import {
  DatedOfferCharge,
  Offer,
  OfferCharge,
  OfferPriceSummary,
  OfferResult,
  ServiceOffer,
  ShoppingCartItem,
  ShoppingCartPriceSummary,
  UnitGroupOffer
} from '../types'
import currency from 'currency.js'

/**
 * Determines if the offer is a member rate
 * @param offer
 * @returns True, if the offer is a member rate
 */
export function isMemberRate(offer: Offer): boolean {
  return offer.ratePlan.name.toLowerCase().includes('member')
}

/**
 * Determines if the offer requires payment at the time of booking
 * @param offer The offer
 * @returns
 */
export function isPrepaidOffer(offer: Offer): boolean {
  return offer.prePaymentAmount && offer.prePaymentAmount.amount > 0
}

/**
 * Sorts unit group offers by lowest gross amount
 * @param unitGroupOffers
 * @returns
 */
function sortUnitGroupOffers(
  unitGroupOffers: UnitGroupOffer[]
): UnitGroupOffer[] {
  // sort by lowest gross amount
  return unitGroupOffers.sort((a, b) => {
    if (a.lowestGrossAmount.amount < b.lowestGrossAmount.amount) {
      return -1
    } else if (a.lowestGrossAmount.amount > b.lowestGrossAmount.amount) {
      return 1
    } else {
      return 0
    }
  })
}

/**
 * Groups offers by unit group for display
 * @param offers
 * @returns
 */
function groupOffersByUnitGroup({
  offers,
  property
}: OfferResult): UnitGroupOffer[] {
  // group offers by unit group, calculate lowest gross amount and nightly amount
  const unitGroupOffersMap = offers.reduce(
    (acc: Record<string, UnitGroupOffer>, offer: Offer) => {
      const unitGroupId = offer.unitGroup.id
      const nightCount = offer.timeSlices.length
      if (acc[unitGroupId]) {
        const unitGroupAcc = acc[unitGroupId]
        unitGroupAcc.offers.push(offer)
        if (
          unitGroupAcc.lowestGrossAmount.amount > offer.totalGrossAmount.amount
        ) {
          const nightlyRate = currency(offer.totalGrossAmount.amount).divide(
            nightCount
          ).value
          unitGroupAcc.lowestGrossAmount = offer.totalGrossAmount
          unitGroupAcc.lowestNightlyAmount.amount = nightlyRate
          unitGroupAcc.lowestNightlyAmount.currency =
            offer.totalGrossAmount.currency
        }
      } else {
        const nightlyRate = currency(offer.totalGrossAmount.amount).divide(
          nightCount
        ).value
        acc[unitGroupId] = {
          propertyId: property.id,
          unitGroup: offer.unitGroup,
          availableUnits: offer.availableUnits,
          lowestGrossAmount: offer.totalGrossAmount,
          lowestNightlyAmount: {
            amount: nightlyRate,
            currency: offer.totalGrossAmount.currency
          },
          nights: nightCount,
          offers: [offer]
        }
      }
      return acc
    },
    {}
  )

  return Object.values(unitGroupOffersMap)
}

/**
 * Gets unit group offers for a property selection.
 * @param propertySelection
 * @param offerResults
 * @returns
 */
export function getUnitGroupOffers(
  propertySelection: string,
  offerResults: OfferResult[]
): UnitGroupOffer[] {
  if (propertySelection === 'ALL') {
    const allUnitGroupOffers = offerResults.flatMap(offerResult =>
      groupOffersByUnitGroup(offerResult)
    )
    return sortUnitGroupOffers(allUnitGroupOffers)
  } else {
    const offerResult = offerResults.find(
      offerResult => offerResult.property.id === propertySelection
    )
    const unitGroupOffers = offerResult
      ? groupOffersByUnitGroup(offerResult)
      : []
    return sortUnitGroupOffers(unitGroupOffers)
  }
}

/**
 * Gets the best offer from a list of offers
 * @param offers
 * @returns
 */
export function getBestOffer(offers: Offer[]): Offer {
  return offers.reduce((cheapest, offer) =>
    offer.totalGrossAmount.amount < cheapest.totalGrossAmount.amount
      ? offer
      : cheapest
  )
}

/**
 * Calculates the base average nightly room rate without taxes and fees
 * @param offer The offer
 * @returns
 */
export function calcBaseAvgNightlyRoomRate(offer: Offer): number {
  const totalRoomCharges =
    offer.timeSlices?.reduce(
      (acc, timeSlice) => acc + timeSlice.totalGrossAmount.amount,
      0
    ) || 0
  const totalNights = offer.timeSlices?.length || 0
  const nightlyRate = currency(totalRoomCharges).divide(totalNights).value
  return nightlyRate
}

/**
 * Calculates the base total room charge without taxes and fees
 * @param offer The offer
 * @returns
 */
export function calcBaseTotalRoomCharge(offer: Offer): number {
  return (
    offer.timeSlices?.reduce(
      (acc, timeSlice) =>
        currency(acc).add(timeSlice.totalGrossAmount.amount).value,
      0
    ) || 0
  )
}

/**
 * Calculates the offer price summary including room charges,
 * additional services, fees, taxes, etc.
 * @param offer The offer
 * @param additionalServices The additional services added by the guest
 * @returns
 */
export function calcOfferPriceSummary(
  offer: Offer,
  additionalServices: ServiceOffer[]
): OfferPriceSummary {
  const itemizedRoomCharges =
    (offer.timeSlices?.map(timeSlice => {
      return {
        name: 'Room Charge',
        date: timeSlice.from.split('T')[0],
        amount: timeSlice.totalGrossAmount.amount
      }
    }) as DatedOfferCharge[]) || []

  const itemizedIncludedServices =
    (offer.services?.map(service => {
      return {
        name: service.service.name,
        amount: service.totalAmount.grossAmount
      }
    }) as OfferCharge[]) || []

  const itemizedFees =
    (offer.fees?.map(fee => {
      return {
        type: 'Fee',
        name: fee.name,
        amount: fee.totalAmount.grossAmount
      }
    }) as OfferCharge[]) || []

  const itemizedTaxes =
    offer.taxDetails?.reduce((acc, taxDetail) => {
      if (taxDetail.tax.amount > 0) {
        acc.push({
          name: 'VAT',
          amount: taxDetail.tax.amount
        })
      }
      return acc
    }, [] as OfferCharge[]) || []

  const itemizedCityTaxes =
    offer.cityTaxes?.reduce((acc, cityTax) => {
      if (cityTax.totalGrossAmount.amount > 0) {
        acc.push({
          name: cityTax.name,
          amount: cityTax.totalGrossAmount.amount
        })
      }
      return acc
    }, [] as OfferCharge[]) || []

  const itemizedAdditionalServices =
    (additionalServices.flatMap(additionalService => {
      return additionalService.dates?.map(date => {
        return {
          name: additionalService.service.name,
          date: date.serviceDate,
          amount: date.amount.grossAmount
        }
      })
    }) as DatedOfferCharge[]) || []

  const itemizedAdditionalServiceFees = additionalServices.reduce(
    (acc, additionalService) => {
      const {service, fees} = additionalService
      const charges = fees?.map(fee => {
        return {
          name: `${service.name} - ${fee.name}`,
          amount: fee.totalAmount.grossAmount
        }
      })
      return charges ? [...acc, ...charges] : acc
    },
    []
  )

  // combine all taxes and fees
  const itemizedTaxesAndFees = [
    ...itemizedAdditionalServiceFees,
    ...itemizedFees,
    ...itemizedIncludedServices,
    ...itemizedTaxes,
    ...itemizedCityTaxes
  ]

  // calculate totals
  const totalRoomCharges = itemizedRoomCharges.reduce(
    (acc, item) => currency(acc).add(item.amount).value,
    0
  )
  const totalAdditionalServices = itemizedAdditionalServices.reduce(
    (acc, item) => currency(acc).add(item.amount).value,
    0
  )
  const totalAdditionalServiceFees = itemizedAdditionalServiceFees.reduce(
    (acc, item) => currency(acc).add(item.amount).value,
    0
  )

  const totalCityTaxes = itemizedCityTaxes.reduce(
    (acc, item) => currency(acc).add(item.amount).value,
    0
  )

  const totalTaxesAndFees = itemizedTaxesAndFees.reduce(
    (acc, item) => currency(acc).add(item.amount).value,
    0
  )

  // calculate prepayment total
  const totalPrepayment = isPrepaidOffer(offer)
    ? currency(offer.prePaymentAmount.amount)
        .add(totalAdditionalServices)
        .add(totalAdditionalServiceFees)
        .add(totalCityTaxes).value
    : 0

  // calculate total
  const total = currency(totalRoomCharges)
    .add(totalAdditionalServices)
    .add(totalTaxesAndFees).value

  return {
    itemizedRoomCharges,
    itemizedAdditionalServices,
    itemizedTaxesAndFees,
    totalRoomCharges,
    totalAdditionalServices,
    totalTaxesAndFees,
    totalPrepayment,
    total
  }
}

/**
 * Calculates the price totals for all shopping cart items
 * @param cartItems The shopping cart items
 * @returns
 */
export function calcCartPriceSummary(
  cartItems: ShoppingCartItem[]
): ShoppingCartPriceSummary {
  const itemSummaries = cartItems.map(item => {
    return calcOfferPriceSummary(item.offer, item.additionalServices)
  })

  const totalPrepayment = itemSummaries.reduce(
    (acc, item) => currency(acc).add(item.totalPrepayment).value,
    0
  )
  const total = itemSummaries.reduce(
    (acc, item) => currency(acc).add(item.total).value,
    0
  )
  const balance = currency(total).subtract(totalPrepayment).value

  return {
    itemSummaries,
    totalPrepayment,
    total,
    balance
  }
}
