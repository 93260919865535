import {createSelector} from '@reduxjs/toolkit'
import {selectGuestInfo} from './guestInfoSlice'
import {selectCartItems} from './shoppingCartSlice'
import {
  BookingReservation,
  CreateIbeBookingMetadata,
  ShoppingCartItem
} from '../types'
import {calcOfferPriceSummary} from '../services/offerUtils'

/**
 * Selects the booking metadata for the IBE booking.
 */
export const selectBookingMetadata = createSelector(
  [selectGuestInfo, selectCartItems],
  (guestInfo, cartItems): CreateIbeBookingMetadata => {
    const reservations: BookingReservation[] = cartItems.map(
      (cartItem: ShoppingCartItem) => {
        const {offer, additionalServices, adults} = cartItem

        const timeSlices = offer.timeSlices.map(_ => ({
          ratePlanId: offer.ratePlan?.id
        }))

        const services = additionalServices.map(service => ({
          serviceId: service.service?.id
        }))

        const priceSummary = calcOfferPriceSummary(offer, additionalServices)

        return {
          arrival: offer.arrival,
          departure: offer.departure,
          adults,
          childrenAges: [],
          guestComment: guestInfo.comments || '',
          channelCode: 'Ibe',
          primaryGuest: {
            firstName: guestInfo.firstName,
            lastName: guestInfo.lastName,
            email: guestInfo.email,
            phone: guestInfo.phone
          },
          guaranteeType: offer.minGuaranteeType,
          timeSlices,
          services,
          prePaymentAmount: {
            amount: priceSummary.totalPrepayment,
            currency: offer.prePaymentAmount.currency
          }
        }
      }
    )

    return {
      type: 'CreateIbeBooking',
      booker: {
        firstName: guestInfo.firstName,
        lastName: guestInfo.lastName,
        email: guestInfo.email,
        phone: guestInfo.phone
      },
      reservations
    }
  }
)
