import React from 'react'
import clsx from 'clsx'
import {HubspotLocation} from '../../types'

export interface NavBarDesktopMenuProps {
  locations: HubspotLocation[]
  isCondensed?: boolean
}

const NavBarDesktopMenu = ({
  locations,
  isCondensed = false
}: NavBarDesktopMenuProps) => {
  return (
    <ul
      className={clsx('flex flex-row items-center gap-8 font-semibold', {
        'text-base': !isCondensed,
        'text-sm': isCondensed
      })}
    >
      <li>
        <a
          className="cursor-pointer hover:text-accent"
          href="https://www.placemakr.com/"
        >
          home
        </a>
      </li>
      <li>
        {locations?.length > 0 ? (
          <div className="dropdown dropdown-hover">
            <a
              tabIndex={0}
              role="button"
              className="cursor-pointer hover:text-accent"
              href="https://www.placemakr.com/locations"
            >
              locations
            </a>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
            >
              {locations.map(location => (
                <li key={location.name}>
                  <a
                    className="font-light-primary cursor-pointer hover:text-accent"
                    href={location.url}
                  >
                    {location.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <a
            tabIndex={0}
            role="button"
            className="cursor-pointer hover:text-accent"
            href="https://www.placemakr.com/locations"
          >
            locations
          </a>
        )}
      </li>
      <li>
        <div className="dropdown dropdown-hover">
          <a
            tabIndex={0}
            role="button"
            className="cursor-pointer hover:text-accent"
          >
            about
          </a>
          <ul
            tabIndex={0}
            className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
          >
            <li>
              <a
                className="font-light-primary cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/about/what-is-placemakr"
              >
                Who We Are
              </a>
            </li>
            <li>
              <a
                className="font-light-primary cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/corporate/join-our-team"
              >
                Join Our Team
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="dropdown dropdown-hover">
          <a
            tabIndex={0}
            role="button"
            className="cursor-pointer hover:text-accent"
          >
            business
          </a>
          <ul
            tabIndex={0}
            className="dropdown-content menu bg-base-100 rounded-box z-[1] w-72 p-2 shadow"
          >
            <li>
              <a
                className="font-light-primary cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/corporate-group"
              >
                Business Travel, Group, & Extended Stay
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div className="dropdown dropdown-hover">
          <a
            tabIndex={0}
            role="button"
            className="cursor-pointer hover:text-accent"
          >
            real estate
          </a>
          <ul
            tabIndex={0}
            className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
          >
            <li>
              <a
                className="font-light-primary cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/partners"
              >
                Real Estate Platform
              </a>
            </li>
            <li>
              <a
                className="font-light-primary cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/investments"
              >
                Investments
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  )
}

export default NavBarDesktopMenu
