import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface User {
  isAuthenticated: boolean
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

const initialState: User = {
  isAuthenticated: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state = action.payload
      return state
    },
    clearUser: state => {
      state = initialState
      return state
    }
  }
})

// selectors
export const selectUser = (state: any): User => {
  return state.user
}

export const selectIsAuthenticated = createSelector(
  [selectUser],
  (user: User) => {
    return user.isAuthenticated
  }
)

// actions
export const {setUser, clearUser} = userSlice.actions

// reducer
export default userSlice.reducer
