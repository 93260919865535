import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SearchParams} from '../types'
import {addDays, formatISO} from 'date-fns'

const initialState: SearchParams = {
  location: '',
  propertyId: '',
  dateRange: {
    type: 'Specific',
    start: formatISO(addDays(new Date(), 1)),
    end: formatISO(addDays(new Date(), 2))
  },
  guests: {
    rooms: 1,
    adults: 1,
    children: 0,
    hasInfants: false,
    hasPets: false
  },
  specialRates: {
    promoCode: '',
    corporateCode: '',
    hasAAA: false,
    hasAARP: false,
    hasGov: false,
    hasMilitary: false
  }
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<SearchParams>) => {
      state = action.payload
      return state
    },
    clearSearch: state => {
      state = initialState
      return state
    }
  }
})

// selectors
export const selectSearch = (state: any): SearchParams => {
  return state.search
}

export const selectGuestsPerRoom = createSelector(
  selectSearch,
  (search: SearchParams): number => {
    const adults = search?.guests?.adults || 1
    const rooms = search?.guests?.rooms || 1
    return rooms > 1 ? Math.ceil(adults / rooms) : adults
  }
)

// actions
export const {setSearch, clearSearch} = searchSlice.actions

// reducer
export default searchSlice.reducer
