import React from 'react'
import {Offer} from '../../types'
import {formatCurrency} from '../../services/utils'

export interface NoShowPolicyProps {
  offer: Offer
}

const NoShowPolicy = ({offer}: NoShowPolicyProps) => {
  const noShow = offer.noShowFee
  const noShowFee = noShow.fee?.amount || 0
  return (
    <div>
      <h3 className="font-semibold text-base border-b mb-2">No Show Policy</h3>
      <p>{offer.noShowFee.description}</p>
      {noShowFee && noShowFee > 0 && (
        <p className="mt-1">
          No shows will incur a fee of{' '}
          <span className="font-semibold">{formatCurrency(noShowFee)}</span>.
        </p>
      )}
    </div>
  )
}

export default NoShowPolicy
