import {z} from 'zod'
import {
  BookingGuestSchema,
  BookingReservationSchema,
  CategorizedFeatureSchema,
  CityTaxSchema,
  CreateIbeBookingMetadataSchema,
  FeeSchema,
  IbeConfigSchema,
  ImageAssetSchema,
  ItemEditSchema,
  ItemEditorModeSchema,
  HubspotLocationSchema,
  HubspotPropertySchema,
  HubspotPropertyResultsSchema,
  MapCoordinatesSchema,
  OfferLineItemSchema,
  OfferRequestSchema,
  OfferResultSchema,
  OfferSchema,
  PaymentSessionRequestSchema,
  PropertyConfigSchema,
  PropertySchema,
  SearchDateRangeParamsSchema,
  SearchDateRangeTypeSchema,
  SearchGuestParamsSchema,
  SearchParamsSchema,
  SearchSpecialRatesParamsSchema,
  SearchUnitGroupTypeSchema,
  ServiceOfferRequestSchema,
  ServiceOfferResultsSchema,
  ServiceOfferSchema,
  ServiceSchema,
  ShoppingCartItemSchema,
  TaxAndFeeItemSchema,
  TaxDetailSchema,
  ToastNotificationSchema,
  UnitGroupOfferSchema,
  UnitGroupSchema
} from './schema'

// -----------------------------------------------------------------------------
//  Enums
// -----------------------------------------------------------------------------

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum AppView {
  HOME = 'Home',
  CHOOSE_PROPERTY = 'ChooseProperty',
  CHOOSE_UNIT = 'ChooseUnit',
  CHECKOUT = 'Checkout',
  CONFIRMATION = 'Confirmation'
}

export enum PaymentSessionStatus {
  PaymentSessionCreated = 'PaymentSessionCreated',
  PaymentRejected = 'PaymentRejected',
  PaymentProcessing = 'PaymentProcessing',
  PaymentSucceeded = 'PaymentSucceeded',
  PaymentFailed = 'PaymentFailed'
}

// -----------------------------------------------------------------------------
//  Types
// -----------------------------------------------------------------------------

export type BookingGuest = z.infer<typeof BookingGuestSchema>

export type BookingReservation = z.infer<typeof BookingReservationSchema>

export type CategorizedFeature = z.infer<typeof CategorizedFeatureSchema>

export type CityTax = z.infer<typeof CityTaxSchema>

export type CreateIbeBookingMetadata = z.infer<
  typeof CreateIbeBookingMetadataSchema
>
export type Fee = z.infer<typeof FeeSchema>

export type HubspotLocation = z.infer<typeof HubspotLocationSchema>

export type HubspotProperty = z.infer<typeof HubspotPropertySchema>

export type HubspotPropertyResults = z.infer<
  typeof HubspotPropertyResultsSchema
>

export type IbeConfig = z.infer<typeof IbeConfigSchema>

export type ImageAsset = z.infer<typeof ImageAssetSchema>

export type ItemEditorMode = z.infer<typeof ItemEditorModeSchema>

export type ItemEdit = z.infer<typeof ItemEditSchema>

export type MapCoordinates = z.infer<typeof MapCoordinatesSchema>

export type Offer = z.infer<typeof OfferSchema>

export type OfferLineItem = z.infer<typeof OfferLineItemSchema>

export type OfferRequest = z.infer<typeof OfferRequestSchema>

export type OfferResult = z.infer<typeof OfferResultSchema>

export type PaymentSessionRequest = z.infer<typeof PaymentSessionRequestSchema>

export type Property = z.infer<typeof PropertySchema>

export type PropertyConfig = z.infer<typeof PropertyConfigSchema>

export type SearchDateRangeType = z.infer<typeof SearchDateRangeTypeSchema>

export type SearchDateRangeParams = z.infer<typeof SearchDateRangeParamsSchema>

export type SearchGuestParams = z.infer<typeof SearchGuestParamsSchema>

export type SearchParams = z.infer<typeof SearchParamsSchema>

export type SearchSpecialRatesParams = z.infer<
  typeof SearchSpecialRatesParamsSchema
>

export type SearchUnitGroupType = z.infer<typeof SearchUnitGroupTypeSchema>

export type Service = z.infer<typeof ServiceSchema>

export type ServiceOffer = z.infer<typeof ServiceOfferSchema>

export type ServiceOfferRequest = z.infer<typeof ServiceOfferRequestSchema>

export type ServiceOfferResults = z.infer<typeof ServiceOfferResultsSchema>

export type ShoppingCartItem = z.infer<typeof ShoppingCartItemSchema>

export type TaxAndFeeItem = z.infer<typeof TaxAndFeeItemSchema>

export type TaxDetail = z.infer<typeof TaxDetailSchema>

export type ToastNotification = z.infer<typeof ToastNotificationSchema>

export type UnitGroup = z.infer<typeof UnitGroupSchema>

export type UnitGroupOffer = z.infer<typeof UnitGroupOfferSchema>
