import React, {useState} from 'react'
import SearchPropertyFeatures from './SearchPropertyFeatures'
import {OfferResult, PropertyConfig} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationCrosshairs} from '@fortawesome/free-solid-svg-icons'
import {getBestOffer} from '../../services/offerUtils'
import ImageCarousel from '../common/ImageCarousel'
import SearchPropertyDetailsModal from './SearchPropertyDetailsModal'
import clsx from 'clsx'
import ReactGA from 'react-ga4'
import {getLogger} from '../../services/logging'

const logger = getLogger('SearchPropertyResultItem')

export interface SearchPropertyResultsItemProps {
  result: OfferResult
  propertyConfig: PropertyConfig
  onSelectProperty: (propertyId: string) => void
  onShowOnMap?: (propertyId: string) => void
  className?: string
}

const SearchPropertyResultItem = ({
  result,
  propertyConfig,
  onSelectProperty,
  onShowOnMap = () => {},
  className = ''
}: SearchPropertyResultsItemProps) => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const {property, offers} = result
  const bestOffer = getBestOffer(offers)
  const nights = bestOffer.timeSlices.length
  const totalAmount = bestOffer.totalGrossAmount.amount
  const nightlyRate = Math.round(totalAmount / nights)

  return (
    <div className={className}>
      <div className="card bg-white shadow">
        <div className="flex flex-col md:flex-row">
          {/* image carousel */}
          <div
            className={clsx(
              'w-full overflow-hidden rounded-tl-2xl rounded-tr-2xl h-72',
              'md:w-5/12 md:rounded-bl-2xl md:rounded-tr-none md:h-auto',
              'relative'
            )}
          >
            <ImageCarousel images={propertyConfig.images} />

            {propertyConfig.isPopup && (
              <span className="bg-orange-600 text-xs text-white font-medium absolute px-2 py-1 top-6 rounded-r">
                pop-up hotel
              </span>
            )}
          </div>

          {/* card body */}
          <div className="w-full md:w-7/12 flex flex-col p-6">
            {/* property header */}
            <h1 className="text-xl font-semibold">{property.name}</h1>
            {/* property neighborhood/city */}
            <div className="text-sm text-light-primary text-pretty mb-2">
              {propertyConfig.neighborhood} &#x2022; {propertyConfig.location}{' '}
            </div>
            {/* property amenities */}
            {propertyConfig.highlightedFeatures && (
              <SearchPropertyFeatures
                features={propertyConfig.highlightedFeatures}
                className="mb-4"
              />
            )}

            {/* property description */}
            <div className="text-sm text-light-primary mb-4">
              {propertyConfig.description}
            </div>

            {/* property details modal link */}
            <a
              className={clsx(
                'link text-sm text-accent font-semibold',
                'no-underline hover:underline',
                'mb-4'
              )}
              onClick={() => {
                ReactGA.event({
                  category: 'IBE',
                  action: 'Property_Details_View',
                  value: 1
                })
                logger.info({message: 'Opening property details modal...'})
                setIsDetailModalOpen(true)
              }}
            >
              View all property features
            </a>

            <div className="flex flex-row justify-end mb-2">
              {/* property pricing */}
              <div className="flex flex-col justify-center items-center flex-none">
                <div className="text-gray-400 text-2xs leading-3">
                  starting at
                </div>
                <div className="font-semibold flex flex-row items-center">
                  <span className="text-xl leading-6 mr-1">${nightlyRate}</span>
                  <span className="text-xs uppercase">
                    {bestOffer.totalGrossAmount.currency}
                  </span>
                </div>
                <div className="text-gray-400 text-2xs leading-3">/night</div>
              </div>
            </div>

            <div className="flex flex-row items-center mt-2 w-full justify-between">
              {/* property map link */}
              <div className="flex-0 flex flex-row items-center gap-4">
                <a
                  className={clsx(
                    'link text-sm font-medium no-underline hover:underline',
                    'flex flex-row text-sm items-center gap-1'
                  )}
                  onClick={() => {
                    ReactGA.event({
                      category: 'IBE',
                      action: 'Property_Map_Show',
                      value: 1
                    })
                    onShowOnMap(propertyConfig.propertyApaleoId)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faLocationCrosshairs}
                    className="text-accent"
                  />
                  <span className="text-sm">view map</span>
                </a>
              </div>

              {/* property select cta */}
              <button
                className="btn btn-secondary btn-sm sm:btn-md"
                onClick={() => {
                  ReactGA.event({
                    category: 'IBE',
                    action: 'Property_Select',
                    value: 1
                  })
                  logger.info({
                    message: 'User selected property',
                    data: {property}
                  })
                  onSelectProperty(property.id)
                }}
              >
                see what's available
              </button>
            </div>
          </div>
        </div>
      </div>

      <SearchPropertyDetailsModal
        propertyConfig={propertyConfig}
        isOpen={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
      />
    </div>
  )
}

export default SearchPropertyResultItem
