import {faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectCartItemCount, showCart} from '../../store/shoppingCartSlice'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'

const logger = getLogger('ShoppingCartButton')

export interface ShoppingCartButtonProps {
  className?: string
}

const ShoppingCartButton = ({className}: ShoppingCartButtonProps) => {
  const dispatch = useAppDispatch()
  const cartItemCount = useAppSelector(selectCartItemCount)

  const CartButton = (
    <button
      className={clsx(
        'btn btn-outline btn-sm lg:btn-md flex items-center',
        className
      )}
      onClick={() => {
        logger.debug({message: 'Show shopping cart button clicked.'})
        ReactGA.event({
          category: 'IBE',
          action: 'Shopping_Cart_Button_Click',
          value: 1
        })
        dispatch(showCart())
      }}
      aria-label="Shopping cart button."
    >
      <FontAwesomeIcon icon={faShoppingCart} title="Shopping cart" />
      <span>cart</span>
    </button>
  )

  if (cartItemCount === 0) {
    return CartButton
  } else {
    return (
      <div className="indicator">
        <span className="indicator-item badge badge-secondary font-semibold">
          {cartItemCount}
        </span>
        {CartButton}
      </div>
    )
  }
}

export default ShoppingCartButton
