import React from 'react'
import {PropertyConfig} from '../../types'
import ImageCarousel from '../common/ImageCarousel'
import SearchPropertyFeatures from './SearchPropertyFeatures'
import {FocusTrap} from 'focus-trap-react'
import clsx from 'clsx'

export interface SearchPropertyDetailsModalProps {
  propertyConfig: PropertyConfig
  isOpen: boolean
  onClose: () => void
}

const SearchPropertyDetailsModal = ({
  propertyConfig,
  isOpen,
  onClose
}: SearchPropertyDetailsModalProps) => {
  const features = propertyConfig?.features || []
  const sortedFeatures = [...features].sort((a, b) => a.localeCompare(b))

  return (
    <>
      {isOpen && (
        <FocusTrap active={isOpen}>
          <div
            className="modal modal-open"
            onClick={e => {
              if (e.target === e.currentTarget) {
                onClose()
              }
            }}
            role="dialog"
            aria-modal="true"
          >
            <div className="modal-box p-0 w-[95%] lg:max-w-screen-md max-h-[95%]">
              <div className="w-full h-72 rounded-t-2xl overflow-hidden">
                <ImageCarousel images={propertyConfig.images} />
              </div>
              <button
                className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
                onClick={onClose}
                aria-label="Close property details modal."
              >
                ✕
              </button>
              <div className="modal-body overflow-y-scroll max-h-96 pr-4 my-2 px-8 py-4">
                <h2 className="font-semibold text-xl">
                  {propertyConfig?.name}
                </h2>
                {propertyConfig?.highlightedFeatures && (
                  <SearchPropertyFeatures
                    className="mb-2"
                    features={propertyConfig.highlightedFeatures}
                  />
                )}
                <div className="text-sm text-light-primary">
                  {propertyConfig?.description}
                </div>
                {sortedFeatures && sortedFeatures.length > 0 && (
                  <>
                    <div className="divider my-1"></div>
                    <h3 className="font-semibold text-md mb-1">
                      Property Amenities
                    </h3>
                    <ul
                      className={clsx(
                        'list-disc pl-5 grid grid-cols-1 md:grid-cols-2 gap-0 mr-1',
                        'text-sm text-light-primary'
                      )}
                    >
                      {sortedFeatures.map(feature => (
                        <li key={feature}>{feature}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  )
}

export default SearchPropertyDetailsModal
