import React, {useState, useEffect, useRef} from 'react'
import {useLazyGetPaymentSessionStatusQuery} from '../../services/adyenPaymentsApi'
import {PaymentSessionStatus} from '../../types'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import IbeProgress from '../../assets/ibe-progress.png'
import BookingFailed from '../../assets/ibe-booking-failed.png'

const logger = getLogger('CheckoutProcessingModal')

enum View {
  Loading = 'Loading',
  Error = 'Error'
}

// polling interval in ms and max attempts
const POLL_INTERVAL = 15000
const POLL_MAX_ATTEMPTS = 10

export interface CheckoutProcessingModalProps {
  isOpen: boolean
  onComplete: () => void
  onClose: () => void
  paymentSessionId?: string
}

const CheckoutProcessingModal = ({
  isOpen,
  onComplete,
  onClose,
  paymentSessionId
}: CheckoutProcessingModalProps) => {
  const [view, setView] = useState<View>(View.Loading)
  const pollAttempts = useRef(0)
  const [getPaymentSessionStatus] = useLazyGetPaymentSessionStatusQuery()

  useEffect(() => {
    if (!paymentSessionId) {
      return
    }

    const intervalId = setInterval(async () => {
      logger.info({
        message: `Polling for payment session status - ${paymentSessionId}`
      })

      if (pollAttempts.current >= POLL_MAX_ATTEMPTS) {
        logger.info({
          message: 'Max polling attempts reached, stop polling for status'
        })
        clearInterval(intervalId)
        setView(View.Error)
        return
      } else {
        pollAttempts.current += 1
      }

      getPaymentSessionStatus(paymentSessionId)
        .then(response => {
          const status = response.data?.status as PaymentSessionStatus
          logger.info({
            message: 'Retrieved payment session status',
            data: {status}
          })
          switch (status) {
            case PaymentSessionStatus.PaymentSucceeded:
              logger.info({
                message: 'Payment succeeded, stop polling for status'
              })
              ReactGA.event({
                category: 'IBE',
                action: 'Checkout_Payment_Success',
                value: 1
              })

              clearInterval(intervalId)
              onComplete()
              break
            case PaymentSessionStatus.PaymentRejected:
            case PaymentSessionStatus.PaymentFailed:
              logger.warn({message: 'Payment failed, stop polling for status'})
              ReactGA.event({
                category: 'IBE',
                action: 'Checkout_Payment_Failure',
                value: 1
              })
              clearInterval(intervalId)
              setView(View.Error)
              break
            default:
              logger.info({message: 'Continue polling for status...'})
              ReactGA.event({
                category: 'IBE',
                action: 'Checkout_Payment_Polling',
                value: 1
              })
          }
        })
        .catch(error => {
          logger.error({
            message:
              'Failed to retrieve payment session status, stop polling for status',
            errorData: {error}
          })
          clearInterval(intervalId)
          setView(View.Error)
        })
    }, POLL_INTERVAL)

    return () => clearInterval(intervalId)
  }, [paymentSessionId])

  return (
    <>
      {isOpen && (
        <div className="modal modal-open p-4">
          <div className="modal-box">
            <div className="modal-body w-full p-6">
              {view === View.Loading && (
                <div className="flex flex-col items-center justify-center gap-2 p-4">
                  <img
                    src={IbeProgress}
                    alt="Processing your booking."
                    className="h-48"
                  />
                  <div className="flex flex-row items-center gap-2 mt-2">
                    <span className="loading loading-spinner loading-sm"></span>
                    <span className="text-lg font-semibold">
                      Processing your booking...
                    </span>
                  </div>
                  <p className="text-center text-sm mx-4">
                    Hang tight - we're working on confirming your booking and
                    will update you shortly. This may take a few minutes.
                  </p>
                </div>
              )}

              {view === View.Error && (
                <div className="flex flex-col items-center justify-center gap-2 h-96 p-4">
                  <img
                    src={BookingFailed}
                    alt="Booking failed."
                    className="h-48"
                  />
                  <h2 className="text-lg font-semibold">Booking Failed.</h2>
                  <p className="text-center text-sm mx-4">
                    The booking could not be processed because your payment
                    failed. You may try again with a different payment method.
                    If problems persist, please contact us.
                  </p>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        logger.info({
                          message: 'User is trying again to submit a payment...'
                        })
                        ReactGA.event({
                          category: 'IBE',
                          action: 'Checkout_Payment_Retry',
                          value: 1
                        })
                        onClose()
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CheckoutProcessingModal
