import React, {useEffect} from 'react'
import CheckoutView from './checkout/CheckoutView'
import SearchView from './search/SearchView'
import {useGetConfigQuery} from '../services/ibeApi'
import {AppView, IbeConfig} from '../types'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {
  selectAppView,
  selectIsSearchView,
  setAppView
} from '../store/appViewSlice'
import ShoppingCartModal from './cart/ShoppingCartModal'
import useInitSearchParams from '../hooks/useInitSearchParams'
import ShoppingCartItemEditorModal from './cart/ShoppingCartItemEditorModal'
import ToastNotificationManager from './common/ToastNotificationManager'
import {setConfig} from '../store/configSlice'
import {initAuthState, initAuthListener} from '../services/auth'
import ConfirmationView from './confirmation/ConfirmationView'
import ReactGA from 'react-ga4'
import {getConfig} from '../services/config'
import {getLogger} from '../services/logging'
import NavBar from './nav/NavBar'
import {selectSearch} from '../store/searchSlice'
import Alert from './common/Alert'

const logger = getLogger('App')

/**
 * Initializes Google Analytics.
 * @param googleAnalyticsId - The Google Analytics tracking ID.
 */
function initGoogleAnalytics(): void {
  const {googleAnalyticsId} = getConfig()
  logger.info({
    message: `Initializing Google Analytics...`,
    data: {googleAnalyticsId}
  })
  ReactGA.initialize(googleAnalyticsId)
}

const App = () => {
  useInitSearchParams()
  const dispatch = useAppDispatch()
  const appView = useAppSelector(selectAppView)
  const searchParams = useAppSelector(selectSearch)
  const isSearchView = useAppSelector(selectIsSearchView)
  const configQuery = useGetConfigQuery(null)
  const config = configQuery.data as IbeConfig

  useEffect(() => {
    // initialize Google Analytics
    initGoogleAnalytics()

    // initialize AWS Amplify Auth
    initAuthState(dispatch)
    const unsubscribeAuthListener = initAuthListener(dispatch)
    return () => {
      unsubscribeAuthListener()
    }
  }, [])

  useEffect(() => {
    if (
      (searchParams?.location === 'ALL' || !searchParams?.location?.length) &&
      appView !== AppView.HOME
    ) {
      logger.info({
        message: 'Redirecting to home view...',
        data: {appView, searchParams}
      })
      dispatch(setAppView(AppView.HOME))
    }
  }, [])

  useEffect(() => {
    // track app view changes
    ReactGA.event({
      category: 'IBE',
      action: `App_View_${appView}`,
      value: 1
    })

    // scroll on view changes
    if (
      appView === AppView.CHOOSE_PROPERTY ||
      appView === AppView.CHOOSE_UNIT
    ) {
      // scroll to search results when on search view
      setTimeout(() => {
        const searchResultsElement = document.getElementById(
          'ibe-search-results'
        )
        if (searchResultsElement) {
          const NAV_HEIGHT = 100
          window.scrollTo({
            top: searchResultsElement.offsetTop - NAV_HEIGHT,
            behavior: 'smooth'
          })
        }
      }, 100)
    } else {
      // scroll up when view changes
      window.scrollTo(0, 0)
    }
  }, [appView, searchParams])

  useEffect(() => {
    if (config) {
      dispatch(setConfig(config))
    }
  }, [configQuery])

  return (
    <>
      <NavBar />
      <main className="w-full bg-base-100">
        <section className="placemakr-ibe p-4 sm:p-8 max-w-screen-xl mx-auto">
          {configQuery.isLoading && (
            <div className="flex flex-col gap-8 my-4">
              <div className="skeleton h-20 rounded-full w-full"></div>
              <div className="skeleton h-16 rounded-full w-1/2 mx-auto"></div>
              <div className="skeleton h-96 w-full"></div>
            </div>
          )}
          {!configQuery.isLoading && config && (
            <>
              {/* Toast Notifications */}
              <ToastNotificationManager />

              {/* Search View */}
              {isSearchView && <SearchView appView={appView} />}

              {/* Checkout View */}
              {appView === AppView.CHECKOUT && (
                <CheckoutView appView={appView} />
              )}

              {/* Confirmation View */}
              {appView === AppView.CONFIRMATION && <ConfirmationView />}

              {/* Shopping Cart */}
              <ShoppingCartModal />

              {/* Shopping Cart Item Editor */}
              <ShoppingCartItemEditorModal />
            </>
          )}
          {!configQuery.isLoading && !config && (
            <Alert type="error" className="p-8">
              An application error occurred. Configuration could not be loaded.
              If the problem persists, please contact support:
              <a
                className="link"
                target="_blank"
                href="mailto:support@placemakr.com"
              >
                support@placemakr.com
              </a>
            </Alert>
          )}
        </section>
      </main>
    </>
  )
}

export default App
