import React from 'react'
import clsx from 'clsx'
import {AppView} from '../../types'
import {useAppDispatch} from '../../store/hooks'
import {setAppView} from '../../store/appViewSlice'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome} from '@fortawesome/free-solid-svg-icons'

export interface BreadcrumbProps {
  appView: string
  className?: string
}

const Breadcrumb = ({appView, className = ''}: BreadcrumbProps) => {
  const dispatch = useAppDispatch()

  if (appView === AppView.HOME) {
    return
  }

  return (
    <div className={clsx('text-xs sm:text-sm breadcrumbs', className)}>
      <ul>
        <li>
          <a
            className="link"
            onClick={() => dispatch(setAppView(AppView.HOME))}
            onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
              if (e.key === 'Enter') {
                dispatch(setAppView(AppView.HOME))
              }
            }}
            tabIndex={0}
            aria-label="Navigate to the search home page."
          >
            <FontAwesomeIcon
              className="sm:hidden"
              icon={faHome}
              title="Search home"
            />
            <span className="hidden sm:block">home</span>
          </a>
        </li>
        {appView === AppView.CHOOSE_PROPERTY && <li>choose property</li>}
        {appView === AppView.CHOOSE_UNIT && (
          <>
            <li>
              <a
                className="link"
                onClick={() => dispatch(setAppView(AppView.CHOOSE_PROPERTY))}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'Enter') {
                    dispatch(setAppView(AppView.CHOOSE_PROPERTY))
                  }
                }}
                tabIndex={0}
                aria-label="Navigate to the choose property page."
              >
                choose property
              </a>
            </li>
            <li>choose room</li>
          </>
        )}
        {appView === AppView.CHECKOUT && (
          <>
            <li>
              <a
                className="link"
                onClick={() => dispatch(setAppView(AppView.CHOOSE_PROPERTY))}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'Enter') {
                    dispatch(setAppView(AppView.CHOOSE_PROPERTY))
                  }
                }}
                tabIndex={0}
                aria-label="Navigate to the choose property page."
              >
                choose property
              </a>
            </li>
            <li>
              <a
                className="link"
                onClick={() => dispatch(setAppView(AppView.CHOOSE_UNIT))}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'Enter') {
                    dispatch(setAppView(AppView.CHOOSE_UNIT))
                  }
                }}
                tabIndex={0}
                aria-label="Navigate to the choose room page."
              >
                choose room
              </a>
            </li>
            <li>checkout</li>
          </>
        )}
      </ul>
    </div>
  )
}

export default Breadcrumb
