import React, {useEffect} from 'react'
import {
  AdvancedMarker,
  Map,
  Pin,
  useAdvancedMarkerRef,
  useMap
} from '@vis.gl/react-google-maps'
import clsx from 'clsx'
import {PropertyConfig} from '../../types'
import {getLogger} from '../../services/logging'

const logger = getLogger('SearchView')

export interface SearchGoogleMapProps {
  properties: PropertyConfig[]
  selectedProperty?: string
  className?: string
}

const SearchGoogleMap = ({
  properties,
  selectedProperty,
  className = ''
}: SearchGoogleMapProps) => {
  const map = useMap()
  const [markerRef, marker] = useAdvancedMarkerRef()

  useEffect(() => {
    if (!map) {
      return
    }

    if (selectedProperty) {
      const property = properties.find(
        property => property.propertyApaleoId === selectedProperty
      )
      if (property) {
        const {latitude, longitude} = property.coordinates
        logger.info({
          message: 'Setting map center for selected property',
          data: {selectedProperty}
        })
        map.panTo(new window.google.maps.LatLng(latitude, longitude))
        map.setZoom(15)
      }
    } else if (properties.length === 1) {
      const property = properties[0]
      const {latitude, longitude} = property.coordinates
      logger.info({
        message: 'Setting map center for only property',
        data: {property}
      })
      map.panTo(new window.google.maps.LatLng(latitude, longitude))
      map.setZoom(15)
    } else if (properties.length > 1) {
      const bounds = new window.google.maps.LatLngBounds()
      logger.info({
        message: 'Setting bounding box for properties',
        data: {properties}
      })
      properties.forEach(property => {
        const {latitude, longitude} = property.coordinates
        bounds.extend(new window.google.maps.LatLng(latitude, longitude))
      })
      map.fitBounds(bounds)
    }
  }, [map, properties, selectedProperty])

  return (
    <div
      className={clsx('card card-compact shadow-md', className)}
      style={{height: '832px'}}
    >
      <div className="card-body p-1">
        <Map
          mapId="searchGoogleMap"
          style={{width: '400px', height: '800px', minHeight: '500px'}}
          defaultZoom={10}
          defaultCenter={{lat: 38.90343, lng: -77.0536}}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          {properties.map(property => {
            const {latitude, longitude} = property.coordinates
            return (
              <AdvancedMarker
                key={property.propertyApaleoId}
                position={{
                  lat: latitude,
                  lng: longitude
                }}
              >
                <Pin
                  background="#122634"
                  glyphColor="#b2ece7"
                  borderColor="#b2ece7"
                />
              </AdvancedMarker>
            )

            // TODO: fix issue with marker refs
            // see: https://github.com/visgl/react-google-maps/discussions/404

            // if (property.id === selectedProperty) {
            //   // render selected property with info window
            //   const address1 = property.address?.addressLine1
            //   const address2 = property.address?.addressLine2 || ''
            //   const city = property.address?.city
            //   const state =
            //     property.address?.state === 'DC'
            //       ? null
            //       : property.address?.state
            //   const postalCode = property.address?.postalCode

            //   return (
            //     <AdvancedMarker
            //       key={property.id}
            //       position={{
            //         lat: property.latLong[0],
            //         lng: property.latLong[1]
            //       }}
            //       ref={markerRef}
            //     >
            //       <Pin
            //         background="#122634"
            //         glyphColor="#b2ece7"
            //         borderColor="#b2ece7"
            //       />

            //       <InfoWindow
            //         anchor={marker}
            //         headerContent={
            //           <div className="font-bold text-lg">{property.name}</div>
            //         }
            //       >
            //         <div className="flex flex-col">
            //           <div>{`${address1} ${
            //             address2 ? ', ' + address2 : ''
            //           }`}</div>
            //           <div>{`${city}${
            //             state ? ', ' + state : ''
            //           } ${postalCode}`}</div>
            //         </div>
            //       </InfoWindow>
            //     </AdvancedMarker>
            //   )
            // } else {
            //   // render property marker
            //   return (
            //     <AdvancedMarker
            //       key={property.id}
            //       position={{
            //         lat: property.latLong[0],
            //         lng: property.latLong[1]
            //       }}
            //     >
            //       <Pin
            //         background="#122634"
            //         glyphColor="#b2ece7"
            //         borderColor="#b2ece7"
            //       />
            //     </AdvancedMarker>
            //   )
            // }
          })}
        </Map>
      </div>
    </div>
  )
}

export default SearchGoogleMap
