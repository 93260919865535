import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {parseISO, differenceInDays} from 'date-fns'
import {setSearch} from '../store/searchSlice'
import {selectSearch} from '../store/searchSlice'
import {AppView, SearchParams} from '../types'
import {setAppView} from '../store/appViewSlice'
import {getLogger} from '../services/logging'

const logger = getLogger('useInitSearchParams')

const safeParseInt = (value: string | undefined): number => {
  const parsed = parseInt(value || '0', 10)
  return isNaN(parsed) ? 0 : parsed
}

export interface QueryStringSearchParams {
  location?: string
  region?: string
  propertyId?: string
  arrival?: string
  departure?: string
  adults?: string
  rooms?: string
  promoCode?: string
  corporateCode?: string
  hasAAA?: string
  hasAARP?: string
  hasGov?: string
  hasMilitary?: string
}

const queryStringParams = [
  'location',
  'region',
  'propertyId',
  'arrival',
  'departure',
  'adults',
  'rooms',
  'promoCode',
  'corporateCode',
  'hasAAA',
  'hasAARP',
  'hasGov',
  'hasMilitary'
]

const useInitSearchParams = () => {
  const dispatch = useDispatch()
  const searchParams = useSelector(selectSearch)

  logger.info({
    message: 'Initializing search parameters...',
    data: {searchParams}
  })

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const params: QueryStringSearchParams = {}
    urlParams.forEach((value, key) => {
      if (queryStringParams.includes(key)) {
        params[key as keyof QueryStringSearchParams] = value
      }
    })

    if (Object.keys(params).length > 0) {
      const {
        arrival,
        departure,
        region,
        location,
        propertyId,
        adults,
        rooms,
        promoCode,
        corporateCode,
        hasAAA,
        hasAARP,
        hasGov,
        hasMilitary
      } = params

      const property = propertyId !== 'All' ? propertyId : undefined
      const start = arrival || searchParams.dateRange.start
      const end = departure || searchParams.dateRange.end
      const nights =
        start && end ? differenceInDays(parseISO(end), parseISO(start)) : 0
      const adultCount = adults
        ? safeParseInt(adults)
        : searchParams.guests.adults
      const roomCount = rooms ? safeParseInt(rooms) : searchParams.guests.rooms

      const updatedSearchParams: SearchParams = {
        ...searchParams,
        location: location || region || searchParams.location || 'ALL',
        propertyId: property || searchParams.propertyId || undefined,
        dateRange: {
          type: 'Specific',
          start,
          end,
          nights
        },
        guests: {
          adults: adultCount,
          rooms: roomCount,
          children: 0,
          hasInfants: false,
          hasPets: false
        },
        specialRates: {
          promoCode,
          corporateCode,
          hasAAA: hasAAA === 'true',
          hasAARP: hasAARP === 'true',
          hasGov: hasGov === 'true',
          hasMilitary: hasMilitary === 'true'
        }
      }

      logger.info({
        message: 'Setting search parameters from URL parameters...',
        data: {updatedSearchParams}
      })

      dispatch(setSearch(updatedSearchParams))

      // set app view based on search params
      if (updatedSearchParams.propertyId) {
        dispatch(setAppView(AppView.CHOOSE_UNIT))
      } else if (updatedSearchParams.location) {
        dispatch(setAppView(AppView.CHOOSE_PROPERTY))
      } else {
        dispatch(setAppView(AppView.HOME))
      }
    }
  }, [])
}

export default useInitSearchParams
