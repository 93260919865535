import {
  faCaretLeft,
  faCaretRight,
  faImage
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, {useRef} from 'react'
import {useState} from 'react'
import {ImageAsset} from '../../types'

interface ImageCarouselProps {
  images: ImageAsset[]
}

const ImageCarousel = ({images}: ImageCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const imageCount = images?.length || 0
  const indicatorCount = Math.min(imageCount, 5)
  const indicators = Array.from({length: indicatorCount}, (_, index) => index)

  const isActiveIndicator = (indicatorIndex: number): boolean => {
    const imageCount = images.length
    if (imageCount === indicatorCount) {
      return currentPage === indicatorIndex
    } else {
      const middleIndicatorIndex = Math.floor(indicatorCount / 2)
      if (indicatorIndex === 0) {
        return currentPage === 0
      } else if (indicatorIndex === 1) {
        return currentPage === 1
      } else if (indicatorIndex === middleIndicatorIndex) {
        return currentPage >= 2 && currentPage <= imageCount - 3
      } else if (indicatorIndex === indicatorCount - 2) {
        return currentPage === imageCount - 2
      } else if (indicatorIndex === indicatorCount - 1) {
        return currentPage === imageCount - 1
      } else {
        return false
      }
    }
  }

  if (!images || images.length === 0) {
    return (
      <div className="h-full flex items-center justify-center bg-gray-200">
        <FontAwesomeIcon icon={faImage} className="text-2xl" />
      </div>
    )
  } else {
    return (
      <div className="carousel-container h-full relative bg-gray-200">
        <div
          className="carousel h-full"
          ref={carouselRef}
          onScroll={() => {
            if (carouselRef.current) {
              const width = carouselRef.current.clientWidth
              const scrollLeft = carouselRef.current.scrollLeft
              const newPage = Math.round(scrollLeft / width)
              setCurrentPage(newPage)
            }
          }}
        >
          {images.map(({image, altText}) => {
            return (
              <div key={image} className="carousel-item w-full bg-gray-200">
                <img
                  src={image}
                  alt={altText}
                  className="w-full h-auto object-cover bg-gray-200"
                />
              </div>
            )
          })}
        </div>

        <div className="absolute left-5 right-5 top-1/2 flex justify-between">
          <a
            onClick={() => {
              if (carouselRef.current) {
                const width = carouselRef.current.clientWidth
                carouselRef.current.scrollBy({
                  left: -width,
                  behavior: 'smooth'
                })
              }
            }}
            className="btn btn-sm btn-circle bg-black border-0 opacity-70 hover:opacity-90 hover:bg-black"
          >
            <FontAwesomeIcon icon={faCaretLeft} className="text-white" />
          </a>
          <a
            onClick={() => {
              if (carouselRef.current) {
                const width = carouselRef.current.clientWidth
                carouselRef.current.scrollBy({
                  left: width,
                  behavior: 'smooth'
                })
              }
            }}
            className="btn btn-sm btn-circle bg-black border-0 opacity-70 hover:opacity-90 hover:bg-black"
          >
            <FontAwesomeIcon icon={faCaretRight} className="text-white" />
          </a>
        </div>
        <div
          className={clsx(
            'absolute bottom-5 left-1/2 transform -translate-x-1/2 flex space-x-2',
            'bg-black border-0 opacity-70 p-2 rounded-xl'
          )}
        >
          {indicators.map(x => {
            const isActive = isActiveIndicator(x)
            return (
              <div
                key={x}
                className={clsx('w-2 h-2 rounded-full', {
                  'bg-white': isActive,
                  'bg-gray-500': !isActive
                })}
              />
            )
          })}
        </div>

        {/* {images && images?.length > 1 && (
          <>
            <div className="absolute left-5 right-5 top-1/2 flex justify-between">
              <a
                onClick={() => {
                  if (carouselRef.current) {
                    const width = carouselRef.current.clientWidth
                    carouselRef.current.scrollBy({
                      left: -width,
                      behavior: 'smooth'
                    })
                  }
                }}
                className="btn btn-sm btn-circle bg-black border-0 opacity-70 hover:opacity-90 hover:bg-black"
              >
                <FontAwesomeIcon icon={faCaretLeft} className="text-white" />
              </a>
              <a
                onClick={() => {
                  if (carouselRef.current) {
                    const width = carouselRef.current.clientWidth
                    carouselRef.current.scrollBy({
                      left: width,
                      behavior: 'smooth'
                    })
                  }
                }}
                className="btn btn-sm btn-circle bg-black border-0 opacity-70 hover:opacity-90 hover:bg-black"
              >
                <FontAwesomeIcon icon={faCaretRight} className="text-white" />
              </a>
            </div>
            <div
              className={clsx(
                'absolute bottom-5 left-1/2 transform -translate-x-1/2 flex space-x-2',
                'bg-black border-0 opacity-70 p-2 rounded-xl'
              )}
            >
              {indicators.map(x => {
                const isActive = isActiveIndicator(x)
                return (
                  <div
                    key={x}
                    className={clsx('w-2 h-2 rounded-full', {
                      'bg-white': isActive,
                      'bg-gray-500': !isActive
                    })}
                  />
                )
              })}
            </div>
          </>
        )} */}
      </div>
    )
  }
}

export default ImageCarousel
