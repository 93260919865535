import React from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {
  addItem,
  clearCart,
  hideItemEditor,
  selectCartHasItems,
  selectCartItemsProperty,
  selectIsItemEditorOpen,
  selectItemEditorMode,
  selectItemToEdit,
  updateItem,
  updateItemEditor
} from '../../store/shoppingCartSlice'
import ShoppingCartServiceItem from './ShoppingCartServiceItem'
import {AppView, ServiceOffer} from '../../types'
import ShoppingCartItem from './ShoppingCartItem'
import {setAppView} from '../../store/appViewSlice'
import {addToastNotification} from '../../store/toastNotificationSlice'
import {generateUUID} from '../../services/utils'
import {selectGuestsPerRoom, selectSearch} from '../../store/searchSlice'
import {useSearchServiceOffersQuery} from '../../services/ibeApi'
import {getLogger} from '../../services/logging'
import Alert from '../common/Alert'
import ReactGA from 'react-ga4'
import {FocusTrap} from 'focus-trap-react'

const logger = getLogger('ShoppingCartItemEditorModal')

const ShoppingCartItemEditorModal = () => {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectIsItemEditorOpen)
  const item = useAppSelector(selectItemToEdit)
  const editorMode = useAppSelector(selectItemEditorMode)
  const search = useAppSelector(selectSearch)
  const guestsPerRoom = useAppSelector(selectGuestsPerRoom)
  const cartHasItems = useAppSelector(selectCartHasItems)
  const cartItemsPropertyId = useAppSelector(selectCartItemsProperty)
  const isMultiPropertyViolation =
    cartHasItems && cartItemsPropertyId !== item?.propertyId
  const ratePlanId = item?.offer?.ratePlan?.id || ''
  const serviceOffersQuery = useSearchServiceOffersQuery(
    {
      ratePlanId,
      adults: guestsPerRoom,
      dateRange: search.dateRange
    },
    {skip: !ratePlanId}
  )
  const isLoading = serviceOffersQuery.isLoading
  const serviceOffers: ServiceOffer[] = serviceOffersQuery.data?.services || []
  const additionalServiceIds = item?.additionalServices
    ? item.additionalServices.map(({service}) => service.id)
    : []

  if (!item) {
    return null
  }

  return (
    <>
      {isOpen && (
        <FocusTrap active={isOpen}>
          <div
            className="modal modal-open"
            onClick={e => {
              if (e.target === e.currentTarget) {
                dispatch(hideItemEditor())
              }
            }}
            role="dialog"
            aria-modal="true"
          >
            <div className="modal-box w-[95%] max-w-screen-md max-h-[95%] px-4 md:px-6">
              <h1 className="text-lg font-bold">
                {editorMode === 'Add' ? 'Add room' : 'Edit room details'}
              </h1>
              <button
                className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4"
                onClick={() => {
                  dispatch(hideItemEditor())
                }}
                aria-label="Close shopping cart item."
              >
                ✕
              </button>

              {isMultiPropertyViolation && (
                <Alert type="error" className="my-4">
                  <p>
                    You cannot add rooms from multiple properties to the
                    shopping cart. If you would like to book rooms from multiple
                    properties, you will need to complete the booking process
                    for each property individually. You may also clear existing
                    items in the shopping cart to continue adding this room.
                  </p>
                  <button
                    className="btn mt-4"
                    onClick={() => {
                      logger.info({
                        message:
                          'Clearing shopping cart to add room from a different property...',
                        data: {item, cartItemsPropertyId}
                      })
                      ReactGA.event({
                        category: 'IBE',
                        action: 'Shopping_Cart_Clear',
                        value: 1
                      })
                      dispatch(clearCart())
                    }}
                    aria-label="Clear shopping cart."
                  >
                    Clear shopping cart
                  </button>
                </Alert>
              )}

              {isLoading && (
                <div className="flex flex-col items-center justify-center h-48">
                  <span className="loading loading-spinner"></span>
                  <h2 className="text-lg">Loading services...</h2>
                </div>
              )}

              {!isLoading && serviceOffers.length > 0 && (
                <>
                  <h2 className="font-semibold text-sm mt-2 mb-1">
                    Choose Additional Services:
                  </h2>
                  <div className="flex flex-col gap-2">
                    {serviceOffers.map(
                      (serviceOffer: ServiceOffer, index: number) => {
                        return (
                          <ShoppingCartServiceItem
                            key={index}
                            serviceOffer={serviceOffer}
                            isSelected={additionalServiceIds.includes(
                              serviceOffer.service.id
                            )}
                            onSelectionChange={(isSelected: boolean) => {
                              logger.info({
                                message: 'Updated service selection...',
                                data: {isSelected, serviceOffer}
                              })
                              const updatedItem = {
                                ...item,
                                additionalServices: isSelected
                                  ? [...item.additionalServices, serviceOffer]
                                  : item.additionalServices.filter(service => {
                                      return (
                                        service.service.id !==
                                        serviceOffer.service.id
                                      )
                                    })
                              }

                              dispatch(updateItemEditor(updatedItem))
                            }}
                          />
                        )
                      }
                    )}
                  </div>
                </>
              )}

              <ShoppingCartItem
                item={item}
                className="mt-4"
                showActions={false}
                showItemIndex={false}
              />

              <div>
                <div className="modal-action">
                  {editorMode === 'Add' ? (
                    <>
                      <button
                        className="flex flex-col items-center gap-0 btn btn-ghost"
                        disabled={isLoading || isMultiPropertyViolation}
                        onClick={() => {
                          logger.info({
                            message: 'Added room to cart.',
                            data: {item}
                          })
                          ReactGA.event({
                            category: 'IBE',
                            action: 'Shopping_Cart_Add',
                            value: 1
                          })
                          dispatch(addItem(item))
                          dispatch(hideItemEditor())
                          // show toast notification
                          dispatch(
                            addToastNotification({
                              id: generateUUID(),
                              type: 'add',
                              message:
                                'Room successfully added to your shopping cart.'
                            })
                          )
                        }}
                        aria-label="Add room to shopping cart and continue shopping."
                      >
                        Add Room
                      </button>

                      <button
                        className="btn btn-secondary"
                        disabled={isLoading || isMultiPropertyViolation}
                        onClick={() => {
                          logger.info({
                            message:
                              'Added room to cart, continuing to checkout.',
                            data: {item}
                          })
                          ReactGA.event({
                            category: 'IBE',
                            action: 'Shopping_Cart_Add_Checkout',
                            value: 1
                          })
                          dispatch(addItem(item))
                          dispatch(hideItemEditor())
                          dispatch(setAppView(AppView.CHECKOUT))
                        }}
                        aria-label="Add room to shopping cart and checkout."
                      >
                        Add Room & Checkout
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-ghost"
                        onClick={() => {
                          logger.info({
                            message: 'Cancelled edits to room item...',
                            data: {item}
                          })
                          ReactGA.event({
                            category: 'IBE',
                            action: 'Shopping_Cart_Cancel_Edit',
                            value: 1
                          })
                          dispatch(hideItemEditor())
                        }}
                        aria-label="Cancel edits to shopping cart item."
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-secondary"
                        disabled={isLoading || isMultiPropertyViolation}
                        onClick={() => {
                          logger.info({
                            message: 'Saved edits to room item...',
                            data: {item}
                          })
                          ReactGA.event({
                            category: 'IBE',
                            action: 'Shopping_Cart_Save_Edit',
                            value: 1
                          })
                          dispatch(updateItem(item))
                          dispatch(hideItemEditor())
                        }}
                        aria-label="Save edits to shopping cart item."
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  )
}

export default ShoppingCartItemEditorModal
