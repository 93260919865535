import React, {useEffect} from 'react'
import SearchBar from './SearchBar'
import SearchPropertyResults from '../search-property/SearchPropertyResults'
import {AppView, PropertyConfig, SearchParams} from '../../types'
import {useParallelSearchOffersQuery} from '../../hooks/useParallelSearchOffersQuery'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectSearch, setSearch} from '../../store/searchSlice'
import SearchUnitResults from '../search-unit/SearchUnitResults'
import {setAppView} from '../../store/appViewSlice'
import Breadcrumb from '../common/Breadcrumb'
import {getLogger} from '../../services/logging'
import SearchHome from './SearchHome'
import {selectConfig} from '../../store/configSlice'

const logger = getLogger('SearchView')

/**
 * Gets a list of property configs for a location
 * @param propertyConfigs
 * @param locationId
 * @returns
 */
export function getPropertyConfigsForLocation(
  propertyConfigs: PropertyConfig[],
  locationId: string
): PropertyConfig[] {
  return locationId === 'ALL'
    ? propertyConfigs
    : propertyConfigs.filter(property => property.location === locationId)
}

interface SearchViewProps {
  appView: AppView
}

const SearchView = ({appView}: SearchViewProps) => {
  const dispatch = useAppDispatch()
  const searchParams = useAppSelector(selectSearch)
  const config = useAppSelector(selectConfig)
  const {
    offerResults,
    loading,
    triggerParallelFetch
  } = useParallelSearchOffersQuery()

  useEffect(() => {
    if (config && searchParams && searchParams.location !== 'ALL') {
      const properties = getPropertyConfigsForLocation(
        config.properties,
        searchParams.location
      )

      const {dateRange, guests, specialRates} = searchParams
      const requests = properties.map(property => {
        return {
          propertyId: property.propertyApaleoId,
          dateRange,
          guests,
          specialRates
        }
      })

      logger.info({
        message: 'Searching for properties...',
        data: {requests}
      })

      triggerParallelFetch(requests)
    }
  }, [config, searchParams])

  return (
    <section>
      <Breadcrumb appView={appView} className="mb-2" />
      <SearchBar
        searchParams={searchParams}
        onSubmit={(searchParams: SearchParams) => {
          dispatch(setSearch(searchParams))
          dispatch(setAppView(AppView.CHOOSE_PROPERTY))
        }}
        className="mb-8"
      />

      {appView === AppView.HOME && <SearchHome />}

      {appView === AppView.CHOOSE_PROPERTY && (
        <SearchPropertyResults
          searchParams={searchParams}
          offerResults={offerResults}
          isLoading={loading}
          onSelectProperty={(propertyId: string) => {
            dispatch(setSearch({...searchParams, propertyId}))
            dispatch(setAppView(AppView.CHOOSE_UNIT))
          }}
        />
      )}

      {appView === AppView.CHOOSE_UNIT && (
        <SearchUnitResults
          offerResults={offerResults}
          selectedPropertyId={searchParams.propertyId || ''}
          isLoading={loading}
        />
      )}
    </section>
  )
}

export default SearchView
