import React, {useState} from 'react'
import AtlantaImage from '../../assets/home-atl.jpeg'
import AustinImage from '../../assets/home-atx.jpeg'
import DCImage from '../../assets/home-dc.jpeg'
import NashvilleImage from '../../assets/home-nash.jpeg'
import NewYorkImage from '../../assets/home-nyc.jpeg'
import SanAntonioImage from '../../assets/home-satx.jpeg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import clsx from 'clsx'

interface SearchGridImageProps {
  gridArea: string
  src: string
  alt: string
  label: string
  heightClasses: string
}

const SearchGridImage = ({
  gridArea,
  src,
  alt,
  label,
  heightClasses
}: SearchGridImageProps) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  return (
    <div className="bg-gray-300 relative" style={{gridArea}}>
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsImgLoaded(true)}
        onError={() => setIsImgLoaded(true)}
        className={clsx(heightClasses, {
          'object-cover w-full m': isImgLoaded,
          'hidden': !isImgLoaded
        })}
      />
      <div
        className={clsx({
          'absolute bottom-0 inset-x-0 bg-black p-2 opacity-70': isImgLoaded,
          'hidden': !isImgLoaded
        })}
      >
        <span className="text-white text-sm md:text-base">{label}</span>
      </div>
    </div>
  )
}

const SearchHome = () => {
  const {isMedium} = useWindowDimensions()
  return (
    <div className="flex flex-col items-center gap-2 mb-8">
      <h1 className="text-3xl text-center">
        Combining the best of{' '}
        <span className="font-bold">home and hospitality.</span>
      </h1>
      <h2 className="text-center">
        Begin your search to discover the perfect Placemakr location for you.
      </h2>

      <div
        className="gap-1 mt-4"
        style={{
          display: 'grid',
          gridTemplateAreas: isMedium
            ? `'A A B C' 'D E B F'`
            : `'A A' 'D E' 'F F' 'B C'`
        }}
      >
        <SearchGridImage
          gridArea="A"
          src={NashvilleImage}
          alt="Nashville"
          label="Nashville"
          heightClasses="h-72 md:h-full"
        />
        <SearchGridImage
          gridArea="B"
          src={NewYorkImage}
          alt="New York"
          label="New York"
          heightClasses="h-full"
        />
        <SearchGridImage
          gridArea="C"
          src={SanAntonioImage}
          alt="San Antonio"
          label="San Antonio"
          heightClasses="h-72 md:h-full"
        />
        <SearchGridImage
          gridArea="D"
          src={AustinImage}
          alt="Austin"
          label="Austin"
          heightClasses="h-72 md:h-full"
        />
        <SearchGridImage
          gridArea="E"
          src={DCImage}
          alt="Washington, DC"
          label="Washington, DC"
          heightClasses="h-72 md:h-full"
        />
        <SearchGridImage
          gridArea="F"
          src={AtlantaImage}
          alt="Atlanta"
          label="Atlanta"
          heightClasses="h-72 md:h-full"
        />
      </div>
    </div>
  )
}

export default SearchHome
