import React from 'react'
import {Offer} from '../../types'
import {formatCurrency, formatDate} from '../../services/utils'
import {format, isAfter, isDate, parseISO, roundToNearestHours} from 'date-fns'

export interface CancellationPolicyProps {
  offer: Offer
}

/**
 * Formats a date time
 * @param date
 * @returns
 */
export function formatTime(d: Date | string): string {
  const date = isDate(d) ? (d as Date) : parseISO(d as string)
  return format(date, 'h:00 a')
}

const CancellationPolicy = ({offer}: CancellationPolicyProps) => {
  const cancellation = offer.cancellationFee
  const cancellationFee = cancellation.fee?.amount || 0

  return (
    <div>
      <h3 className="font-semibold text-base border-b mb-2">
        Cancellation Policy
      </h3>
      <p>{offer.cancellationFee.description}</p>
      {cancellationFee && cancellationFee > 0 && (
        <p className="mt-1">
          Cancellations outside of the allowable window will incur a fee of{' '}
          <span className="font-semibold">
            {formatCurrency(cancellationFee)}
          </span>
          .
        </p>
      )}
    </div>
  )
}

export default CancellationPolicy
