import React, {useState} from 'react'
import CheckoutGuestInfo from './CheckoutGuestInfo'
import CheckoutPayment from './CheckoutPayment'
import Breadcrumb from '../common/Breadcrumb'
import {AppView} from '../../types'
import ShoppingCart from '../cart/ShoppingCart'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import CheckoutProcessingModal from './CheckoutProcessingModal'
import {setAppView} from '../../store/appViewSlice'
import {
  clearGuestInfo,
  selectIsValidGuestInfo
} from '../../store/guestInfoSlice'
import {
  clearCart,
  selectCartHasItems,
  selectHasMemberRate
} from '../../store/shoppingCartSlice'
import {clearSearch} from '../../store/searchSlice'
import {getLogger} from '../../services/logging'
import EmptyCart from '../../assets/ibe-empty-cart.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import {selectIsAuthenticated} from '../../store/userSlice'
import clsx from 'clsx'

const logger = getLogger('CheckoutView')

export interface CheckoutViewProps {
  appView: AppView
}

const CheckoutView = ({appView}: CheckoutViewProps) => {
  const dispatch = useAppDispatch()
  const hasItems = useAppSelector(selectCartHasItems)
  const hasMemberRate = useAppSelector(selectHasMemberRate)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false)
  const [paymentSessionId, setPaymentSessionId] = useState<string>(
    undefined as any
  )
  const isValidGuestInfo = useAppSelector(selectIsValidGuestInfo)

  return (
    <section>
      <Breadcrumb appView={appView} />
      <h1 className="font-semibold my-1">
        Complete your booking with Placemakr
      </h1>
      {hasItems ? (
        <div>
          {/* Member rate message */}
          {hasMemberRate && !isAuthenticated && (
            <div className="card card-compact bg-white border shadow mt-4 mx-auto w-3/4">
              <div
                className={clsx(
                  'card-body flex ',
                  'flex-col items-start gap-1',
                  'md:flex-row md:items-center md:gap-4'
                )}
              >
                <div className="flex-0 badge badge-primary rounded text-xs">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="mr-1"
                    title="Member rate"
                  />
                  <span className="italic">Member Rate</span>
                </div>
                <p className="flex-1 text-sm text-light-primary italic">
                  You've selected an exclusive Placemakr Member rate. Join for
                  free or sign in below. Don't want to join? Please select a
                  non-Member Rate.
                </p>
              </div>
            </div>
          )}
          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            <div className="flex-1 lg:order-last">
              <ShoppingCart />
            </div>
            <div className="divider lg:hidden my-0" />
            <div className="flex-1 flex flex-col gap-4">
              <CheckoutGuestInfo />
              <CheckoutPayment
                isActive={isValidGuestInfo}
                onPaymentProcessing={(paymentSessionId: string) => {
                  logger.info({
                    message: 'Processing payment...',
                    data: {paymentSessionId}
                  })
                  setPaymentSessionId(paymentSessionId)
                  setIsProcessingModalOpen(true)
                }}
              />
              <CheckoutProcessingModal
                isOpen={isProcessingModalOpen}
                onComplete={() => {
                  logger.info({
                    message: 'Payment processing complete',
                    data: {paymentSessionId}
                  })
                  setIsProcessingModalOpen(false)
                  dispatch(clearCart())
                  dispatch(clearSearch())
                  dispatch(clearGuestInfo())
                  dispatch(setAppView(AppView.CONFIRMATION))
                }}
                onClose={() => setIsProcessingModalOpen(false)}
                paymentSessionId={paymentSessionId}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8 flex flex-col gap-4 items-center">
          <img
            src={EmptyCart}
            alt="Shopping cart is empty image."
            className="max-h-72"
          />
          <h2 className="text-xl font-semibold">Your cart is empty</h2>
          <p className="text-center">
            Please add items to your cart before proceeding to checkout.
          </p>
          <button
            className="btn btn-secondary"
            onClick={() => {
              dispatch(setAppView(AppView.HOME))
            }}
            aria-label="Start a new search."
          >
            start a new search
          </button>
        </div>
      )}
    </section>
  )
}

export default CheckoutView
