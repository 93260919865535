import React from 'react'
import {UnitGroupOffer} from '../../types'
import SearchUnitAttributes from './SearchUnitAttributes'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import SearchUnitOfferItem from './SearchUnitOfferItem'
import ImageCarousel from '../common/ImageCarousel'
import SearchUnitDetailsModal from './SearchUnitDetailsModal'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'
import {useAppSelector} from '../../store/hooks'
import {
  selectPropertiesById,
  selectUnitGroupsById
} from '../../store/configSlice'
import ReactGA from 'react-ga4'

const logger = getLogger('SearchUnitResultItem')

export interface SearchUnitResultItemProps {
  propertyId: string
  unitGroupOffer: UnitGroupOffer
}

const AVAILABLE_UNITS_THRESHOLD = 5

function getAvailableUnitsLabel(availableUnits: number): string | undefined {
  if (availableUnits === 1) {
    return 'Only 1 room left'
  } else if (availableUnits <= AVAILABLE_UNITS_THRESHOLD) {
    return `Only ${availableUnits} rooms left`
  } else {
    return
  }
}

const SearchUnitResultItem = ({
  propertyId,
  unitGroupOffer
}: SearchUnitResultItemProps) => {
  const propertyLookup = useAppSelector(selectPropertiesById)
  const propertyConfig = propertyLookup[propertyId]

  const [showDetailsModal, setShowDetailsModal] = React.useState(false)
  const {unitGroup, availableUnits, offers} = unitGroupOffer
  const sortedOffers = offers.sort((x, y) => {
    return x.totalGrossAmount.amount - y.totalGrossAmount.amount
  })
  const unitGroupConfigsById = useAppSelector(selectUnitGroupsById)
  const unitGroupConfig = unitGroupConfigsById[unitGroup.id]

  return (
    <>
      <div className="card bg-white shadow">
        <div className="flex flex-col md:flex-row">
          {/* image carousel */}
          <div
            className={clsx(
              'w-full overflow-hidden rounded-tl-2xl rounded-tr-2xl h-72',
              'md:w-5/12 md:rounded-bl-2xl md:rounded-tr-none md:h-auto'
            )}
          >
            <ImageCarousel images={unitGroupConfig?.images} />
          </div>

          <div className="w-full md:w-7/12">
            {/* unit header */}
            <div className="w-full flex flex-col gap-1 p-4 pb-3">
              {/* unit group title */}
              <h1 className="text-xl font-semibold">{unitGroup.name}</h1>

              {/* property name/city */}
              <div className="text-sm text-light-primary text-pretty mb-2">
                {propertyConfig.name} &#x2022; {propertyConfig.location}{' '}
              </div>

              {/* unit group attributes */}
              <SearchUnitAttributes
                unitAttributes={unitGroupConfig?.attributes}
                className="mb-1"
              />

              {/* unit group description */}
              <div className="text-sm text-light-primary">
                {unitGroupConfig?.description}
              </div>

              {/* unit group details modal */}
              <a
                className={clsx(
                  'link text-sm text-accent font-semibold',
                  'no-underline hover:underline'
                )}
                onClick={() => {
                  ReactGA.event({
                    category: 'IBE',
                    action: 'Unit_Group_Details_View',
                    value: 1
                  })
                  logger.info({
                    message: 'Viewing room details modal...',
                    data: {unitGroupOffer}
                  })
                  setShowDetailsModal(true)
                }}
              >
                View room details
              </a>
            </div>

            <div className="divider my-0 mx-4" />

            {/* unit low availability warning */}
            {availableUnits < AVAILABLE_UNITS_THRESHOLD && (
              <div className="flex flex-row justify-end px-4 pt-2">
                <div className="text-red-500 text-sm font-medium">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="mr-1"
                  />
                  <span>{getAvailableUnitsLabel(availableUnits)}</span>
                </div>
              </div>
            )}

            {/* unit offers */}
            <div className="w-full flex flex-col gap-2 p-2">
              {sortedOffers.map(offer => {
                return (
                  <div
                    className="rounded-lg bg-gray-100 p-3"
                    key={offer?.ratePlan?.id}
                  >
                    <SearchUnitOfferItem
                      offer={offer}
                      propertyId={propertyId}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <SearchUnitDetailsModal
        propertyId={propertyId}
        unitGroup={unitGroupOffer?.unitGroup}
        isOpen={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false)
        }}
      />
    </>
  )
}

export default SearchUnitResultItem
