import React, {useEffect} from 'react'
import {removeItem, showItemEditor} from '../../store/shoppingCartSlice'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import * as types from '../../types'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faImage,
  faInfoCircle,
  faStar,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import {formatDate, generateUUID} from '../../services/utils'
import {addToastNotification} from '../../store/toastNotificationSlice'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import {
  selectPropertiesById,
  selectUnitGroupsById
} from '../../store/configSlice'
import ShoppingCartPricingSummary from './ShoppingCartPricingSummary'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import CancellationPolicy from '../common/CancellationPolicy'
import NoShowPolicy from '../common/NoShowPolicy'
import {isMemberRate} from '../../services/offerUtils'

const logger = getLogger('ShoppingCartItem')

export interface ShoppingCartItemProps {
  item: types.ShoppingCartItem
  itemIndex?: number
  showItemIndex?: boolean
  showActions?: boolean
  className?: string
}

const ShoppingCartItem = ({
  item,
  itemIndex = -1,
  showItemIndex = false,
  showActions = false,
  className
}: ShoppingCartItemProps) => {
  const [showCancellationPolicy, setShowCancellationPolicy] = React.useState(
    false
  )
  const {isLarge} = useWindowDimensions()
  const dispatch = useAppDispatch()
  const propertyConfigLookup = useAppSelector(selectPropertiesById)
  const unitGroupConfigLookup = useAppSelector(selectUnitGroupsById)
  const propertyConfig = propertyConfigLookup
    ? propertyConfigLookup[item?.propertyId]
    : null
  const propertyName = propertyConfig?.name
  const propertyLocation = propertyConfig?.location
  const unitGroupConfig = unitGroupConfigLookup
    ? unitGroupConfigLookup[item?.offer?.unitGroup?.id]
    : null
  const roomImage = unitGroupConfig?.images?.[0]
  const specialRateCode = item?.specialRateCode
  const hasMemberRate = isMemberRate(item.offer)

  const handleEditItem = () => {
    logger.info({
      message: 'Editing room item in shopping cart...',
      data: {item}
    })
    ReactGA.event({
      category: 'IBE',
      action: 'Shopping_Cart_Edit',
      value: 1
    })
    dispatch(showItemEditor({item, mode: 'Edit'}))
  }

  const handleRemoveItem = () => {
    logger.info({
      message: 'Removing room item from shopping cart...',
      data: {item}
    })
    ReactGA.event({
      category: 'IBE',
      action: 'Shopping_Cart_Remove',
      value: 1
    })
    dispatch(removeItem(item.id))

    const roomName = item?.offer?.unitGroup?.name || 'Room'
    dispatch(
      addToastNotification({
        id: generateUUID(),
        type: 'remove',
        message: `${roomName} successfully removed from your shopping cart.`
      })
    )
  }

  // close cancellation policy dropdown on window clicks
  useEffect(() => {
    const handleClick = () => {
      if (showCancellationPolicy) {
        setShowCancellationPolicy(false)
      }
    }
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [showCancellationPolicy])

  return (
    <div
      className={clsx(
        'card card-compact border bg-white shadow w-full',
        className
      )}
    >
      <div className="card-body">
        {/* card header */}
        <div className="flex flex-col gap-2 mb-2">
          {showItemIndex && (
            <div className="badge bg-base-400 text-light-primary text-xs font-semibold">
              room {itemIndex + 1}
            </div>
          )}
          <div className="w-full">
            <h4 className="text-xs text-accent font-semibold uppercase">
              {propertyLocation}
            </h4>

            <h3 className="font-medium text-md md:text-lg">
              {propertyName && <span>{propertyName} &#8226; </span>}
              <span>{item.offer.unitGroup.name}</span>
            </h3>
            <div>{item.offer.ratePlan.name}</div>
            <div
              className={clsx(
                'flex flex-col',
                'md:flex-row md:gap-2 md:items-center'
              )}
            >
              <div>
                {formatDate(item.offer.arrival)}-
                {formatDate(item.offer.departure)}
              </div>
              <div className="hidden md:block">&#8226;</div>

              {/* cancellation / no show policy */}
              <div
                className={clsx('dropdown', {
                  'dropdown-open': showCancellationPolicy,
                  'dropdown-hover': isLarge
                })}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-accent mr-1"
                    title="Cancellation policy info"
                  />
                  <a
                    className="link no-underline hover:underline"
                    onClick={() => {
                      if (!isLarge) {
                        setShowCancellationPolicy(!showCancellationPolicy)
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                      if (e.key === 'Enter') {
                        setShowCancellationPolicy(!showCancellationPolicy)
                      }
                    }}
                    tabIndex={0}
                    aria-label="Show cancellation/no show policies."
                  >
                    Cancellation Policy
                  </a>
                </div>

                <div
                  tabIndex={0}
                  className="card compact dropdown-content bg-white border rounded-box z-[1] w-64 shadow-lg"
                >
                  <div tabIndex={0} className="card-body">
                    <div className="flex flex-col gap-4">
                      <CancellationPolicy offer={item.offer} />
                      <NoShowPolicy offer={item.offer} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-2 w-full">
          <div className="w-full sm:w-1/3">
            {/* item image */}
            <div className="w-full h-full">
              {roomImage ? (
                <img
                  src={roomImage.image}
                  alt={roomImage.altText}
                  className="object-cover rounded-xl"
                />
              ) : (
                <div className="h-36 flex items-center justify-center bg-gray-200 rounded-xl">
                  <FontAwesomeIcon
                    icon={faImage}
                    className="text-2xl"
                    title="Empty room image"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="w-full sm:w-2/3 flex flex-col gap-2">
            {/* special rate */}
            {item.specialRateCode && (
              <div className="badge badge-primary rounded text-xs">
                <FontAwesomeIcon
                  icon={faStar}
                  className="mr-1"
                  title="Special rate"
                />
                <span className="italic">{specialRateCode}</span>
              </div>
            )}

            {/* member rate */}
            {!item.specialRateCode && hasMemberRate && (
              <div className="badge badge-primary rounded text-xs">
                <FontAwesomeIcon
                  icon={faStar}
                  className="mr-1"
                  title="Special rate"
                />
                <span className="italic">Member Rate</span>
              </div>
            )}

            {/* item price details */}
            <ShoppingCartPricingSummary
              offer={item.offer}
              additionalServices={item.additionalServices}
              className="flex-1"
            />

            {/* item actions */}
            {showActions && (
              <div className="flex-0 flex flex-row justify-end gap-3">
                <a
                  className={clsx(
                    'flex flex-row gap-1 items-center',
                    'link text-xs md:text-sm font-medium text-accent no-underline hover:underline'
                  )}
                  onClick={handleRemoveItem}
                  onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                    if (e.key === 'Enter') {
                      handleRemoveItem()
                    }
                  }}
                  tabIndex={0}
                  aria-label="Remove this room from your shopping cart."
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-accent"
                    title="Delete shopping cart item"
                  />
                  <span className="text-light-primary">remove room</span>
                </a>
                <a
                  className={clsx(
                    'flex flex-row gap-1 items-center',
                    'link text-xs md:text-sm font-medium no-underline hover:underline'
                  )}
                  onClick={handleEditItem}
                  onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                    if (e.key === 'Enter') {
                      handleEditItem()
                    }
                  }}
                  tabIndex={0}
                  aria-label="Edit this room's details."
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="text-accent"
                    title="Edit shopping cart item"
                  />
                  <span className="text-light-primary">edit room</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartItem
