import React from 'react'
import clsx from 'clsx'

interface NumberStepperProps {
  value: number
  min?: number
  max?: number
  step?: number
  onChange?: (value: number) => void
  className?: string
}

const NumberStepper: React.FC<NumberStepperProps> = ({
  value,
  min = 0,
  max = 100,
  step = 1,
  onChange = (value: number) => {},
  className = ''
}) => {
  const handleDecrementValue = () => {
    const updatedValue = value - step
    if (updatedValue >= min) {
      onChange(updatedValue)
    }
  }

  const handleIncrementValue = () => {
    const updatedValue = value + step
    if (updatedValue <= max) {
      onChange(updatedValue)
    }
  }

  return (
    <div className={clsx('flex flex-row items-center gap-2', className)}>
      <a
        className={clsx('btn btn-circle btn-xs', {
          'btn-disabled': value <= min
        })}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleDecrementValue()
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleDecrementValue()
          }
        }}
        tabIndex={0}
        aria-label="Decrease the number value by one."
      >
        -
      </a>
      <span>{value}</span>
      <a
        className={clsx('btn btn-circle btn-xs', {
          'btn-disabled': value >= max
        })}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleIncrementValue()
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleIncrementValue()
          }
        }}
        tabIndex={0}
        aria-label="Increase the number value by one."
      >
        +
      </a>
    </div>
  )
}

export default NumberStepper
