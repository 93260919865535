import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {GuestInfo} from '../types'

const initialState: GuestInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  formattedPhone: '',
  comments: '',
  acceptTerms: false
}

export const guestInfoSlice = createSlice({
  name: 'guestInfo',
  initialState,
  reducers: {
    setGuestInfo: (state, action: PayloadAction<GuestInfo>) => {
      state = action.payload
      return state
    },
    clearGuestInfo: state => {
      state = initialState
      return state
    }
  }
})

// selectors

/**
 * Gets the guestInfo
 * @param state
 * @returns
 */
export const selectGuestInfo = (state: any): GuestInfo => {
  return state.guestInfo
}

/**
 * Checks if the guestInfo is valid
 */
export const selectIsValidGuestInfo = createSelector(
  [selectGuestInfo],
  (guestInfo: GuestInfo): boolean => {
    return (
      guestInfo?.firstName?.length > 0 &&
      guestInfo?.lastName?.length > 0 &&
      guestInfo?.email?.length > 0 &&
      guestInfo?.phone?.length > 0 &&
      guestInfo.acceptTerms === true
    )
  }
)

/**
 * Gets the guestInfo's email address
 */
export const selectGuestInfoEmail = createSelector(
  [selectGuestInfo],
  (guestInfo: GuestInfo): string => {
    return guestInfo.email
  }
)

// actions
export const {clearGuestInfo, setGuestInfo} = guestInfoSlice.actions

// reducer
export default guestInfoSlice.reducer
