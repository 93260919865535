import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ToastNotification} from '../types'

interface ToastNotificationState {
  notifications: ToastNotification[]
}

const initialState: ToastNotificationState = {
  notifications: []
}

export const toastNotificationSlice = createSlice({
  name: 'toastNotification',
  initialState,
  reducers: {
    addToastNotification: (
      state: ToastNotificationState,
      action: PayloadAction<ToastNotification>
    ) => {
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      }
    },
    removeToastNotification: (
      state: ToastNotificationState,
      action: PayloadAction<ToastNotification>
    ) => {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.id
        )
      }
    }
  }
})

// selectors
export const selectToastNotifications = (state: any): ToastNotification[] => {
  return state.toastNotification.notifications
}

// actions
export const {
  addToastNotification,
  removeToastNotification
} = toastNotificationSlice.actions

// reducer
export default toastNotificationSlice.reducer
