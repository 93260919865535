import React from 'react'
import {useAppSelector} from '../../store/hooks'
import {selectToastNotifications} from '../../store/toastNotificationSlice'
import ToastNotification from './ToastNotification'

const ToastNotificationManager = () => {
  const toastNotifications = useAppSelector(selectToastNotifications)

  return (
    <div
      className="toast toast-top toast-end whitespace-normal mt-14 z-[9999]"
      aria-label="User notifications area."
      aria-live="polite"
    >
      {toastNotifications.map((notification, index) => (
        <ToastNotification key={index} notification={notification} />
      ))}
    </div>
  )
}

export default ToastNotificationManager
