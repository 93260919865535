import React from 'react'
import {UnitGroupOffer} from '../../types'
import SearchUnitAttributes from './SearchUnitAttributes'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import SearchUnitOfferItem from './SearchUnitOfferItem'
import ImageCarousel from '../common/ImageCarousel'
import SearchUnitDetailsModal from './SearchUnitDetailsModal'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'
import {useAppSelector} from '../../store/hooks'
import {
  selectPropertiesById,
  selectUnitGroupsById
} from '../../store/configSlice'
import ReactGA from 'react-ga4'

const logger = getLogger('SearchUnitResultItem')

export interface SearchUnitResultItemProps {
  propertyId: string
  unitGroupOffer: UnitGroupOffer
  specialRateCode?: string | null
  specialRatePlans?: string[] | null
}

const AVAILABLE_UNITS_THRESHOLD = 5
const DISPLAY_OFFER_THRESHOLD = 3

function getAvailableUnitsLabel(availableUnits: number): string | undefined {
  if (availableUnits === 1) {
    return 'Only 1 room left'
  } else if (availableUnits <= AVAILABLE_UNITS_THRESHOLD) {
    return `Only ${availableUnits} rooms left`
  } else {
    return
  }
}

const SearchUnitResultItem = ({
  propertyId,
  unitGroupOffer,
  specialRateCode,
  specialRatePlans
}: SearchUnitResultItemProps) => {
  const [showDetailsModal, setShowDetailsModal] = React.useState(false)
  const [showAllOffers, setShowAllOffers] = React.useState(false)
  const propertyLookup = useAppSelector(selectPropertiesById)
  const propertyConfig = propertyLookup[propertyId]
  const {unitGroup, availableUnits, offers} = unitGroupOffer
  const allOffers = offers.sort((x, y) => {
    return x.totalGrossAmount.amount - y.totalGrossAmount.amount
  })

  const hasCollapsibleOffers = allOffers.length > DISPLAY_OFFER_THRESHOLD
  const displayOffers =
    hasCollapsibleOffers && showAllOffers
      ? allOffers
      : allOffers.slice(0, DISPLAY_OFFER_THRESHOLD)
  const allOffersCount = allOffers.length
  const unitGroupConfigsById = useAppSelector(selectUnitGroupsById)
  const unitGroupConfig = unitGroupConfigsById[unitGroup.id]

  const handleShowDetailsModal = () => {
    ReactGA.event({
      category: 'IBE',
      action: 'Unit_Group_Details_View',
      value: 1
    })
    logger.info({
      message: 'Viewing room details modal...',
      data: {unitGroupOffer}
    })
    setShowDetailsModal(true)
  }

  const handleToggleViewAllOffers = () => {
    ReactGA.event({
      category: 'IBE',
      action: 'View_All_Offers',
      value: 1
    })
    logger.info({
      message: 'Viewing all offers...',
      data: {unitGroupOffer}
    })
    setShowAllOffers(!showAllOffers)
  }

  return (
    <>
      <div className="card bg-white shadow">
        <div className="flex flex-col md:flex-row">
          {/* image carousel */}
          <div
            className={clsx(
              'w-full overflow-hidden rounded-tl-2xl rounded-tr-2xl h-72',
              'md:w-5/12 md:rounded-tr-none md:h-auto md:max-h-[600px]'
            )}
          >
            <ImageCarousel images={unitGroupConfig?.images} />
          </div>

          <div className="w-full md:w-7/12">
            {/* unit header */}
            <div className="w-full flex flex-col gap-1 p-4 pb-3">
              {/* unit group title */}
              <h1 className="text-xl font-semibold">{unitGroup.name}</h1>

              {/* property name/city */}
              <div className="text-sm text-light-primary text-pretty mb-2">
                {propertyConfig.name} &#x2022; {propertyConfig.location}{' '}
              </div>

              {/* unit group attributes */}
              <SearchUnitAttributes
                unitAttributes={unitGroupConfig?.attributes}
                className="mb-1"
              />

              {/* unit group description */}
              {unitGroupConfig?.description && (
                <div className="text-sm text-light-primary">
                  {unitGroupConfig?.description}
                </div>
              )}

              {/* unit group details modal */}
              <a
                className={clsx(
                  'link text-sm text-accent font-semibold',
                  'no-underline hover:underline'
                )}
                onClick={handleShowDetailsModal}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'Enter') {
                    handleShowDetailsModal()
                  }
                }}
                tabIndex={0}
                aria-label="View room details."
              >
                View room details
              </a>
            </div>

            <div className="divider my-0 mx-4" />

            {/* unit low availability warning */}
            {availableUnits < AVAILABLE_UNITS_THRESHOLD && (
              <div className="flex flex-row justify-end px-4 pt-2">
                <div className="text-red-500 text-sm font-medium">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="mr-1"
                    title="Low availability warning"
                  />
                  <span>{getAvailableUnitsLabel(availableUnits)}</span>
                </div>
              </div>
            )}

            {/* unit offers */}
            <div className="w-full flex flex-col gap-2 p-2">
              {displayOffers.map(offer => {
                return (
                  <div
                    className="rounded-lg bg-gray-100 p-3"
                    key={offer?.ratePlan?.id}
                  >
                    <SearchUnitOfferItem
                      offer={offer}
                      propertyId={propertyId}
                      specialRateCode={specialRateCode}
                      specialRatePlans={specialRatePlans}
                    />
                  </div>
                )
              })}
            </div>
            {hasCollapsibleOffers && (
              <div className="flex flex-row justify-end items-center pt-0 pb-3 px-3">
                <a
                  className="link font-semibold no-underline hover:underline"
                  onClick={handleToggleViewAllOffers}
                  onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                    if (e.key === 'Enter') {
                      handleToggleViewAllOffers()
                    }
                  }}
                  tabIndex={0}
                  aria-label="Toggle view all offers."
                >
                  <FontAwesomeIcon
                    icon={showAllOffers ? faCaretUp : faCaretRight}
                    className="mr-1 text-xs"
                    title="Toggle featured offers"
                  />
                  <span className="text-accent text-sm ">
                    {showAllOffers
                      ? 'View featured rates only'
                      : `View all ${allOffersCount} rates`}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <SearchUnitDetailsModal
        propertyId={propertyId}
        unitGroup={unitGroupOffer?.unitGroup}
        isOpen={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false)
        }}
      />
    </>
  )
}

export default SearchUnitResultItem
