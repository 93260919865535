import React, {useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
import {HubspotLocation} from '../../types'

export interface NavBarMobileMenuProps {
  locations: HubspotLocation[]
}

const NavBarMobileMenu = ({locations}: NavBarMobileMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
  }

  useEffect(() => {
    // Handler for clicks outside the dropdown
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current) {
        const element = dropdownRef.current as any
        if (!element.contains(event.target)) {
          setDropdownOpen(false)
        }
      }
    }

    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownOpen])

  return (
    <div className="dropdown dropdown-end" ref={dropdownRef}>
      <label
        tabIndex={0}
        className="btn btn-ghost btn-sm"
        onClick={toggleDropdown}
      >
        <FontAwesomeIcon icon={dropdownOpen ? faXmark : faBars} />
      </label>
      {dropdownOpen && (
        <div
          tabIndex={0}
          className="dropdown-content flex bg-white z-[60] w-screen h-screen"
          style={{insetInlineEnd: -16}}
        >
          <div className="flex flex-col gap-4 m-4">
            <div>
              <a
                className="text-base font-semibold cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/"
              >
                home
              </a>
            </div>

            <div>
              <a
                className="text-base font-semibold cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/locations"
              >
                locations
              </a>
              {locations?.length > 0 && (
                <ul className="mt-2 ml-2 list-inside grid grid-cols-2 gap-2">
                  {locations.map(location => (
                    <li key={location.name}>
                      <a
                        className="font-light-primary cursor-pointer hover:text-accent"
                        href={location.url}
                      >
                        {location.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <a className="text-base font-semibold cursor-pointer hover:text-accent">
                about
              </a>
              <ul className="mt-2 ml-2 list-inside grid grid-cols-2 gap-2">
                <li>
                  <a
                    className="font-light-primary cursor-pointer hover:text-accent"
                    href="https://www.placemakr.com/about/what-is-placemakr"
                  >
                    Who We Are
                  </a>
                </li>
                <li>
                  <a
                    className="font-light-primary cursor-pointer hover:text-accent"
                    href="https://www.placemakr.com/corporate/join-our-team"
                  >
                    Join Our Team
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <a className="text-base font-semibold cursor-pointer hover:text-accent">
                business
              </a>
              <ul className="mt-2 ml-2 list-inside grid grid-cols-2 gap-2">
                <li>
                  <a
                    className="font-light-primary cursor-pointer hover:text-accent"
                    href="https://www.placemakr.com/corporate-group"
                  >
                    Business Travel, Group, & Extended Stay
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <a
                className="text-base font-semibold cursor-pointer hover:text-accent"
                href="https://www.placemakr.com/"
              >
                real estate
              </a>
              <ul className="mt-2 ml-2 list-inside grid grid-cols-2 gap-2">
                <li>
                  <a
                    className="font-light-primary cursor-pointer hover:text-accent"
                    href="https://www.placemakr.com/partners"
                  >
                    Real Estate Platform
                  </a>
                </li>
                <li>
                  <a
                    className="font-light-primary cursor-pointer hover:text-accent"
                    href="https://www.placemakr.com/investments"
                  >
                    Investments
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavBarMobileMenu
