import React, {Component, ErrorInfo, ReactNode} from 'react'
import {getLogger} from '../services/logging'
import {getRootState} from '../store/store'
import Alert from './common/Alert'
import ReactGA from 'react-ga4'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

const logger = getLogger('AppErrorBoundary')

export default class AppErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    hasError: false
  }

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return {hasError: true}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log the error
    logger.error({
      message: 'Application Error',
      errorData: {
        error,
        errorInfo,
        reduxState: getRootState()
      }
    })

    ReactGA.event({
      category: 'IBE',
      action: 'App_Error',
      value: 1
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="mx-auto px-4 mt-4 lg:mt-8 max-w-screen-xl">
          <Alert type="error" className="p-8">
            An application error occurred. If the problem persists, please
            contact support:
            <a
              className="link"
              target="_blank"
              href="mailto:support@placemakr.com"
              aria-label="Email the Placemakr support team."
            >
              support@placemakr.com
            </a>
          </Alert>
        </div>
      )
    } else {
      return <>{this.props.children}</>
    }
  }
}
