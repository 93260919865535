import React from 'react'
import ShoppingCart from './ShoppingCart'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {setAppView} from '../../store/appViewSlice'
import {AppView} from '../../types'
import {
  hideCart,
  selectCartItemCount,
  selectIsCartOpen
} from '../../store/shoppingCartSlice'
import {getLogger} from '../../services/logging'

const logger = getLogger('ShoppingCartModal')

const ShoppingCartModal = () => {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectIsCartOpen)
  const cartItemCount = useAppSelector(selectCartItemCount)

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              dispatch(hideCart())
            }
          }}
        >
          <div className="modal-box w-[95%] max-w-screen-md max-h-[95%] px-4 md:px-6">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4"
              onClick={() => {
                logger.info({message: 'Closing shopping cart...'})
                dispatch(hideCart())
              }}
            >
              ✕
            </button>
            <div>
              <h1 className="text-xl font-bold mb-2">Shopping Cart</h1>

              <ShoppingCart />

              <div className="modal-action">
                {cartItemCount > 0 ? (
                  <>
                    <button
                      className="btn btn-ghost"
                      onClick={() => {
                        logger.info({
                          message:
                            'Continuing shopping and closing shopping cart...'
                        })
                        dispatch(hideCart())
                      }}
                    >
                      Continue Shopping
                    </button>

                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        logger.info({
                          message: 'Checking out and closing shopping cart...'
                        })
                        dispatch(hideCart())
                        dispatch(setAppView(AppView.CHECKOUT))
                      }}
                    >
                      Checkout
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      logger.info({message: 'Closing shopping cart...'})
                      dispatch(hideCart())
                    }}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ShoppingCartModal
