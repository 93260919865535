import {Dispatch} from '@reduxjs/toolkit'
import {getLogger} from '../services/logging'
import {Auth, Hub} from 'aws-amplify'
import {setMember} from '../store/memberSlice'
import {getConfig} from '../services/config'
import {Amplify} from 'aws-amplify'
import ReactGA from 'react-ga4'

const logger = getLogger('useInitCognitoAuth')

// amplify cognito configuration
const {cognitoRegion, cognitoUserPoolId, cognitoWebClientId} = getConfig()
Amplify.configure({
  Auth: {
    region: cognitoRegion,
    userPoolId: cognitoUserPoolId,
    userPoolWebClientId: cognitoWebClientId
  }
})

/**
 * Initializes the Cognito authorization state.  Checks for a valid session token, etc.
 * @param dispatch The Redux dispatch function
 */
export async function initAuthState(dispatch: Function) {
  logger.info({message: 'Initializing auth state...'})
  try {
    const session = await Auth.currentSession()
    logger.info({message: 'User session...', data: {session}})

    if (session && session.isValid()) {
      logger.info({message: 'User is already signed in'})
      const attributes = session.getIdToken().decodePayload()
      logger.info({message: 'User attributes...', data: {attributes}})
      dispatch(
        setMember({
          isAuthenticated: true,
          firstName: attributes.given_name,
          lastName: attributes.family_name,
          email: attributes.email,
          phone: attributes.phone_number
        })
      )

      ReactGA.event({
        category: 'IBE',
        action: 'User_Session_Restored',
        value: 1
      })
    } else {
      logger.info({message: 'User is not signed in'})
      dispatch(setMember({isAuthenticated: false}))
    }
  } catch (e) {
    logger.info({message: 'User is not signed in'})
    dispatch(setMember({isAuthenticated: false}))
  }
}

/**
 * Initializes the Cognito authorization listener.  Listens for auth events and updates the Redux store.
 * @param dispatch The redux dispatch function
 * @returns
 */
export function initAuthListener(dispatch: Dispatch): Function {
  logger.info({message: 'Initializing auth listener...'})

  const signIn = async (): Promise<void> => {
    const session = await Auth.currentSession()
    const attributes = session.getIdToken().decodePayload()
    const member = {
      isAuthenticated: true,
      email: attributes.email,
      firstName: attributes.given_name,
      lastName: attributes.family_name,
      phone: attributes.phone_number
    }

    logger.info({message: 'Signing in member...', data: {member}})
    dispatch(setMember(member))
  }

  const signOut = (): void => {
    logger.info({message: 'Signing out member...'})
    dispatch(setMember({isAuthenticated: false}))
  }

  const authListener = async (data: any): Promise<void> => {
    logger.info({message: 'Auth event...', data})

    switch (data?.payload?.event) {
      case 'signIn':
        logger.info({message: 'User signed in'})
        await signIn()
        break
      case 'signUp':
        logger.info({message: 'User signed up'})
        break
      case 'signOut':
        logger.info({message: 'User signed out'})
        signOut()
        break
      case 'signIn_failure':
        logger.error({message: 'User sign in failed'})
        break
      case 'tokenRefresh':
        logger.info({message: 'Auth token refresh succeeded'})
        break
      case 'tokenRefresh_failure':
        logger.error({message: 'Auth token refresh failed'})
        break
      case 'configured':
        logger.info({message: 'Auth module configured'})
        break
      case 'confirmSignUp':
        logger.info({message: 'User signed up, confirmation required'})
        break
      case 'autoSignIn':
        logger.info({
          message: 'User automatically signed in after signup confirmed'
        })
        break
      default:
        logger.warn({
          message: 'Unhandled auth event',
          data: {event: data?.payload?.event}
        })
    }
  }

  // add auth listener function
  Hub.listen('auth', authListener)

  // return auth listener cleanup function
  return () => {
    Hub.remove('auth', authListener)
  }
}
