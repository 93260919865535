import React, {useEffect, useRef} from 'react'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {SearchSpecialRatesParams} from '../../types'

export interface SearchSpecialRatesControlProps {
  specialRates: SearchSpecialRatesParams
  onChange: (updatedParams: SearchSpecialRatesParams) => void
  className?: string
}

interface FormState {
  hasPromoCode: boolean
  promoCode?: string
  hasCorporateCode: boolean
  corporateCode?: string
  hasAAA: boolean
  hasAARP: boolean
  hasGov: boolean
  hasMilitary: boolean
}

function getLabel({
  hasCorporateCode,
  hasPromoCode,
  hasAAA,
  hasAARP,
  hasGov,
  hasMilitary
}: FormState): string {
  if (hasPromoCode && hasCorporateCode) {
    return 'Promo & Corporate Code'
  } else if (hasPromoCode) {
    return 'Promo Code'
  } else if (hasCorporateCode) {
    return 'Corporate Code'
  } else if (hasAAA) {
    return 'AAA Rate'
  } else if (hasAARP) {
    return 'AARP Rate'
  } else if (hasGov) {
    return 'US Gov Rate'
  } else if (hasMilitary) {
    return 'US Military Rate'
  } else {
    return 'Best Available'
  }
}

const SearchSpecialRatesControl = ({
  specialRates,
  onChange,
  className = ''
}: SearchSpecialRatesControlProps) => {
  const [formState, setFormState] = React.useState<FormState>({
    hasPromoCode: specialRates?.promoCode ? true : false,
    promoCode: specialRates?.promoCode,
    hasCorporateCode: specialRates?.corporateCode ? true : false,
    corporateCode: specialRates?.corporateCode,
    hasAAA: specialRates?.hasAAA,
    hasAARP: specialRates?.hasAARP,
    hasGov: specialRates?.hasGov,
    hasMilitary: specialRates?.hasMilitary
  })

  const detailsRef = useRef<HTMLDetailsElement>(null)

  useEffect(() => {
    const {
      hasCorporateCode,
      hasPromoCode,
      corporateCode,
      promoCode,
      hasAAA,
      hasAARP,
      hasGov,
      hasMilitary
    } = formState
    onChange({
      ...specialRates,
      promoCode: hasPromoCode ? promoCode : '',
      corporateCode: hasCorporateCode ? corporateCode : '',
      hasAAA,
      hasAARP,
      hasGov,
      hasMilitary
    })
  }, [formState])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        detailsRef.current &&
        !detailsRef.current.contains(event.target as Node)
      ) {
        detailsRef.current.removeAttribute('open')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <details ref={detailsRef} className={clsx('dropdown min-w-52', className)}>
      <summary className="flex flex-row items-center cursor-pointer py-3.5 md:py-1">
        <div className="w-full font-medium text-sm">{getLabel(formState)}</div>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-2xs" />
      </summary>

      <div className="dropdown-content menu bg-white rounded-box z-[9999] w-64 p-4 mt-2 shadow flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasPromoCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasPromoCode: e.target.checked,
                    hasCorporateCode: false,
                    hasAAA: false,
                    hasAARP: false,
                    hasGov: false,
                    hasMilitary: false
                  })
                }}
              />
              <span className="label-text">Promo Code</span>
            </label>
          </div>
          {formState.hasPromoCode && (
            <div className="form-control">
              <input
                type="text"
                className="input input-sm focus:ring-0 outline-none"
                placeholder="Enter Promo Code"
                value={formState.promoCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    promoCode: e.target.value
                  })
                }}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasCorporateCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasCorporateCode: e.target.checked,
                    hasPromoCode: false,
                    hasAAA: false,
                    hasAARP: false,
                    hasGov: false,
                    hasMilitary: false
                  })
                }}
              />
              <span className="label-text">Corporate Code</span>
            </label>
          </div>
          {formState.hasCorporateCode && (
            <div className="form-control">
              <input
                type="text"
                className="input input-sm focus:ring-0 outline-none"
                placeholder="Enter Corporate Code"
                value={formState.corporateCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    corporateCode: e.target.value
                  })
                }}
              />
            </div>
          )}
        </div>

        <div className="form-control">
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="checkbox"
              className="toggle toggle-sm"
              checked={formState.hasAAA}
              onChange={e => {
                setFormState({
                  ...formState,
                  hasAAA: e.target.checked,
                  hasCorporateCode: false,
                  hasPromoCode: false,
                  hasAARP: false,
                  hasGov: false,
                  hasMilitary: false
                })
              }}
            />
            <span className="label-text">AAA</span>
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="checkbox"
              className="toggle toggle-sm"
              checked={formState.hasAARP}
              onChange={e => {
                setFormState({
                  ...formState,
                  hasAARP: e.target.checked,
                  hasCorporateCode: false,
                  hasPromoCode: false,
                  hasAAA: false,
                  hasGov: false,
                  hasMilitary: false
                })
              }}
            />
            <span className="label-text">AARP</span>
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="checkbox"
              className="toggle toggle-sm"
              checked={formState.hasGov}
              onChange={e => {
                setFormState({
                  ...formState,
                  hasGov: e.target.checked,
                  hasCorporateCode: false,
                  hasPromoCode: false,
                  hasAAA: false,
                  hasAARP: false,
                  hasMilitary: false
                })
              }}
            />
            <span className="label-text">US Government</span>
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="checkbox"
              className="toggle toggle-sm"
              checked={formState.hasMilitary}
              onChange={e => {
                setFormState({
                  ...formState,
                  hasMilitary: e.target.checked,
                  hasCorporateCode: false,
                  hasPromoCode: false,
                  hasAAA: false,
                  hasAARP: false,
                  hasGov: false
                })
              }}
            />
            <span className="label-text">US Military</span>
          </label>
        </div>
      </div>
    </details>
  )
}

export default SearchSpecialRatesControl
