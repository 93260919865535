import {
  faCaretLeft,
  faCaretRight,
  faImage
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, {useRef} from 'react'
import {useState} from 'react'
import {ImageAsset} from '../../types'

interface ImageCarouselProps {
  images: ImageAsset[]
}

const ImageCarousel = ({images}: ImageCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [currentImage, setCurrentImage] = useState(0)
  const imageCount = images?.length || 0
  const indicatorCount = Math.min(imageCount, 5)
  const indicators = Array.from({length: indicatorCount}, (_, index) => index)
  const isFirstImage = currentImage === 0
  const isLastImage = currentImage === imageCount - 1

  const isActiveIndicator = (indicatorIndex: number): boolean => {
    if (imageCount === indicatorCount) {
      return currentImage === indicatorIndex
    } else {
      const middleIndicatorIndex = Math.floor(indicatorCount / 2)
      if (indicatorIndex === 0) {
        return currentImage === 0
      } else if (indicatorIndex === 1) {
        return currentImage === 1
      } else if (indicatorIndex === middleIndicatorIndex) {
        return currentImage >= 2 && currentImage <= imageCount - 3
      } else if (indicatorIndex === indicatorCount - 2) {
        return currentImage === imageCount - 2
      } else if (indicatorIndex === indicatorCount - 1) {
        return currentImage === imageCount - 1
      } else {
        return false
      }
    }
  }

  const handlePreviousImage = () => {
    if (carouselRef.current) {
      const width = carouselRef.current.clientWidth
      carouselRef.current.scrollBy({
        left: -width,
        behavior: 'smooth'
      })
    }
  }

  const handleNextImage = () => {
    if (carouselRef.current) {
      const width = carouselRef.current.clientWidth
      carouselRef.current.scrollBy({
        left: width,
        behavior: 'smooth'
      })
    }
  }

  if (!images || images.length === 0) {
    return (
      <div className="h-full flex items-center justify-center bg-gray-200">
        <FontAwesomeIcon
          icon={faImage}
          className="text-2xl"
          title="Empty carousel image"
        />
      </div>
    )
  } else {
    return (
      <div className="carousel-container h-full relative bg-gray-200">
        <div
          className="carousel w-full h-full"
          ref={carouselRef}
          onScroll={() => {
            if (carouselRef.current) {
              const width = carouselRef.current.clientWidth
              const scrollLeft = carouselRef.current.scrollLeft
              const newPage = Math.round(scrollLeft / width)
              setCurrentImage(newPage)
            }
          }}
        >
          {images.map(({image, altText}, index) => {
            return (
              <div key={image} className="carousel-item w-full bg-gray-200">
                <img
                  src={image}
                  srcSet={`${image}?width=750 750w, ${image}?width=500 500w`}
                  sizes="(max-width: 1280px) 750w, 500w"
                  loading={index === 0 ? 'eager' : 'lazy'}
                  alt={altText}
                  className={clsx(
                    'w-full h-auto object-cover bg-gray-200',
                    'opacity-0 transition-opacity duration-500'
                  )}
                  onLoad={e => {
                    e.currentTarget.classList.remove('opacity-0')
                  }}
                />
              </div>
            )
          })}
        </div>

        {images && images?.length > 1 && (
          <>
            <div
              className={clsx('absolute left-5 right-5 top-1/2 flex', {
                'justify-between': !isFirstImage && !isLastImage,
                'justify-end': isFirstImage,
                'justify-start': isLastImage
              })}
            >
              {/* Previous button */}
              {!isFirstImage && (
                <a
                  onClick={handlePreviousImage}
                  onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                    if (e.key === 'Enter') {
                      handlePreviousImage()
                    }
                  }}
                  className="btn btn-sm btn-circle bg-black border-0 opacity-70 hover:opacity-90 hover:bg-black"
                  tabIndex={0}
                  aria-label="View the previous image."
                >
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    className="text-white"
                    title="Previous image"
                  />
                </a>
              )}

              {/* Next button */}
              {!isLastImage && (
                <a
                  onClick={handleNextImage}
                  onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                    if (e.key === 'Enter') {
                      handleNextImage()
                    }
                  }}
                  className="btn btn-sm btn-circle bg-black border-0 opacity-70 hover:opacity-90 hover:bg-black"
                  tabIndex={0}
                  aria-label="View the next image."
                >
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="text-white"
                    title="Next image"
                  />
                </a>
              )}
            </div>
            <div
              className={clsx(
                'absolute bottom-5 left-1/2 transform -translate-x-1/2 flex space-x-2',
                'bg-black border-0 opacity-70 p-2 rounded-xl'
              )}
            >
              {indicators.map(x => {
                const isActive = isActiveIndicator(x)
                return (
                  <div
                    key={x}
                    className={clsx('w-2 h-2 rounded-full', {
                      'bg-white': isActive,
                      'bg-gray-500': !isActive
                    })}
                  />
                )
              })}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default ImageCarousel
