import React, {useEffect, useRef, useState} from 'react'
import NumberStepper from '../common/NumberStepper'
import clsx from 'clsx'
import {SearchGuestParams} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'

export interface SearchRoomsGuestsControlProps {
  searchGuestParams: SearchGuestParams
  onChange: (searchGuestParams: SearchGuestParams) => void
  className?: string
}

function formatLabel(searchGuestParams: SearchGuestParams): string {
  const rooms = searchGuestParams.rooms || 1
  const adults = searchGuestParams.adults || 1

  const roomLabel = rooms === 1 ? '1 Room' : `${rooms} Rooms`
  const adultLabel = adults === 1 ? '1 Adult' : `${adults} Adults`

  return `${roomLabel}, ${adultLabel}`
}

const SearchRoomsGuestsControl = ({
  searchGuestParams,
  onChange,
  className = ''
}: SearchRoomsGuestsControlProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const label = formatLabel(searchGuestParams)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isOpen])

  return (
    <div
      ref={dropdownRef}
      className={clsx('dropdown', className)}
      aria-haspopup="true"
      aria-expanded={isOpen}
      role="menu"
    >
      {/* Dropdown button */}
      <button
        className="flex flex-row items-center justify-start cursor-pointer py-3.5 lg:py-1 w-full"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          setIsOpen(!isOpen)
        }}
        tabIndex={0}
        aria-label="Select number of rooms and guests."
      >
        <div className="w-full text-left font-medium text-sm">{label}</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className="ml-2 text-2xs"
          title="Toggle rooms and guests field"
        />
      </button>

      {/* Dropdown content */}
      {isOpen && (
        <div
          className="dropdown-content menu bg-white rounded-box z-[9999] w-80 p-4 mt-2 shadow flex flex-col gap-3"
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <div className="flex flex-row align-center justify-between pb-2 border-b">
            <span className="text-sm">Rooms</span>
            <NumberStepper
              min={1}
              max={6}
              step={1}
              value={searchGuestParams?.rooms}
              onChange={updatedRooms => {
                if (onChange) {
                  const {adults} = searchGuestParams
                  onChange({
                    ...searchGuestParams,
                    rooms: updatedRooms,
                    adults: updatedRooms > adults ? updatedRooms : adults
                  })
                }
              }}
            />
          </div>
          <div className="flex flex-row align-center justify-between pb-2 border-b">
            <span>Adults</span>
            <NumberStepper
              min={Math.max(1, searchGuestParams?.rooms || 1)}
              max={99}
              step={1}
              value={searchGuestParams?.adults}
              onChange={value => {
                onChange({
                  ...searchGuestParams,
                  adults: value
                })
              }}
            />
          </div>
          <div className="flex flex-row align-center justify-between pb-2 border-b">
            <span>Children</span>
            <NumberStepper
              min={0}
              max={99}
              step={1}
              value={searchGuestParams?.children}
              onChange={value => {
                onChange({
                  ...searchGuestParams,
                  children: value
                })
              }}
            />
          </div>
          <div className="flex flex-row align-center justify-between pb-2 border-b">
            <span>Travelling with Infants?</span>
            <input
              type="checkbox"
              className="toggle toggle-sm mr-4"
              checked={searchGuestParams?.hasInfants}
              onChange={e => {
                const hasInfants = (e.target as HTMLInputElement).checked
                onChange({
                  ...searchGuestParams,
                  hasInfants
                })
              }}
              role="checkbox"
              aria-checked={searchGuestParams?.hasInfants}
              tabIndex={0}
            />
          </div>
          <div className="flex flex-row align-center justify-between pb-2 border-b">
            <span>Travelling with Pets?</span>
            <input
              type="checkbox"
              className="toggle toggle-sm mr-4"
              checked={searchGuestParams?.hasPets}
              onChange={e => {
                const hasPets = e.target.checked
                onChange({
                  ...searchGuestParams,
                  hasPets
                })
              }}
              role="checkbox"
              aria-checked={searchGuestParams?.hasPets}
              tabIndex={0}
            />
          </div>
          <div className="text-xs font-light text-gray-400">
            A maximum of 6 rooms can be booked online. Please contact{' '}
            <a
              href="mailto:sales@placemakr.com"
              target="_blank"
              className="link"
              tabIndex={0}
              aria-label="Email the Placemakr sales team for group bookings."
            >
              sales
            </a>{' '}
            for group bookings.
          </div>
        </div>
      )}
    </div>
  )
}
export default SearchRoomsGuestsControl
