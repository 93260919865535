import {z} from 'zod'
import {
  BookingReservationSchema,
  CreateIbeBookingMetadataSchema,
  DatedOfferChargeSchema,
  GuestInfoSchema,
  HubspotLocationSchema,
  HubspotPropertySchema,
  HubspotPropertyResultsSchema,
  IbeConfigSchema,
  ImageAssetSchema,
  ItemEditSchema,
  ItemEditorModeSchema,
  OfferChargeSchema,
  OfferPriceSummarySchema,
  OfferRequestSchema,
  OfferResultSchema,
  OfferSchema,
  PaymentSessionRequestSchema,
  PropertyConfigSchema,
  SearchGuestParamsSchema,
  SearchParamsSchema,
  SearchSpecialRatesParamsSchema,
  ServiceOfferRequestSchema,
  ServiceOfferSchema,
  ShoppingCartSchema,
  ShoppingCartItemSchema,
  ShoppingCartPriceSummarySchema,
  SpecialRatePlansSchema,
  ToastNotificationSchema,
  UnitGroupOfferSchema,
  UnitGroupSchema,
  UserSchema
} from './schema'

// -----------------------------------------------------------------------------
//  Enums
// -----------------------------------------------------------------------------

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum AppView {
  HOME = 'Home',
  CHOOSE_PROPERTY = 'ChooseProperty',
  CHOOSE_UNIT = 'ChooseUnit',
  CHECKOUT = 'Checkout',
  CONFIRMATION = 'Confirmation'
}

export enum PaymentSessionStatus {
  PaymentSessionCreated = 'PaymentSessionCreated',
  PaymentRejected = 'PaymentRejected',
  PaymentProcessing = 'PaymentProcessing',
  PaymentSucceeded = 'PaymentSucceeded',
  PaymentFailed = 'PaymentFailed'
}

// -----------------------------------------------------------------------------
//  Types
// -----------------------------------------------------------------------------

export type BookingReservation = z.infer<typeof BookingReservationSchema>

export type CreateIbeBookingMetadata = z.infer<
  typeof CreateIbeBookingMetadataSchema
>

export type DatedOfferCharge = z.infer<typeof DatedOfferChargeSchema>

export type GuestInfo = z.infer<typeof GuestInfoSchema>

export type HubspotLocation = z.infer<typeof HubspotLocationSchema>

export type HubspotProperty = z.infer<typeof HubspotPropertySchema>

export type HubspotPropertyResults = z.infer<
  typeof HubspotPropertyResultsSchema
>

export type IbeConfig = z.infer<typeof IbeConfigSchema>

export type ImageAsset = z.infer<typeof ImageAssetSchema>

export type ItemEditorMode = z.infer<typeof ItemEditorModeSchema>

export type ItemEdit = z.infer<typeof ItemEditSchema>

export type Offer = z.infer<typeof OfferSchema>

export type OfferCharge = z.infer<typeof OfferChargeSchema>

export type OfferPriceSummary = z.infer<typeof OfferPriceSummarySchema>

export type OfferRequest = z.infer<typeof OfferRequestSchema>

export type OfferResult = z.infer<typeof OfferResultSchema>

export type PaymentSessionRequest = z.infer<typeof PaymentSessionRequestSchema>

export type PropertyConfig = z.infer<typeof PropertyConfigSchema>

export type SearchGuestParams = z.infer<typeof SearchGuestParamsSchema>

export type SearchParams = z.infer<typeof SearchParamsSchema>

export type SearchSpecialRatesParams = z.infer<
  typeof SearchSpecialRatesParamsSchema
>

export type ServiceOffer = z.infer<typeof ServiceOfferSchema>

export type ServiceOfferRequest = z.infer<typeof ServiceOfferRequestSchema>

export type ShoppingCart = z.infer<typeof ShoppingCartSchema>

export type ShoppingCartItem = z.infer<typeof ShoppingCartItemSchema>

export type ShoppingCartPriceSummary = z.infer<
  typeof ShoppingCartPriceSummarySchema
>

export type SpecialRatePlans = z.infer<typeof SpecialRatePlansSchema>

export type ToastNotification = z.infer<typeof ToastNotificationSchema>

export type UnitGroup = z.infer<typeof UnitGroupSchema>

export type UnitGroupOffer = z.infer<typeof UnitGroupOfferSchema>

export type User = z.infer<typeof UserSchema>
