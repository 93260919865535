import React, {useEffect, useMemo, useState} from 'react'
import PlacemakrLogo from '../../assets/placemakr-logo.png'
import ShoppingCartButton from '../cart/ShoppingCartButton'
import clsx from 'clsx'
import NavBarMobileMenu from './NavBarMobileMenu'
import NavBarDesktopMenu from './NavBarDesktopMenu'
import {HubspotLocation, HubspotProperty} from '../../types'
import {HubspotPropertyResultsSchema} from '../../schema'

declare global {
  interface Window {
    hubspotProperties?: any
  }
}

function getLocations(): HubspotLocation[] {
  try {
    if (window.hubspotProperties) {
      const hubspotPropertyResults = HubspotPropertyResultsSchema.parse(
        window.hubspotProperties
      )
      const hubspotProperties = hubspotPropertyResults.results

      const locationMap = hubspotProperties.reduce(
        (
          acc: Record<string, HubspotLocation>,
          {location_group, city_page_url_slug}: HubspotProperty
        ) => {
          if (location_group && city_page_url_slug && !acc[location_group]) {
            acc[location_group] = {
              name: location_group,
              url: city_page_url_slug
            }
          }
          return acc
        },
        {} as Record<string, HubspotLocation>
      )

      return Object.values(locationMap).sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    } else {
      return []
    }
  } catch (error) {
    console.error(error)
    return []
  }
}

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const locations = useMemo(() => getLocations(), [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    // add scroll listener
    window.addEventListener('scroll', handleScroll)

    // cleanup scroll listener
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <nav
      aria-label="Main navigation bar."
      className={clsx(
        'navbar bg-white py-4 px-4 lg:px-10',
        'h-[68px] lg:h-[84px]',
        {
          'shadow-xl sticky top-0 z-50 transition-all duration-300': isScrolled
        }
      )}
    >
      <div className="flex-1">
        <a
          className="cursor-pointer text-xl"
          href="https://www.placemakr.com/"
          aria-label="Navigate to Placemakr home page."
        >
          <img
            src={PlacemakrLogo}
            className="w-[130px] h-auto"
            alt="Placemakr company logo."
          />
        </a>
      </div>
      {/* desktop menu */}
      <div className="flex-0 hidden lg:inline">
        <NavBarDesktopMenu isCondensed={isScrolled} locations={locations} />
      </div>
      <div className="ml-16 mr-8">
        <ShoppingCartButton />
      </div>
      {/* mobile menu */}
      <div className="flex-0 lg:hidden">
        <NavBarMobileMenu locations={locations} />
      </div>
    </nav>
  )
}

export default NavBar
