import {
  Offer,
  OfferLineItem,
  OfferResult,
  ServiceOffer,
  UnitGroupOffer
} from '../types'
import {formatDate} from './utils'

/**
 * Determines if the offer is a member rate
 * @param offer
 * @returns True, if the offer is a member rate
 */
export function isMemberRate(offer: Offer): boolean {
  return offer.ratePlan.name.toLowerCase().includes('member')
}

/**
 * Calculates the total room charges for an offer.
 * @param offer
 * @returns The total room charges for the duration of the stay.
 */
export function calcTotalRoomCharges(offer: Offer): number {
  return (
    offer.timeSlices?.reduce(
      (acc, timeSlice) => acc + timeSlice.baseAmount.grossAmount,
      0
    ) || 0
  )
}

/**
 * Calculates the total room nights for an offer.
 * @param offer
 * @returns The total number of nights.
 */
export function calcTotalRoomNights(offer: Offer): number {
  return offer.timeSlices?.length || 0
}

/**
 * Calculates the total service charges for additional services.
 * @param services
 * @returns The total service charges.
 */
export function calcTotalServiceCharges(services: ServiceOffer[]): number {
  return (
    services.reduce((acc, service) => {
      return acc + service.totalAmount.grossAmount
    }, 0) || 0
  )
}

/**
 * Calculates the total taxes and fees for an offer.
 * @param offer
 * @param taxServiceCodes
 * @returns The total taxes and fees.
 */
export function calcTotalTaxesAndFees(
  offer: Offer,
  taxServiceCodes: string[]
): number {
  const totalFees =
    offer.fees?.reduce((acc, fee) => {
      return acc + fee.totalAmount.grossAmount
    }, 0) || 0

  const totalTaxServices =
    offer.services?.reduce((acc, service) => {
      if (taxServiceCodes.includes(service.service.code)) {
        acc += service.totalAmount?.grossAmount
      }
      return acc
    }, 0) || 0

  const totalCityTaxes =
    offer.cityTaxes?.reduce((acc, cityTax) => {
      return acc + cityTax.totalGrossAmount.amount
    }, 0) || 0

  return totalFees + totalTaxServices + totalCityTaxes
}

/**
 * Calculates the total prepayment amount for an offer.
 * @param offer
 * @param additionalServices
 * @returns The total prepayment amount.
 */
export function calcTotalPrepaymentAmount(
  offer: Offer,
  additionalServices: ServiceOffer[]
): number {
  const servicesPrepayment =
    additionalServices.reduce((acc, service) => {
      if (service.prePaymentAmount.amount > 0) {
        acc += service.prePaymentAmount.amount
      }
      return acc
    }, 0) || 0
  const roomPrepayment = offer.prePaymentAmount.amount
  return servicesPrepayment + roomPrepayment
}

/**
 * Calculates the total charges for an offer.
 * @param offer
 * @param additionalServices
 * @param taxServiceCodes
 * @returns The total charges.
 */
export function calcTotalCharges(
  offer: Offer,
  additionalServices: ServiceOffer[],
  taxServiceCodes: string[]
): number {
  const totalRoomCharges = calcTotalRoomCharges(offer)
  const totalServiceCharges = calcTotalServiceCharges(additionalServices)
  const totalTaxesAndFees = calcTotalTaxesAndFees(offer, taxServiceCodes)
  return totalRoomCharges + totalServiceCharges + totalTaxesAndFees
}

/**
 * Gets the room and service charge line items for the offer.
 * @param offer
 * @returns The charge line items.
 */
export function getOfferLineItems(offer: Offer): OfferLineItem[] {
  const lineItems: OfferLineItem[] = []
  for (const timeSlice of offer.timeSlices) {
    const date = timeSlice.from.split('T')[0]
    lineItems.push({
      type: 'room',
      date,
      description: `${formatDate(date)} - Room`,
      amount: timeSlice.totalGrossAmount.amount,
      currency: timeSlice.totalGrossAmount.currency
    })
  }

  if (offer.services?.length) {
    for (const service of offer.services) {
      for (const date of service.dates) {
        lineItems.push({
          type: 'service',
          date: date.serviceDate,
          description: `${formatDate(date.serviceDate)} - ${
            service.service.name
          }`,
          amount: date.amount.grossAmount,
          currency: date.amount.currency
        })
      }
    }
  }

  const lineItemComparator = (item1: OfferLineItem, item2: OfferLineItem) => {
    if (item1.type !== item2.type) {
      const typeOrder = ['room', 'service']
      return typeOrder.indexOf(item1.type) - typeOrder.indexOf(item2.type)
    } else {
      return item1.date.localeCompare(item2.date)
    }
  }

  return lineItems.sort(lineItemComparator)
}

/**
 * Sorts unit group offers by lowest gross amount
 * @param unitGroupOffers
 * @returns
 */
function sortUnitGroupOffers(
  unitGroupOffers: UnitGroupOffer[]
): UnitGroupOffer[] {
  // sort by lowest gross amount
  return unitGroupOffers.sort((a, b) => {
    if (a.lowestGrossAmount.amount < b.lowestGrossAmount.amount) {
      return -1
    } else if (a.lowestGrossAmount.amount > b.lowestGrossAmount.amount) {
      return 1
    } else {
      return 0
    }
  })
}

/**
 * Groups offers by unit group for display
 * @param offers
 * @returns
 */
function groupOffersByUnitGroup({
  offers,
  property
}: OfferResult): UnitGroupOffer[] {
  // group offers by unit group, calculate lowest gross amount and nightly amount
  const unitGroupOffersMap = offers.reduce(
    (acc: Record<string, UnitGroupOffer>, offer: Offer) => {
      const unitGroupId = offer.unitGroup.id
      const nightCount = offer.timeSlices.length
      if (acc[unitGroupId]) {
        const unitGroupAcc = acc[unitGroupId]
        unitGroupAcc.offers.push(offer)
        if (
          unitGroupAcc.lowestGrossAmount.amount > offer.totalGrossAmount.amount
        ) {
          unitGroupAcc.lowestGrossAmount = offer.totalGrossAmount
          unitGroupAcc.lowestNightlyAmount.amount = Math.round(
            offer.totalGrossAmount.amount / nightCount
          )
          unitGroupAcc.lowestNightlyAmount.currency =
            offer.totalGrossAmount.currency
        }
      } else {
        acc[unitGroupId] = {
          propertyId: property.id,
          unitGroup: offer.unitGroup,
          availableUnits: offer.availableUnits,
          lowestGrossAmount: offer.totalGrossAmount,
          lowestNightlyAmount: {
            amount: Math.round(offer.totalGrossAmount.amount / nightCount),
            currency: offer.totalGrossAmount.currency
          },
          nights: nightCount,
          offers: [offer]
        }
      }
      return acc
    },
    {}
  )

  return Object.values(unitGroupOffersMap)
}

/**
 * Gets unit group offers for a property selection.
 * @param propertySelection
 * @param offerResults
 * @returns
 */
export function getUnitGroupOffers(
  propertySelection: string,
  offerResults: OfferResult[]
): UnitGroupOffer[] {
  if (propertySelection === 'ALL') {
    const allUnitGroupOffers = offerResults.flatMap(offerResult =>
      groupOffersByUnitGroup(offerResult)
    )
    return sortUnitGroupOffers(allUnitGroupOffers)
  } else {
    const offerResult = offerResults.find(
      offerResult => offerResult.property.id === propertySelection
    )
    const unitGroupOffers = offerResult
      ? groupOffersByUnitGroup(offerResult)
      : []
    return sortUnitGroupOffers(unitGroupOffers)
  }
}

/**
 * Gets the best offer from a list of offers
 * @param offers
 * @returns
 */
export function getBestOffer(offers: Offer[]): Offer {
  return offers.reduce((cheapest, offer) =>
    offer.totalGrossAmount.amount < cheapest.totalGrossAmount.amount
      ? offer
      : cheapest
  )
}
