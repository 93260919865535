import React, {useEffect, useState} from 'react'
import {OfferResult, PropertyConfig, SearchParams} from '../../types'
import SearchPropertyResultItem from './SearchPropertyResultItem'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMap} from '@fortawesome/free-regular-svg-icons'
import SearchGoogleMap from './SearchGoogleMap'
import SearchGoogleMapModal from './SearchGoogleMapModal'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'
import {useAppSelector} from '../../store/hooks'
import {selectConfig, selectPropertiesById} from '../../store/configSlice'
import EmptyResults from '../../assets/ibe-empty-results.png'

const logger = getLogger('SearchPropertyResults')

export interface SearchPropertyResultsProps {
  isLoading: boolean
  searchParams: SearchParams
  offerResults?: OfferResult[]
  onSelectProperty: (propertyId: string) => void
}

export interface SearchMapState {
  selectedProperty?: string
  properties?: PropertyConfig[]
}

const SearchPropertyResults = ({
  isLoading,
  searchParams,
  offerResults = [],
  onSelectProperty
}: SearchPropertyResultsProps) => {
  const config = useAppSelector(selectConfig)
  const windowDimensions = useWindowDimensions()
  const [isMapVisible, setIsMapVisible] = useState(false)
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)
  const [searchMapState, setSearchMapState] = useState<SearchMapState>({
    properties: []
  })
  const propertyLookup = useAppSelector(selectPropertiesById)
  const location = searchParams.location

  logger.debug({
    message: 'Rendering property search results',
    data: {offerResults}
  })

  useEffect(() => {
    if (offerResults.length > 0) {
      const propertyConfigs = offerResults.map(result => {
        return propertyLookup[result.property.id]
      })
      setSearchMapState({
        ...searchMapState,
        properties: propertyConfigs
      })
    }
  }, [config, offerResults])

  if (isLoading) {
    return (
      <div className="flex flex-col gap-8 my-4">
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
      </div>
    )
  } else if (!isLoading && offerResults.length > 0) {
    return (
      <>
        <div className="flex flex-row justify-center items-center gap-4 mt-4">
          <div className="flex-1 text-sm">
            Showing <span className="font-bold">{offerResults.length}</span>{' '}
            property results for <span className="font-bold">{location}</span>:
          </div>
          {windowDimensions.isXL && (
            <div className="flex-none">
              <a
                className="link link-primary text-sm no-underline"
                onClick={() => {
                  setIsMapVisible(!isMapVisible)
                }}
                aria-label="Show or hide map."
              >
                <FontAwesomeIcon
                  icon={faMap}
                  className="mr-1 text-accent"
                  title="Toggle map view"
                />
                <span>{isMapVisible ? 'hide' : 'show'} map</span>
              </a>
            </div>
          )}
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex-1 flex flex-col gap-8 mt-4">
            <div
              className={clsx('flex flex-col gap-8', {
                'pb-1 pr-3': isMapVisible && windowDimensions.isXL
              })}
            >
              {offerResults.map((result, index) => {
                const propertyConfig = propertyLookup[result.property.id]
                return (
                  <SearchPropertyResultItem
                    className="last:mb-8"
                    key={index}
                    result={result}
                    propertyConfig={propertyConfig}
                    onShowOnMap={(propertyId: string) => {
                      setSearchMapState({
                        ...searchMapState,
                        selectedProperty: propertyId
                      })

                      if (windowDimensions.isXL) {
                        setIsMapVisible(true)
                        setSearchMapState({
                          ...searchMapState,
                          selectedProperty: propertyId
                        })
                      } else {
                        setIsMapModalOpen(true)
                      }
                    }}
                    onSelectProperty={onSelectProperty}
                  />
                )
              })}
            </div>
          </div>
          {windowDimensions.isXL &&
            isMapVisible &&
            searchMapState.properties && (
              <SearchGoogleMap
                className="mt-4 mb-8 bg-white pb-2"
                selectedProperty={searchMapState?.selectedProperty}
                properties={searchMapState.properties}
              />
            )}
        </div>

        <SearchGoogleMapModal
          selectedProperty={searchMapState?.selectedProperty}
          properties={searchMapState?.properties}
          isOpen={isMapModalOpen}
          onClose={() => setIsMapModalOpen(false)}
        />
      </>
    )
  } else {
    return (
      <div className="flex flex-col items-center gap-2 mb-12">
        <img
          src={EmptyResults}
          className="max-h-72"
          alt="No results found image."
        />
        <h2 className="text-lg font-bold mt-8">No results found.</h2>
        <p className="text-center">
          Try adjusting your search filters or broadening your travel dates to
          see more options.
        </p>
      </div>
    )
  }
}

export default SearchPropertyResults
