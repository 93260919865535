import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect} from 'react'
import * as types from '../../types'
import {useAppDispatch} from '../../store/hooks'
import {removeToastNotification} from '../../store/toastNotificationSlice'
import CartItemAdded from '../../assets/cart-item-added.png'
import CartItemRemoved from '../../assets/cart-item-removed.png'

export interface ToastNotificationProps {
  notification: types.ToastNotification
}

const autoCloseDuration = 3000

function getIcon(type: any): any {
  switch (type) {
    case 'add':
      return CartItemAdded
    case 'remove':
      return CartItemRemoved
    default:
      return CartItemAdded
  }
}

const ToastNotification = ({notification}: ToastNotificationProps) => {
  const dispatch = useAppDispatch()

  // autoclose toast notification after 10 seconds
  useEffect(() => {
    const timerRef = setTimeout(() => {
      dispatch(removeToastNotification(notification))
    }, autoCloseDuration)

    return () => {
      clearTimeout(timerRef)
    }
  }, [notification])

  return (
    <div className="flex flex-row gap-4 items-start rounded border bg-white shadow-xl p-4">
      <img src={getIcon(notification.type)} className="w-12" />
      <p className="text-sm text-pretty w-52">{notification.message}</p>
      <button
        className="btn btn-ghost btn-circle btn-sm"
        onClick={() => {
          dispatch(removeToastNotification(notification))
        }}
      >
        <FontAwesomeIcon icon={faXmark} className="text-sm" />
      </button>
    </div>
  )
}

export default ToastNotification
