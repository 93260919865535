import React from 'react'
import {useAppDispatch} from '../../store/hooks'
import {setAppView} from '../../store/appViewSlice'
import {AppView} from '../../types'
import {getLogger} from '../../services/logging'
import IbeConfirmation from '../../assets/ibe-booking-complete.png'

const logger = getLogger('ConfirmationView')

const ConfirmationView = () => {
  const dispatch = useAppDispatch()
  logger.info({message: 'Booking confirmed'})
  return (
    <div className="flex flex-col gap-2 items-center text-center">
      <img
        src={IbeConfirmation}
        alt="Booking confirmed"
        className="mx-auto my-12 max-h-72"
      />
      <h1 className="text-2xl font-bold">Your booking is complete!</h1>
      <p>A confirmation email was sent to your email address.</p>
      <a
        className="link link-primary"
        onClick={() => {
          logger.info({
            message: 'User returning to home page after booking confirmation'
          })
          dispatch(setAppView(AppView.HOME))
        }}
      >
        Go Back
      </a>
    </div>
  )
}

export default ConfirmationView
