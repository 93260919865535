import React, {forwardRef} from 'react'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'

const logger = getLogger('SearchDatePicker')

interface SearchDateInputProps {
  value: string
  onClick: () => void
  className?: string
}

const SearchDateInput = forwardRef<HTMLButtonElement, SearchDateInputProps>(
  ({value, onClick, className}: SearchDateInputProps, ref: any) => (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
      ref={ref}
      tabIndex={0}
    >
      {value}
    </div>
  )
)

export interface SearchDatePickerProps {
  startDate: Date | null
  endDate: Date | null
  onChange: (startDate: Date | null, endDate: Date | null) => void
}

const SearchDatePicker = ({
  startDate,
  endDate,
  onChange
}: SearchDatePickerProps) => {
  return (
    <DatePicker
      className={clsx(
        'font-medium text-sm',
        'bg-transparent w-full lg:w-52 py-3.5',
        'lg:py-1'
      )}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="MMM d, yyyy"
      customInput={<SearchDateInput value="" onClick={() => {}} />}
      minDate={new Date()}
      onChange={([start, end]: [Date | null, Date | null]) => {
        logger.info({message: 'Date range updated', data: {start, end}})
        onChange(start, end)
      }}
      aria-label="Select a date range."
      aria-required="true"
    />
  )
}

export default SearchDatePicker
