import React, {useEffect} from 'react'
import {formatISO, parseISO} from 'date-fns'
import {
  SearchGuestParams,
  SearchParams,
  SearchSpecialRatesParams
} from '../../types'
import SearchRoomsGuestsControl from './SearchRoomsGuestsControl'
import clsx from 'clsx'
import SearchSpecialRatesControl from './SearchSpecialRatesControl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faChevronDown,
  faLocationDot,
  faSearch,
  faTicket,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga4'
import {getLogger} from '../../services/logging'
import {useAppSelector} from '../../store/hooks'
import {selectConfig} from '../../store/configSlice'
import SearchDatePicker from './SearchDatePicker'

const logger = getLogger('SearchBar')

export interface SearchBarProps {
  searchParams: SearchParams
  onSubmit: (searchParams: SearchParams) => void
  className?: string
}

const SearchBar = ({
  searchParams,
  onSubmit,
  className = ''
}: SearchBarProps) => {
  const config = useAppSelector(selectConfig)
  const [searchForm, setSearchForm] = React.useState<SearchParams>(searchParams)
  const locations = config?.locations || []
  const regions = config?.regions || []

  const startDate = searchForm?.dateRange?.start
    ? parseISO(searchForm.dateRange.start)
    : null
  const endDate = searchForm?.dateRange?.end
    ? parseISO(searchForm.dateRange.end)
    : null

  useEffect(() => {
    setSearchForm(searchParams)
  }, [searchParams])

  return (
    <form
      onSubmit={e => {
        e.preventDefault()

        if (searchForm.location) {
          logger.info({message: 'Search form submitted', data: {searchForm}})
          ReactGA.event({
            category: 'IBE',
            action: 'Search_Submit',
            value: 1
          })

          onSubmit(searchForm)
        }
      }}
      className={clsx(
        'bg-white border shadow-md',
        'flex flex-col gap-4 py-6 rounded-2xl',
        'lg:flex-row lg:items-center lg:space-between lg:p-4 lg:rounded-full',
        className
      )}
      aria-label="Room search form."
    >
      <div
        className={clsx(
          'flex-1 px-6',
          'flex flex-col space-between',
          'lg:flex-row lg:items-start'
        )}
      >
        {/* Location Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              className="text-accent"
              title="Location field"
            />
            <span className="text-light-primary">Location</span>
          </div>
          <div className="flex flex-row items-center">
            <select
              style={{
                appearance: 'none'
              }}
              className={clsx(
                'text-sm font-medium cursor-pointer bg-white',
                'w-full py-3.5',
                'lg:py-1 lg:w-52',
                'border-0 focus:border-0 focus:ring-0 outline-none'
              )}
              value={searchForm.location}
              onChange={e => {
                logger.info({
                  message: 'Location selected',
                  data: {location: e.target.value}
                })
                setSearchForm({
                  ...searchForm,
                  location: e.target.value,
                  propertyId: undefined
                })
              }}
              tabIndex={0}
              aria-label="Select a location."
              aria-required="true"
            >
              <option key="DEFAULT" value="" disabled>
                Select a Location
              </option>
              {locations.map(location => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}

              {regions && regions.length > 0 && (
                <optgroup label="Regions">
                  {regions.map(region => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))}
                </optgroup>
              )}
            </select>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="flex-0 text-2xs"
              title="Location field toggle"
            />
          </div>
        </div>

        <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>

        {/* Date Range Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon
              icon={faCalendar}
              className="text-accent"
              title="Date range field"
            />
            <span className="text-light-primary">Dates</span>
          </div>
          <SearchDatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              setSearchForm({
                ...searchForm,
                dateRange: {
                  type: 'Specific',
                  start: start ? formatISO(start) : undefined,
                  end: end ? formatISO(end) : undefined
                }
              })
            }}
          />
        </div>

        <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>

        {/* Rooms and Guests Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon
              icon={faUser}
              className="text-accent"
              title="Room and guests field"
            />
            <span className="text-light-primary">Rooms & Guests</span>
          </div>
          <SearchRoomsGuestsControl
            searchGuestParams={searchForm.guests}
            onChange={(searchGuestsParams: SearchGuestParams) => {
              logger.info({
                message: 'Rooms and guests updated',
                data: {searchGuestsParams}
              })
              setSearchForm({
                ...searchForm,
                guests: searchGuestsParams
              })
            }}
            aria-label="Select the number of guests and rooms to book."
            aria-required="true"
          />
        </div>

        <div className="divider lg:divider-horizontal mt-0.5 mb-2"></div>

        {/* Special Rates Control */}
        <div className={clsx('form-control')}>
          <div
            className={clsx(
              'text-xs font-medium',
              'flex flex-row items-center gap-2'
            )}
          >
            <FontAwesomeIcon
              icon={faTicket}
              className="text-accent"
              title="Special rate field"
            />
            <span className="text-light-primary">Special Rate</span>
          </div>
          <SearchSpecialRatesControl
            specialRates={searchForm.specialRates}
            onChange={(specialRates: SearchSpecialRatesParams) => {
              logger.info({
                message: 'Special rates updated',
                data: {specialRates}
              })
              setSearchForm({
                ...searchForm,
                specialRates
              })
            }}
            aria-label="Enter promo code, corporte code, or select special rate."
            aria-required="false"
          />
        </div>
      </div>

      {/* Search Button */}
      <div className={clsx('flex-0', 'lg:mr-2')}>
        <button
          type="submit"
          className={clsx(
            'btn btn-secondary btn-wide rounded-full',
            'flex items-center justify-center',
            'mx-auto',
            'lg:btn-circle lg:mt-0 lg:mx-0'
          )}
          disabled={!searchForm.location}
          aria-label="Search for available rooms."
        >
          <FontAwesomeIcon
            icon={faSearch}
            className="text-lg"
            title="Submit search"
          />
          <span className="block lg:hidden">Search</span>
        </button>
      </div>
    </form>
  )
}

export default SearchBar
