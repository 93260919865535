import React from 'react'
import {IbeConfig, OfferResult} from '../../types'
import SearchUnitResultItem from './SearchUnitResultItem'
import {findPropertyConfig} from '../../services/utils'
import {getUnitGroupOffers} from '../../services/offerUtils'
import {getLogger} from '../../services/logging'
import {useAppSelector} from '../../store/hooks'
import {selectConfig} from '../../store/configSlice'
import EmptyResults from '../../assets/ibe-empty-results.png'

const logger = getLogger('SearchUnitResults')

/**
 *
 * @param selectedPropertyId
 * @param config
 * @returns
 */
function formatPropertySelectionLabel(
  selectedPropertyId: string,
  config: IbeConfig | null
): string {
  if (selectedPropertyId === 'ALL') {
    return 'All Properties'
  } else if (!config) {
    return 'Property'
  } else {
    const propertyConfig = findPropertyConfig(
      config.properties,
      selectedPropertyId
    )
    return propertyConfig?.name || 'Selected Property'
  }
}

export interface SearchUnitResultsProps {
  offerResults?: OfferResult[]
  selectedPropertyId: string
  isLoading: boolean
}

const SearchUnitResults = ({
  offerResults = [],
  selectedPropertyId,
  isLoading
}: SearchUnitResultsProps) => {
  const config = useAppSelector(selectConfig)
  const propertySelectionLabel = config
    ? formatPropertySelectionLabel(selectedPropertyId, config)
    : 'Property'
  const unitGroupOffers = getUnitGroupOffers(selectedPropertyId, offerResults)

  logger.debug({
    message: 'Rendering SearchUnitResults...',
    data: {unitGroupOffers}
  })

  if (isLoading) {
    return (
      <div className="flex-1 flex flex-col gap-8 mt-8">
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
      </div>
    )
  } else if (!isLoading && unitGroupOffers?.length) {
    return (
      <>
        <div className="text-sm mt-4">
          Showing <span className="font-bold">{unitGroupOffers.length}</span>{' '}
          room results for {propertySelectionLabel}:
        </div>
        <div className="flex flex-col gap-8 mt-4 mb-8">
          {unitGroupOffers.map((unitGroupOffer, index) => {
            return (
              <SearchUnitResultItem
                key={index}
                unitGroupOffer={unitGroupOffer}
                propertyId={unitGroupOffer.propertyId}
              />
            )
          })}
        </div>
      </>
    )
  } else {
    return (
      <div className="flex flex-col items-center gap-2 mb-12">
        <img src={EmptyResults} className="max-h-72" alt="No results found" />
        <h2 className="text-lg font-bold mt-8">No results found.</h2>
        <p className="text-center">
          Try adjusting your search filters or broadening your travel dates to
          see more options.
        </p>
      </div>
    )
  }
}

export default SearchUnitResults
