import {format, isDate, parseISO} from 'date-fns'
import {PropertyConfig} from '../types'
import currency from 'currency.js'
import {getLogger} from './logging'

const logger = getLogger('utils')

/**
 * Finds the property config for the property ID
 * @param propertyConfigs
 * @param propertyId
 * @returns
 */
export function findPropertyConfig(
  propertyConfigs: PropertyConfig[],
  propertyId: string
): PropertyConfig | undefined {
  return propertyConfigs.find(
    property => property.propertyApaleoId === propertyId
  )
}

/**
 * Generates a UUID
 * @returns
 */
export function generateUUID(): string {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  return template.replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0
    var v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * Formats a date
 * @param date
 * @returns
 */
export function formatDate(d: Date | string): string {
  const date = isDate(d) ? (d as Date) : parseISO(d as string)
  return format(date, 'MMM dd, yyyy')
}

/**
 * Formats a currency amount
 * @param value
 * @returns
 */
export function formatCurrency(value: any): string {
  return currency(value, {separator: ',', symbol: '$'}).format()
}

/**
 * Safely parses an ISO date string
 * @param dateString
 * @returns
 */
export function safeParseISO(dateString: any): Date | null {
  try {
    return dateString ? parseISO(dateString) : null
  } catch (error) {
    logger.warn({
      message: 'Failed to parse date',
      data: {dateString, error}
    })
    return null
  }
}
