import React, {ReactNode} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
  faCircleCheck
} from '@fortawesome/free-solid-svg-icons'

interface AlertProps {
  type?: 'info' | 'success' | 'warning' | 'error'
  className?: string
  children?: ReactNode
}

const alertStyles = {
  default: {alertClass: '', alertIcon: faCircleInfo},
  error: {alertClass: 'alert-error', alertIcon: faCircleExclamation},
  info: {alertClass: 'alert-info', alertIcon: faCircleInfo},
  success: {alertClass: 'alert-success', alertIcon: faCircleCheck},
  warning: {alertClass: 'alert-warning', alertIcon: faTriangleExclamation}
}

const Alert = ({type, className, children}: AlertProps) => {
  const alertType = type || 'default'
  const {alertClass, alertIcon} = alertStyles[alertType]
  return (
    <div data-ref="alert" className={className}>
      <div
        className={`alert ${alertClass} flex flex-row items-center justify-start`}
      >
        <div>
          <FontAwesomeIcon
            data-ref="alert.icon"
            icon={alertIcon}
            className="text-2xl mr-4"
            title="Alert"
          />
        </div>
        <div data-ref="alert.content">{children}</div>
      </div>
    </div>
  )
}

export default Alert
