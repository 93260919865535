import React, {useMemo} from 'react'
import {Offer, ServiceOffer} from '../../types'
import clsx from 'clsx'
import {formatCurrency, formatDate} from '../../services/utils'
import {calcOfferPriceSummary, isPrepaidOffer} from '../../services/offerUtils'
import {getLogger} from '../../services/logging'

const logger = getLogger('ShoppingCartPricingSummary')

export interface ShoppingCartPricingSummaryProps {
  offer: Offer
  additionalServices: ServiceOffer[]
  className?: string
}

const ShoppingCartPricingSummary = ({
  offer,
  additionalServices,
  className
}: ShoppingCartPricingSummaryProps) => {
  const [showItemizedRoomCharges, setShowItemizedRoomCharges] = React.useState(
    false
  )
  const [
    showItemizedAdditionalServices,
    setShowItemizedAdditionalService
  ] = React.useState(false)
  const [
    showItemizedTaxesAndFees,
    setShowItemizedTaxesAndFees
  ] = React.useState(false)

  const pricingSummary = useMemo(() => {
    return calcOfferPriceSummary(offer, additionalServices)
  }, [offer, additionalServices])
  const {
    itemizedRoomCharges,
    totalRoomCharges,
    itemizedAdditionalServices,
    totalAdditionalServices,
    itemizedTaxesAndFees,
    totalTaxesAndFees,
    total
  } = pricingSummary

  const isPrepaid: boolean = useMemo(() => {
    return isPrepaidOffer(offer)
  }, [offer])

  logger.debug({
    message: 'Rendering shopping cart pricing summary...',
    data: {
      pricingSummary,
      offer,
      additionalServices
    }
  })

  return (
    <div className={clsx('w-full flex flex-col items-end gap-0.5', className)}>
      <table className="price-details text-light-primary">
        <tbody>
          {/* room charges */}
          {totalRoomCharges > 0 && (
            <>
              <tr className="font-medium">
                <td>
                  <span className="mr-2">Total Room Charges</span>
                  <a
                    className="text-xs link text-accent no-underline hover:underline"
                    onClick={() =>
                      setShowItemizedRoomCharges(!showItemizedRoomCharges)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                      if (e.key === 'Enter') {
                        setShowItemizedRoomCharges(!showItemizedRoomCharges)
                      }
                    }}
                    tabIndex={0}
                    aria-label="Toggle itemized room charges."
                  >
                    {showItemizedRoomCharges ? 'hide' : 'show'}
                  </a>
                </td>
                <td className="price">{formatCurrency(totalRoomCharges)}</td>
              </tr>
              {showItemizedRoomCharges &&
                itemizedRoomCharges.map(({name, date, amount}) => {
                  const key = `${name}-${date}`
                  return (
                    <tr key={key} className="text-xs">
                      <td>
                        {name} - {formatDate(date)}
                      </td>
                      <td className="price">{formatCurrency(amount)}</td>
                    </tr>
                  )
                })}
            </>
          )}

          {/* additional services */}
          {totalAdditionalServices > 0 && (
            <>
              <tr className="font-medium">
                <td>
                  <span className="mr-2">Total Service Charges</span>
                  <a
                    className="text-xs link text-accent no-underline hover:underline"
                    onClick={() =>
                      setShowItemizedAdditionalService(
                        !showItemizedAdditionalServices
                      )
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                      if (e.key === 'Enter') {
                        setShowItemizedAdditionalService(
                          !showItemizedAdditionalServices
                        )
                      }
                    }}
                    tabIndex={0}
                    aria-label="Toggle itemized service charges."
                  >
                    {showItemizedAdditionalServices ? 'hide' : 'show'}
                  </a>
                </td>
                <td className="price">
                  {formatCurrency(totalAdditionalServices)}
                </td>
              </tr>

              {showItemizedAdditionalServices &&
                itemizedAdditionalServices.map(({name, date, amount}) => {
                  return (
                    <tr key={name} className="text-xs">
                      <td>
                        {name} - {formatDate(date)}
                      </td>
                      <td className="price">{formatCurrency(amount)}</td>
                    </tr>
                  )
                })}
            </>
          )}

          {/* taxes and fees */}
          {totalTaxesAndFees > 0 && (
            <>
              <tr className="font-medium">
                <td>
                  <span className="mr-2">Total Taxes and Fees</span>
                  <a
                    className="text-xs link text-accent no-underline hover:underline"
                    onClick={() =>
                      setShowItemizedTaxesAndFees(!showItemizedTaxesAndFees)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                      if (e.key === 'Enter') {
                        setShowItemizedTaxesAndFees(!showItemizedTaxesAndFees)
                      }
                    }}
                    tabIndex={0}
                    aria-label="Toggle itemized taxes and fees."
                  >
                    {showItemizedTaxesAndFees ? 'hide' : 'show'}
                  </a>
                </td>
                <td className="price">{formatCurrency(totalTaxesAndFees)}</td>
              </tr>

              {showItemizedTaxesAndFees &&
                itemizedTaxesAndFees.map(({name, amount}) => {
                  return (
                    <tr key={name} className="text-xs">
                      <td>{name}</td>
                      <td className="price">{formatCurrency(amount)}</td>
                    </tr>
                  )
                })}
            </>
          )}

          {/* total amount */}
          <tr className="border-t">
            <td>
              <span className="font-bold mr-1">Room Total</span>
              <span className="text-xs">
                {isPrepaid ? '(Due Now)' : '(Due Later)'}
              </span>
            </td>
            <td className="font-bold price">{formatCurrency(total)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ShoppingCartPricingSummary
