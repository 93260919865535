import {persistReducer, createTransform} from 'redux-persist'
import {Reducer} from 'redux'
import storage from 'redux-persist/lib/storage'
import {
  addDays,
  isBefore,
  isSameDay,
  isValid,
  parseISO,
  formatISO
} from 'date-fns'
import {SearchParams, ShoppingCart, ShoppingCartItem} from '../types'
import {getLogger} from '../services/logging'

const logger = getLogger('persist')

// state validation for redux persist
const isBeforeDay = (date: Date, dateToCompare: Date) =>
  isValid(date) &&
  !isSameDay(date, dateToCompare) &&
  isBefore(date, dateToCompare)

const validateSearchTransform = createTransform(
  (search: SearchParams) => search,
  (search: SearchParams) => {
    const today = new Date()
    const checkInDate = search?.dateRange?.start
      ? parseISO(search.dateRange.start)
      : today

    logger.info({message: 'Validating search params...', data: {search}})

    // if check-in date is before today, reset checkin/checkout dates
    if (checkInDate && isBeforeDay(checkInDate, today)) {
      search.dateRange = {
        ...search.dateRange,
        start: formatISO(today),
        end: formatISO(addDays(today, 1))
      }
    }

    logger.info({message: 'Validating search params complete.', data: {search}})

    return search
  },
  {whitelist: ['search']}
)

const validateCartTransform = createTransform(
  (shoppingCart: ShoppingCart) => shoppingCart,
  (shoppingCart: ShoppingCart) => {
    const today = new Date()
    const cartItems = shoppingCart.items

    logger.info({message: 'Validating shopping cart...', data: {shoppingCart}})

    // remove items from shopping cart if arrival date is before today
    if (cartItems) {
      shoppingCart.items = cartItems.filter((item: ShoppingCartItem) => {
        const arrivalDate = parseISO(item.offer.arrival)
        return !isBeforeDay(arrivalDate, today)
      })
    }

    logger.info({
      message: 'Validating shopping cart complete.',
      data: {shoppingCart}
    })

    return shoppingCart
  },
  {whitelist: ['shoppingCart']}
)

// configure redux persist - persists state to local storage
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['search', 'shoppingCart'],
  transforms: [validateCartTransform, validateSearchTransform]
}

export const createPersistReducer = (rootReducer: Reducer) => {
  return persistReducer(persistConfig, rootReducer)
}
