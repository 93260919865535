import React, {useMemo} from 'react'
import CheckoutPaymentForm from './CheckoutPaymentForm'
import {
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery
} from '../../services/adyenPaymentsApi'
import {useAppSelector} from '../../store/hooks'
import {selectGuestInfo} from '../../store/guestInfoSlice'
import {selectBookingMetadata} from '../../store/selectors'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import Alert from '../common/Alert'
import {getConfig} from '../../services/config'
import {
  selectCartItems,
  selectCartItemsProperty
} from '../../store/shoppingCartSlice'
import {calcCartPriceSummary} from '../../services/offerUtils'
import {formatCurrency} from '../../services/utils'

const logger = getLogger('CheckoutPayment')
const {environment} = getConfig()
const isProduction = environment === 'prod'

export interface CheckoutPaymentProps {
  isActive: boolean
  onPaymentProcessing: (paymentSessionId: string) => void
}

const CheckoutPayment = ({
  isActive,
  onPaymentProcessing
}: CheckoutPaymentProps) => {
  // use hardcoded propertyId for testing in development environment
  const propertyId = isProduction
    ? useAppSelector(selectCartItemsProperty)
    : 'TEST'

  const guestInfo = useAppSelector(selectGuestInfo)
  const cartItems = useAppSelector(selectCartItems)
  const cartPriceSummary = useMemo(() => {
    return calcCartPriceSummary(cartItems)
  }, [cartItems])
  const paymentMethodsQuery = useGetPaymentMethodsQuery(propertyId as string, {
    skip: !propertyId
  })
  const paymentMethods = paymentMethodsQuery?.data
  const bookingMetadata = useAppSelector(selectBookingMetadata)
  const amountInCents =
    cartPriceSummary.totalPrepayment && cartPriceSummary.totalPrepayment > 0
      ? cartPriceSummary.totalPrepayment * 100
      : 0
  const paymentSessionQuery = useGetPaymentSessionQuery(
    {
      amountInCents,
      isPaymentAccount: true,
      metadata: bookingMetadata,
      propertyId,
      returnUrl: window.location.href,
      shopperEmail: guestInfo.email
    },
    {
      skip: !isActive || !propertyId
    }
  )
  const paymentSession = paymentSessionQuery?.data
  const isLoading =
    paymentMethodsQuery.isLoading || paymentSessionQuery.isLoading

  logger.info({
    message: 'Rendering CheckoutPayment...',
    data: {paymentSession, paymentMethods}
  })

  return (
    <div className="card card-compact bg-white border shadow">
      <div className="card-body">
        <h1 className="text-lg font-semibold">Payment</h1>

        {isActive && !isLoading && paymentMethods && paymentSession ? (
          <div className="mt-4 flex flex-col gap-1">
            {cartPriceSummary.totalPrepayment > 0 ? (
              <Alert type="info" className="mb-2">
                <p className="text-left">
                  A payment of{' '}
                  {formatCurrency(cartPriceSummary.totalPrepayment)} is required
                  to guarantee your stay.
                  {cartPriceSummary.balance > 0
                    ? ` The remaining balance of ${formatCurrency(
                        cartPriceSummary.balance
                      )} will be due later.`
                    : ''}
                </p>
              </Alert>
            ) : (
              <Alert type="info" className="mb-2">
                <p className="text-left">
                  Credit card details are needed to guarantee your stay. Your
                  card will not be charged.
                </p>
              </Alert>
            )}

            <CheckoutPaymentForm
              paymentMethods={paymentMethods}
              paymentSession={paymentSession}
              onPaymentCompleted={(result: any) => {
                const paymentSessionId =
                  paymentSession?.metadata?.paymentSessionId
                logger.info({
                  message: 'Payment completed',
                  data: {paymentSessionId, result}
                })
                ReactGA.event({
                  category: 'IBE',
                  action: 'Checkout_Payment_Submit',
                  value: 1
                })
                onPaymentProcessing(paymentSessionId)
              }}
            />
          </div>
        ) : (
          ''
        )}

        {isActive && isLoading && (
          <div className="my-8 flex flex-row gap-2 items-center">
            <span className="loading loading-spinner loading-sm"></span>
            <h2 className="text-sm">Creating secure payment session...</h2>
          </div>
        )}

        {isActive && !isLoading && (!paymentMethods || !paymentSession) && (
          <Alert type="error" className="mt-2">
            <p className="text-left">
              There was an error with the payment form. If the problem persists,
              please contact support:
              <a
                className="link"
                target="_blank"
                href="mailto:support@placemakr.com"
                aria-label="Email the Placemakr support team."
              >
                support@placemakr.com
              </a>
            </p>
          </Alert>
        )}
      </div>
    </div>
  )
}

export default CheckoutPayment
