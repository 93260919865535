import React from 'react'
import {UnitGroup} from '../../types'
import ImageCarousel from '../common/ImageCarousel'
import SearchUnitAttributes from './SearchUnitAttributes'
import {useAppSelector} from '../../store/hooks'
import {
  selectPropertiesById,
  selectUnitGroupsById
} from '../../store/configSlice'

interface FeatureSection {
  category: string
  features: string[]
}

const FeatureSection = ({category, features}: FeatureSection) => {
  return (
    <div className="mb-4">
      <h3 className="font-semibold mb-1">{category}</h3>
      <ul className="list-disc px-5 grid grid-cols-1 md:grid-cols-2 gap-0.5 text-sm text-primary">
        {features.map(feature => {
          return <li key={`${category}-${feature}`}>{feature}</li>
        })}
      </ul>
    </div>
  )
}

export interface SearchUnitDetailsModalProps {
  propertyId: string
  unitGroup: UnitGroup
  isOpen: boolean
  onClose: () => void
}

const SearchUnitDetailsModal = ({
  propertyId,
  unitGroup,
  isOpen,
  onClose
}: SearchUnitDetailsModalProps) => {
  const propertyLookup = useAppSelector(selectPropertiesById)
  const propertyConfig = propertyLookup[propertyId]
  const unitGroupConfigsById = useAppSelector(selectUnitGroupsById)
  const unitGroupConfig = unitGroupConfigsById[unitGroup?.id]
  const basicFeatures = unitGroupConfig?.basicFeatures || []
  const sleepingFeatures = unitGroupConfig?.sleepingFeatures || []
  const diningFeatures = unitGroupConfig?.diningFeatures || []
  const workingFeatures = unitGroupConfig?.workingFeatures || []
  const familyFeatures = unitGroupConfig?.familyFeatures || []
  const outdoorFeatures = unitGroupConfig?.outdoorFeatures || []
  const safetyFeatures = unitGroupConfig?.safetyFeatures || []
  const accessibilityFeatures = unitGroupConfig?.accessibilityFeatures || []
  const hasFeatures =
    basicFeatures.length > 0 ||
    sleepingFeatures.length > 0 ||
    diningFeatures.length > 0 ||
    workingFeatures.length > 0 ||
    familyFeatures.length > 0 ||
    outdoorFeatures.length > 0 ||
    safetyFeatures.length > 0 ||
    accessibilityFeatures.length > 0

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box p-0 w-[95%] lg:max-w-screen-md max-h-[95%]">
            <div className="w-full h-72 rounded-t-2xl overflow-hidden">
              <ImageCarousel images={unitGroupConfig?.images} />
            </div>
            <button
              className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
            <div className="modal-body overflow-y-scroll max-h-96 pr-4 mt-2 px-8 py-4">
              {/* unit group title */}
              <h1 className="font-semibold text-xl mb-1">{unitGroup.name}</h1>

              {/* property name/city */}
              <div className="text-sm text-primary text-pretty mb-2">
                {propertyConfig.name} &#x2022; {propertyConfig.location}{' '}
              </div>

              {/* unit group attributes */}
              <SearchUnitAttributes
                unitAttributes={unitGroupConfig?.attributes}
              />

              {/* unit group description */}
              <p className="my-1 text-sm text-light-primary">
                {unitGroup.description}
              </p>

              <div className="divider my-2"></div>

              {hasFeatures ? (
                <>
                  {basicFeatures?.length && (
                    <FeatureSection
                      category="Basic Essentials"
                      features={basicFeatures}
                    />
                  )}
                  {sleepingFeatures?.length && (
                    <FeatureSection
                      category="Sleeping Comforts"
                      features={sleepingFeatures}
                    />
                  )}
                  {diningFeatures?.length && (
                    <FeatureSection
                      category="Kitchen & Dining"
                      features={diningFeatures}
                    />
                  )}
                  {outdoorFeatures?.length && (
                    <FeatureSection
                      category="Outdoor Activities"
                      features={outdoorFeatures}
                    />
                  )}
                  {workingFeatures?.length && (
                    <FeatureSection
                      category="Workspace & Business"
                      features={workingFeatures}
                    />
                  )}
                  {familyFeatures?.length && (
                    <FeatureSection
                      category="Family Friendly"
                      features={familyFeatures}
                    />
                  )}
                  {safetyFeatures?.length && (
                    <FeatureSection
                      category="Safety"
                      features={safetyFeatures}
                    />
                  )}
                  {accessibilityFeatures?.length && (
                    <FeatureSection
                      category="ADA Accessibility"
                      features={accessibilityFeatures}
                    />
                  )}
                </>
              ) : (
                <div className="text-sm text-light-primary mb-4">
                  No features available
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchUnitDetailsModal
