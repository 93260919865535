import React from 'react'
import {Offer, ServiceOffer} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import {isMemberRate} from '../../services/offerUtils'
import {useAppSelector} from '../../store/hooks'
import {selectGuestsPerRoom, selectSearch} from '../../store/searchSlice'
import {useSearchServiceOffersQuery} from '../../services/ibeApi'
import {FocusTrap} from 'focus-trap-react'
import ShoppingCartPricingSummary from '../cart/ShoppingCartPricingSummary'
import NoShowPolicy from '../common/NoShowPolicy'
import CancellationPolicy from '../common/CancellationPolicy'

export interface SearchUnitOfferDetailsModalProps {
  offer: Offer
  isOpen: boolean
  onClose: () => void
}

const SearchUnitOfferDetailsModal = ({
  offer,
  isOpen,
  onClose
}: SearchUnitOfferDetailsModalProps) => {
  const ratePlanId = offer?.ratePlan?.id || ''
  const search = useAppSelector(selectSearch)
  const guestsPerRoom = useAppSelector(selectGuestsPerRoom)
  const serviceOffersQuery = useSearchServiceOffersQuery(
    {
      ratePlanId,
      adults: guestsPerRoom,
      dateRange: search.dateRange
    },
    {skip: !ratePlanId}
  )
  const serviceOffers = serviceOffersQuery.data?.services || []
  const serviceOffersLoading = serviceOffersQuery.isLoading

  return (
    <>
      {isOpen && (
        <FocusTrap active={isOpen}>
          <div
            className="modal modal-open"
            onClick={e => {
              if (e.target === e.currentTarget) {
                onClose()
              }
            }}
            role="dialog"
            aria-modal="true"
          >
            <div className="modal-box w-[95%] lg:max-w-screen-md max-h-[95%]">
              <div className="modal-header">
                <button
                  className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                  onClick={onClose}
                  aria-label="Close unit offer details modal."
                >
                  ✕
                </button>
                <h2 className="font-semibold text-xl">
                  {offer.unitGroup.name}
                </h2>
                <h3 className="text-lg">{offer.ratePlan.name}</h3>
              </div>
              <div className="divider my-0"></div>

              <div className="modal-body mt-4 overflow-y-scroll">
                <div className="flex flex-col gap-4">
                  {/* Member Rate Details */}
                  {isMemberRate(offer) && (
                    <div className="rounded-lg bg-gray-100 p-3">
                      <h3 className="font-semibold border-b mb-2">
                        Member Rate Details
                      </h3>
                      <p className="text-sm">
                        Book direct as a Placemakr Member and receive a
                        discounted rate if you pay now. No changes or refunds.
                        If cancelled, the amount paid will serve as the
                        cancellation.
                      </p>
                      <div className="flex flex-row items-center text-sm gap-2 mt-2">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-accent mr-1"
                          title="Member Rate"
                        />
                        <p className="italic">
                          It's always free and easy to join as a Placemakr
                          Member. Complete sign up at checkout.
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Cancellation Policy */}
                  <div className="rounded-lg bg-gray-100 p-3 text-sm">
                    <CancellationPolicy offer={offer} />
                  </div>

                  {/* No Show Policy */}
                  <div className="rounded-lg bg-gray-100 p-3 text-sm">
                    <NoShowPolicy offer={offer} />
                  </div>

                  {/* Pricing */}
                  <div className="rounded-lg bg-gray-100 p-3">
                    <h3 className="font-semibold border-b mb-2">
                      Pricing Breakdown
                    </h3>
                    <ShoppingCartPricingSummary
                      offer={offer}
                      additionalServices={[]}
                    />
                  </div>

                  {/* Service Details */}
                  {!serviceOffersLoading &&
                    serviceOffers &&
                    serviceOffers.length > 0 && (
                      <div className="rounded-lg bg-gray-100 p-3">
                        <h3 className="font-semibold border-b mb-2">
                          Optional Services
                        </h3>
                        <>
                          {serviceOffers.map((serviceOffer: ServiceOffer) => {
                            return (
                              <div
                                key={serviceOffer.service.id}
                                className="flex flex-row justify-between w-full text-sm"
                              >
                                <div>{serviceOffer.service.name}</div>
                                <div className="flex flex-row items-center">
                                  <span className="mr-0.5">
                                    ${serviceOffer.totalAmount.grossAmount}
                                  </span>
                                  <span className="uppercase text-xs">
                                    {serviceOffer.totalAmount.currency}
                                  </span>
                                  <span className="ml-1 lowercase text-xs">
                                    /{serviceOffer.service.pricingUnit}
                                  </span>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      </div>
                    )}

                  {/* Placemakr Policies */}
                  <div className="rounded-lg bg-gray-100 p-3">
                    <h3 className="font-semibold border-b mb-2">
                      Placemakr Policies
                    </h3>

                    <p className="mb-2 text-sm">
                      <span className="font-medium">
                        Reservation Guarantee:
                      </span>{' '}
                      All reservations require a valid credit card at the time
                      of booking. Your reservation is held until midnight on the
                      day of arrival, unless prior arrangements are made.
                    </p>

                    <p className="text-sm">
                      <span className="font-medium">ID Verification:</span> All
                      reservations require that the guest present a valid
                      government-issued ID prior to check-in.
                    </p>
                  </div>

                  {/* Contact Info */}
                  <div className="italic text-xs text-center text-primary mt-1">
                    Have questions? Contact us at info@placemakr.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  )
}

export default SearchUnitOfferDetailsModal
