import React, {useMemo} from 'react'
import {selectCartItems} from '../../store/shoppingCartSlice'
import {useAppSelector} from '../../store/hooks'
import * as types from '../../types'
import ShoppingCartItem from './ShoppingCartItem'
import clsx from 'clsx'
import {formatCurrency} from '../../services/utils'
import {getLogger} from '../../services/logging'
import EmptyCart from '../../assets/ibe-empty-cart.png'
import {calcCartPriceSummary} from '../../services/offerUtils'

const logger = getLogger('ShoppingCart')

export interface ShoppingCartProps {
  className?: string
}

const ShoppingCart = ({className = ''}: ShoppingCartProps) => {
  const cartItems = useAppSelector(selectCartItems)
  const cartTotals = useMemo(() => {
    return calcCartPriceSummary(cartItems)
  }, [cartItems])
  const hasPrepayment = cartTotals.totalPrepayment > 0
  const hasBalance = cartTotals.balance > 0

  logger.debug({
    message: 'Rendering ShoppingCart...',
    data: {cartItems, cartTotals}
  })

  return (
    <div className={clsx('flex flex-col', className)}>
      {!cartItems || cartItems.length === 0 ? (
        <div className="mt-8 flex flex-col gap-4 items-center">
          <img
            src={EmptyCart}
            alt="Shopping cart is empty image"
            className="max-h-72"
          />
          <h2 className="text-xl font-semibold">
            Your shopping cart is empty.
          </h2>
        </div>
      ) : (
        <>
          <div className="flex-1 flex flex-col gap-2">
            {cartItems.map((item: types.ShoppingCartItem, index: number) => (
              <ShoppingCartItem
                key={index}
                item={item}
                itemIndex={index}
                showActions={true}
                showItemIndex={cartItems?.length > 1}
              />
            ))}
          </div>

          <div className="card card-compact border bg-white shadow w-full mt-2">
            <div className="card-body flex flex-col items-end gap-0 w-full text-right">
              <div className="flex flex-row gap-1">
                <span className="text-md font-semibold">Total:</span>
                <span className="text-md font-bold w-20">
                  {formatCurrency(cartTotals.total)}
                </span>
              </div>

              {hasPrepayment && (
                <div className="flex flex-row gap-1">
                  <div className="flex flex-col">
                    <span className="text-md font-semibold">
                      * Due at Checkout:
                    </span>
                  </div>
                  <span className="text-md font-bold w-20">
                    {formatCurrency(cartTotals.totalPrepayment)}
                  </span>
                </div>
              )}

              {hasPrepayment && (
                <div className="text-right w-full text-xs text-gray-400 mt-2">
                  * All non-refundable room rates require full prepayment at
                  time of booking.
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ShoppingCart
