import {z} from 'zod'

export const SearchGuestParamsSchema = z.object({
  rooms: z.number(),
  adults: z.number(),
  children: z.number(),
  hasInfants: z.boolean(),
  hasPets: z.boolean()
})

export const SearchDateRangeTypeSchema = z.enum(['Specific', 'Flexible'])

export const SearchDateRangeParamsSchema = z.object({
  type: SearchDateRangeTypeSchema,
  start: z.string().date().optional(),
  end: z.string().date().optional(),
  nights: z.number().optional()
})

export const SearchUnitGroupTypeSchema = z.enum([
  'BedRoom',
  'MeetingRoom',
  'EventSpace',
  'ParkingLot',
  'Other'
])

export const SearchSpecialRatesParamsSchema = z.object({
  promoCode: z.string().optional(),
  corporateCode: z.string().optional(),
  hasAAA: z.boolean(),
  hasAARP: z.boolean(),
  hasGov: z.boolean(),
  hasMilitary: z.boolean()
})

export const SearchParamsSchema = z.object({
  location: z.string(),
  dateRange: SearchDateRangeParamsSchema,
  guests: SearchGuestParamsSchema,
  propertyId: z.string().optional(),
  specialRates: SearchSpecialRatesParamsSchema
  // unitGroupTypes: z.array(SearchUnitGroupTypeSchema)
})

export const OfferRequestSchema = z.object({
  propertyId: z.string(),
  dateRange: SearchDateRangeParamsSchema,
  guests: SearchGuestParamsSchema,
  specialRates: SearchSpecialRatesParamsSchema.optional(),
  unitGroupTypes: z.array(SearchUnitGroupTypeSchema).optional()
})

export const PropertySchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  description: z.string()
})

export const UnitGroupSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
  maxPersons: z.number(),
  type: z.string()
})

export const ServiceSchema = z.object({
  service: z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    pricingUnit: z.string(),
    defaultGrossPrice: z.object({
      amount: z.number(),
      currency: z.string()
    })
  }),
  count: z.number(),
  availableCount: z.number(),
  totalAmount: z.object({
    grossAmount: z.number(),
    netAmount: z.number(),
    vatType: z.string(),
    vatPercent: z.number(),
    currency: z.string()
  }),
  prePaymentAmount: z.object({
    amount: z.number(),
    currency: z.string()
  }),
  dates: z.array(
    z.object({
      serviceDate: z.string(),
      amount: z.object({
        grossAmount: z.number(),
        netAmount: z.number(),
        vatType: z.string(),
        vatPercent: z.number(),
        currency: z.string()
      }),
      isDefaultDate: z.boolean(),
      isMandatory: z.boolean(),
      availableCount: z.number()
    })
  )
})

export const FeeSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  totalAmount: z.object({
    grossAmount: z.number(),
    netAmount: z.number(),
    vatType: z.string(),
    vatPercent: z.number(),
    currency: z.string()
  })
})

export const TaxDetailSchema = z.object({
  vatType: z.string(),
  vatPercent: z.number(),
  net: z.object({amount: z.number(), currency: z.string()}),
  tax: z.object({amount: z.number(), currency: z.string()})
})

export const CityTaxSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
  totalGrossAmount: z.object({
    amount: z.number(),
    currency: z.string()
  }),
  dates: z.array(
    z.object({
      serviceDate: z.string(),
      amount: z.object({
        grossAmount: z.number(),
        netAmount: z.number(),
        vatType: z.string(),
        vatPercent: z.number(),
        currency: z.string()
      })
    })
  )
})

export const OfferSchema = z.object({
  arrival: z.string(),
  departure: z.string(),
  unitGroup: UnitGroupSchema,
  minGuaranteeType: z.string(),
  availableUnits: z.number(),
  ratePlan: z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    isSubjectToCityTax: z.boolean()
  }),
  totalGrossAmount: z.object({
    amount: z.number(),
    currency: z.string()
  }),
  cancellationFee: z.object({
    code: z.string(),
    name: z.string(),
    description: z.string(),
    dueDateTime: z.string(),
    fee: z.object({amount: z.number(), currency: z.string()})
  }),
  noShowFee: z.object({
    code: z.string(),
    name: z.string(),
    description: z.string(),
    fee: z.object({amount: z.number(), currency: z.string()})
  }),
  timeSlices: z.array(
    z.object({
      from: z.string(),
      to: z.string(),
      availableUnits: z.number(),
      baseAmount: z.object({
        grossAmount: z.number(),
        netAmount: z.number(),
        vatType: z.string(),
        vatPercent: z.number(),
        currency: z.string()
      }),
      totalGrossAmount: z.object({
        amount: z.number(),
        currency: z.string()
      }),
      includedServices: z
        .array(
          z.object({
            service: z.object({
              id: z.string(),
              code: z.string(),
              name: z.string(),
              description: z.string()
            }),
            serviceDate: z.string(),
            count: z.number(),
            amount: z.object({
              grossAmount: z.number(),
              netAmount: z.number(),
              vatType: z.string(),
              vatPercent: z.number(),
              currency: z.string()
            }),
            pricingMode: z.string()
          })
        )
        .optional()
    })
  ),
  services: z.array(ServiceSchema),
  fees: z.array(FeeSchema),
  taxDetails: z.array(TaxDetailSchema).optional(),
  isCorporate: z.boolean(),
  prePaymentAmount: z.object({
    amount: z.number(),
    currency: z.string()
  }),
  cityTaxes: z.array(CityTaxSchema).optional()
})

export const OfferResultSchema = z.object({
  property: PropertySchema,
  offers: z.array(OfferSchema)
})

export const MapCoordinatesSchema = z.object({
  latitude: z.number(),
  longitude: z.number()
})

export const ImageAssetSchema = z.object({
  image: z.string(),
  altText: z.string()
})

export const PropertyConfigSchema = z.object({
  propertyApaleoId: z.string(),
  name: z.string(),
  description: z.string(),
  neighborhood: z.string(),
  location: z.string(),
  regions: z.array(z.string()),
  isEnabled: z.boolean(),
  isPopup: z.boolean(),
  images: z.array(ImageAssetSchema),
  features: z.array(z.string()),
  highlightedFeatures: z.array(z.string()),
  address: z.object({
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    postalCode: z.string(),
    city: z.string(),
    state: z.string(),
    countryCode: z.string()
  }),
  coordinates: MapCoordinatesSchema
})

export const UnitGroupConfigSchema = z.object({
  unitgroupApaleoId: z.string(),
  propertyApaleoId: z.string(),
  name: z.string(),
  description: z.string(),
  images: z.array(ImageAssetSchema),
  attributes: z.object({
    bedrooms: z.number(),
    bathrooms: z.number(),
    maxGuests: z.number()
  }),
  highlightedFeatures: z.array(z.string()),
  basicFeatures: z.array(z.string()),
  sleepingFeatures: z.array(z.string()),
  diningFeatures: z.array(z.string()),
  workingFeatures: z.array(z.string()),
  familyFeatures: z.array(z.string()),
  outdoorFeatures: z.array(z.string()),
  safetyFeatures: z.array(z.string()),
  accessibilityFeatures: z.array(z.string())
})

export const IbeConfigSchema = z.object({
  locations: z.array(z.string()),
  properties: z.array(PropertyConfigSchema),
  unitGroups: z.array(UnitGroupConfigSchema)
})

export const UnitGroupOfferSchema = z.object({
  propertyId: z.string(),
  unitGroup: UnitGroupSchema,
  availableUnits: z.number(),
  lowestGrossAmount: z.object({
    amount: z.number(),
    currency: z.string()
  }),
  lowestNightlyAmount: z.object({
    amount: z.number(),
    currency: z.string()
  }),
  nights: z.number(),
  offers: z.array(OfferSchema)
})

export const TaxAndFeeItemSchema = z.object({
  name: z.string(),
  amount: z.number(),
  currency: z.string()
})

export const ToastNotificationSchema = z.object({
  id: z.string(),
  message: z.string(),
  type: z.enum(['add', 'remove'])
})

export const ServiceOfferRequestSchema = z.object({
  ratePlanId: z.string(),
  dateRange: SearchDateRangeParamsSchema,
  adults: z.number()
})

export const ServiceOfferSchema = z.object({
  service: z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    pricingUnit: z.enum(['Person', 'Room']),
    defaultGrossPrice: z.object({
      amount: z.number(),
      currency: z.string()
    })
  }),
  count: z.number(),
  totalAmount: z.object({
    grossAmount: z.number(),
    netAmount: z.number(),
    vatType: z.string(),
    vatPercent: z.number(),
    currency: z.string()
  }),
  prePaymentAmount: z.object({amount: z.number(), currency: z.string()}),
  fees: z.array(
    z.object({
      id: z.string(),
      code: z.string(),
      name: z.string(),
      totalAmount: z.object({
        grossAmount: z.number(),
        netAmount: z.number(),
        vatType: z.string(),
        vatPercent: z.number(),
        currency: z.string()
      })
    })
  ),
  dates: z.array(
    z.object({
      serviceDate: z.string(),
      amount: z.object({
        grossAmount: z.number(),
        netAmount: z.number(),
        vatType: z.string(),
        vatPercent: z.number(),
        currency: z.string()
      }),
      isDefaultDate: z.boolean(),
      isMandatory: z.boolean()
    })
  )
})

export const ServiceOfferResultsSchema = z.object({
  services: z.array(ServiceOfferSchema)
})

export const ShoppingCartItemSchema = z.object({
  id: z.string(),
  propertyId: z.string(),
  offer: OfferSchema,
  additionalServices: z.array(ServiceOfferSchema),
  adults: z.number()
})

export const ItemEditorModeSchema = z.enum(['Add', 'Edit'])

export const ItemEditSchema = z.object({
  item: ShoppingCartItemSchema,
  mode: ItemEditorModeSchema
})

export const BookingGuestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string()
})

export const BookingReservationSchema = z.object({
  arrival: z.string(),
  departure: z.string(),
  adults: z.number(),
  childrenAges: z.array(z.number()),
  guestComment: z.string().optional(),
  channelCode: z.literal('Ibe'),
  primaryGuest: BookingGuestSchema,
  guaranteeType: z.string(),
  timeSlices: z.array(z.object({ratePlanId: z.string()})),
  services: z.array(z.object({serviceId: z.string()})),
  prePaymentAmount: z.object({amount: z.number(), currency: z.string()})
})

export const CreateIbeBookingMetadataSchema = z.object({
  type: z.literal('CreateIbeBooking'),
  booker: BookingGuestSchema,
  reservations: z.array(BookingReservationSchema)
})

export const PaymentSessionRequestSchema = z.object({
  amountInCents: z.number(),
  isPaymentAccount: z.boolean(),
  metadata: CreateIbeBookingMetadataSchema,
  propertyId: z.string(),
  returnUrl: z.string(),
  shopperId: z.string().optional(),
  shopperEmail: z.string()
})

export const CategorizedFeatureSchema = z.object({
  category: z.string(),
  features: z.array(z.string())
})

export const OfferLineItemSchema = z.object({
  type: z.string(),
  date: z.string(),
  description: z.string(),
  amount: z.number(),
  currency: z.string()
})

export const HubspotPropertySchema = z.object({
  id: z.number().optional(),
  property_id: z.string().optional(),
  pm_property_name: z.string().optional(),
  city_page_url_slug: z.string().optional(),
  location_group: z.string().optional()
})

export const HubspotPropertyResultsSchema = z.object({
  hasMore: z.boolean(),
  offset: z.number(),
  results: z.array(HubspotPropertySchema),
  total: z.number()
})

export const HubspotLocationSchema = z.object({
  name: z.string(),
  url: z.string()
})
