import React from 'react'
import {PropertyConfig} from '../../types'
import {getConfig} from '../../services/config'

export interface SearchGoogleMapModalProps {
  properties?: PropertyConfig[]
  selectedProperty?: string
  isOpen: boolean
  onClose: () => void
}

const SearchGoogleMapModal = ({
  properties,
  selectedProperty,
  isOpen,
  onClose
}: SearchGoogleMapModalProps) => {
  const property = properties?.find(
    property => property.propertyApaleoId === selectedProperty
  )

  // display nothing if property is not found
  if (!property) {
    return null
  }

  const {googleMapsApiKey} = getConfig()
  const address1 = property.address?.addressLine1
  const address2 = property.address?.addressLine2 || ''
  const city = property.address?.city
  const state =
    property.address?.state === 'DC' ? null : property.address?.state
  const postalCode = property.address?.postalCode
  const {latitude, longitude} = property.coordinates
  const mapUrl =
    `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}` +
    `&zoom=16&size=800x600&markers=color:red%7C${latitude},${longitude}&key=${googleMapsApiKey}`

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box">
            <button
              className="btn btn-sm btn-circle btn-white absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
            <div className="flex flex-col">
              <div className="font-bold text-lg">{property.name}</div>
              <div>{`${address1} ${address2 ? ', ' + address2 : ''}`}</div>
              <div>{`${city}${state ? ', ' + state : ''} ${postalCode}`}</div>
            </div>

            <img className="mt-4 border p-1" src={mapUrl} />
          </div>
        </div>
      )}
    </>
  )
}

export default SearchGoogleMapModal
