import React, {useEffect, useRef, useState} from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import {
  CoreOptions,
  PaymentMethodsConfiguration
} from '@adyen/adyen-web/dist/types/core/types'
import {getConfig} from '../../services/config'
import {getLogger} from '../../services/logging'
import Alert from '../common/Alert'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin'
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement'

const logger = getLogger('CheckoutPaymentForm')
const {adyenClientId, adyenEnvironment} = getConfig()

function getPayBtn(): HTMLButtonElement | null {
  const checkoutBtn = document.querySelector('button.adyen-checkout__button')
  if (checkoutBtn) {
    return checkoutBtn as HTMLButtonElement
  }
  return null
}

function disablePayBtn(): void {
  const checkoutBtn = getPayBtn()
  if (checkoutBtn) {
    checkoutBtn.setAttribute('disabled', 'disabled')
  }
}

function enablePayBtn(): void {
  const checkoutBtn = getPayBtn()
  if (checkoutBtn) {
    checkoutBtn.removeAttribute('disabled')
  }
}

function addPayBtnListener(): void {
  const checkoutBtn = getPayBtn()
  if (checkoutBtn) {
    checkoutBtn.addEventListener('click', disablePayBtn)
  }
}

function removePayBtnListener(): void {
  try {
    const checkoutBtn = getPayBtn()
    if (checkoutBtn) {
      checkoutBtn.removeEventListener('click', disablePayBtn)
    }
  } catch (e) {
    logger.error({
      message: 'Failed to remove event listener from Adyen payment button',
      data: {error: e}
    })
  }
}

/**
 * Initializes and renders the Adyen checkout payment form component.
 * @param paymentContainer The payment container element
 * @param paymentMethods The collection of supported payment methods
 * @param paymentSession The payment session data
 * @param onPaymentCompleted Callback function to handle successful payment
 * @param onPaymentError Callback function to handle payment errors
 * @returns The Adyen DropinElement
 */
const initCheckout = async (
  paymentContainer: any,
  paymentMethods: any,
  paymentSession: any,
  onPaymentCompleted: Function,
  onPaymentError: Function
): Promise<DropinElement> => {
  logger.info({
    message: 'Initializing Adyen checkout',
    data: {paymentMethods, paymentSession}
  })

  const payMessage = 'Book Now'
  const paymentMethodsConfig: PaymentMethodsConfiguration = {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      billingAddressRequired: true,
      name: 'Credit or Debit Card'
    }
  }

  const configuration: CoreOptions = {
    paymentMethodsResponse: paymentMethods,
    paymentMethodsConfiguration: paymentMethodsConfig,
    clientKey: adyenClientId,
    environment: adyenEnvironment,
    locale: 'en-US',
    session: {
      id: paymentSession?.id,
      sessionData: paymentSession?.sessionData
    },
    translations: {
      'en-US': {
        confirmPurchase: payMessage,
        confirmPreauthorization: payMessage
      }
    },
    onChange: (_state: any, _element: UIElement): void => {
      logger.info({
        message: 'Payment form state changed',
        data: {_state, _element}
      })
      enablePayBtn()
    },
    onPaymentCompleted: (result: any): void => {
      disablePayBtn()
      logger.info({
        message: 'Payment accepted and being processed.',
        data: {result}
      })
      if (onPaymentCompleted) {
        if (result.resultCode === 'Authorised') {
          if (onPaymentCompleted) {
            onPaymentCompleted(result)
          }
        } else {
          enablePayBtn()
          if (onPaymentError) {
            onPaymentError(result)
          }
        }
      }
    },
    onError: (error: any): void => {
      logger.error({
        message: 'Payment failed',
        data: {error, paymentSession, paymentMethods}
      })
      enablePayBtn()
      if (onPaymentError) {
        onPaymentError(error)
      }
    }
  }

  const checkout = await AdyenCheckout(configuration)
  const dropin = checkout
    .create('dropin', {showPayButton: true})
    .mount(paymentContainer.current)

  // add event listener to the pay button
  setTimeout(() => {
    addPayBtnListener()
  }, 500)

  return dropin
}

interface CheckoutPaymentFormProps {
  paymentMethods: any
  paymentSession: any
  onPaymentCompleted: (result: any) => void
}

const CheckoutPaymentForm = ({
  paymentMethods,
  paymentSession,
  onPaymentCompleted
}: CheckoutPaymentFormProps) => {
  // get reference to container element
  const paymentContainer = useRef(null)
  const dropinRef = useRef<any>(null)
  const [hasError, setHasError] = useState<boolean>(false)

  const destroyCheckout = () => {
    if (dropinRef?.current) {
      try {
        logger.info({
          message: 'Unmounting Adyen payment component...'
        })
        removePayBtnListener()
        dropinRef.current.unmount()
      } catch (e) {
        logger.error({
          message: 'Failed to unmount Adyen payment component',
          data: {error: e}
        })
      }
    }
  }

  useEffect(() => {
    if (paymentContainer && paymentSession) {
      logger.info({
        message:
          'Payment container and/or session changed. Rendering checkout payment form...',
        data: {paymentContainer, paymentSession}
      })

      // remove previous checkout component
      if (dropinRef?.current) {
        logger.info({
          message: 'Removing existing Adyen payment component...'
        })
        destroyCheckout()
      }

      const allowedPaymentMethods = {
        paymentMethods: paymentMethods.paymentMethods.filter(
          (pm: any) => pm.name === 'Credit Card'
        )
      }

      const onPaymentError = () => {
        setHasError(true)
        if (window) {
          window.scrollTo(0, 0)
        }
      }

      // initialize the adyen payment component
      const initCheckoutPromise = initCheckout(
        paymentContainer,
        allowedPaymentMethods,
        paymentSession,
        onPaymentCompleted,
        onPaymentError
      )

      initCheckoutPromise
        .then((dropin: DropinElement) => {
          logger.info({
            message: 'Adyen payment form rendered successfully',
            data: {dropin}
          })
          dropinRef.current = dropin
        })
        .catch(e => {
          console.error('Error rendering Adyen payment form', e)
        })
    }

    // cleanup code
    return () => {
      if (dropinRef?.current) {
        logger.info({
          message: 'Cleanup Adyen payment component...'
        })
        destroyCheckout()
      }
    }
  }, [paymentContainer, paymentSession])

  return (
    <div className="flex flex-col gap-4">
      {hasError && (
        <Alert type="error">
          <p className="text-left">
            There was an error processing your payment. Please make sure your
            payment information is correct and try again. If the problem
            persists, please contact customer support.
          </p>
        </Alert>
      )}
      <div ref={paymentContainer} />
    </div>
  )
}

export default CheckoutPaymentForm
