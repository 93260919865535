import React, {useEffect, useState} from 'react'
import CheckoutGuestInfoForm from './CheckoutGuestInfoForm'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import MemberAuthenticator, {
  MemberAuthenticatorView
} from '../auth/MemberAuthenticator'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons'
import {
  clearUser,
  selectIsAuthenticated,
  selectUser
} from '../../store/userSlice'
import {
  selectGuestInfo,
  clearGuestInfo,
  selectIsValidGuestInfo,
  setGuestInfo
} from '../../store/guestInfoSlice'
import CheckoutGuestInfoDisplay from './CheckoutGuestInfoDisplay'
import {Auth} from 'aws-amplify'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import {GuestInfo} from '../../types'
import {getConfig} from '../../services/config'

const logger = getLogger('CheckoutGuestInfo')

enum GuestInfoView {
  Choose = 'Choose',
  Login = 'Login',
  Register = 'Register',
  MemberForm = 'MemberForm',
  GuestForm = 'GuestForm',
  EditForm = 'EditForm',
  Display = 'Display'
}

interface GuestInfoHeaderProps {
  view: GuestInfoView
}

function GuestInfoHeader({view}: GuestInfoHeaderProps) {
  let subtitle = null

  // set subtitle based on view
  switch (view) {
    case GuestInfoView.Login:
      subtitle = 'Login'
      break
    case GuestInfoView.Register:
      subtitle = 'Create a Member Account'
      break
    case GuestInfoView.GuestForm:
      subtitle = 'Check Out as Guest'
      break
    case GuestInfoView.MemberForm:
      subtitle = 'Check Out as Member'
      break
    case GuestInfoView.EditForm:
      subtitle = 'Edit details'
      break
    default:
      subtitle = null
      break
  }

  return (
    <div className="text-lg">
      <span className="font-semibold">Guest Information</span>
      {subtitle && <span className="font-light">: {subtitle}</span>}
    </div>
  )
}

// enforce member rate restrictions - force user to login or signup
const {ibeMemberRateCheck} = getConfig()

const CheckoutGuestInfo = () => {
  const dispatch = useAppDispatch()
  const [guestInfoView, setGuestInfoView] = useState<GuestInfoView>(
    GuestInfoView.Choose
  )
  const guestInfo = useAppSelector(selectGuestInfo)
  const user = useAppSelector(selectUser)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isValidGuestInfo = useAppSelector(selectIsValidGuestInfo)

  const handleSignOut = () => {
    logger.info({message: 'User logged out'})
    ReactGA.event({
      category: 'IBE',
      action: 'User_Logout',
      value: 1
    })

    // sign out and clear user state
    Auth.signOut()
    dispatch(clearUser())
    dispatch(clearGuestInfo())
  }

  const handleLogin = () => {
    logger.info({message: 'User chose to login as user'})
    ReactGA.event({
      category: 'IBE',
      action: 'Checkout_Member_Login',
      value: 1
    })
    setGuestInfoView(GuestInfoView.Login)
  }

  const handleBack = () => {
    logger.info({
      message: 'User navigated back from user login/registration'
    })
    ReactGA.event({
      category: 'IBE',
      action: 'Checkout_Back_Choose',
      value: 1
    })
    setGuestInfoView(GuestInfoView.Choose)
  }

  useEffect(() => {
    if (isValidGuestInfo) {
      // guestInfo is valid, show the guest info
      logger.info({message: 'Guest Info is valid, show guest info'})
      setGuestInfoView(GuestInfoView.Display)
    } else if (!isValidGuestInfo && isAuthenticated) {
      // guestInfo is invalid but user is authenticated, show the prepopulated form
      logger.info({message: 'Guest Info is invalid but user is authenticated'})
      setGuestInfoView(GuestInfoView.MemberForm)
    } else {
      // default to choose
      logger.info({
        message: 'Choose to login/register user or checkout as guest'
      })
      setGuestInfoView(GuestInfoView.Choose)
    }
  }, [isValidGuestInfo, isAuthenticated])

  return (
    <div className="card card-compact bg-white border shadow">
      <div className="card-body">
        <GuestInfoHeader view={guestInfoView} />

        {user.isAuthenticated && (
          <div className="flex flex-row items-center gap-2">
            <span className="flex-1">
              Hello,{' '}
              <span className="font-semibold">
                {user?.isAuthenticated ? user?.firstName : 'Guest'}
              </span>
              . You're logged in as a member.
            </span>
            <a
              className="link text-accent text-bold text-sm flex-0 no-underline hover:underline"
              onClick={handleSignOut}
              onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                if (e.key === 'Enter') {
                  handleSignOut()
                }
              }}
              tabIndex={0}
              aria-label="Sign out of your account."
            >
              sign out
            </a>
          </div>
        )}

        {/* Choose register, login, or checkout as guest */}
        {guestInfoView === GuestInfoView.Choose && (
          <div className="flex flex-col gap-4">
            {/* Create member account */}
            <button
              className="btn btn-secondary"
              onClick={() => {
                logger.info({message: 'User chose to register as member'})
                ReactGA.event({
                  category: 'IBE',
                  action: 'Checkout_Member_Register',
                  value: 1
                })
                setGuestInfoView(GuestInfoView.Register)
              }}
              aria-label="Create a new member account."
            >
              create a member account
            </button>

            {/* Checkout as guest (non-member rates only IF member rate check setting is enabled) */}
            {!ibeMemberRateCheck && (
              <button
                className="btn btn-secondary btn-outline !text-primary"
                onClick={() => {
                  logger.info({message: 'User chose to checkout as guest'})
                  ReactGA.event({
                    category: 'IBE',
                    action: 'Checkout_Guest',
                    value: 1
                  })
                  setGuestInfoView(GuestInfoView.GuestForm)
                }}
                aria-label="Checkout as guest."
              >
                checkout as guest
              </button>
            )}

            <p className="text-center text-sm text-light-primary">
              Already a member?{' '}
              <a
                className="link text-accent font-medium no-underline hover:underline"
                onClick={handleLogin}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'Enter') {
                    handleLogin()
                  }
                }}
                tabIndex={0}
                aria-label="Login to your existing account."
              >
                Login here.
              </a>
            </p>
          </div>
        )}

        {/* Form: login/registration */}
        {(guestInfoView === GuestInfoView.Login ||
          guestInfoView === GuestInfoView.Register) && (
          <>
            <MemberAuthenticator
              className="mt-2"
              view={
                guestInfoView === GuestInfoView.Register
                  ? MemberAuthenticatorView.SignUp
                  : MemberAuthenticatorView.SignIn
              }
            />
            {!user.isAuthenticated && (
              <div className="flex justify-center w-full mt-2">
                <a
                  className="link link-primary text-sm"
                  onClick={handleBack}
                  onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                    if (e.key === 'Enter') {
                      handleBack()
                    }
                  }}
                  tabIndex={0}
                  aria-label="Back to choose guest information view."
                >
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    className="mr-1"
                    title="Back to guest info"
                  />
                  <span>back</span>
                </a>
              </div>
            )}
          </>
        )}

        {/* Form: checkout as guest */}
        {(guestInfoView === GuestInfoView.GuestForm ||
          guestInfoView === GuestInfoView.MemberForm ||
          guestInfoView === GuestInfoView.EditForm) && (
          <CheckoutGuestInfoForm
            className="mt-4"
            isEditMode={guestInfoView === GuestInfoView.EditForm}
            user={user}
            guestInfo={guestInfo}
            onBack={() => {
              logger.info({message: 'User navigated back from guest info form'})
              setGuestInfoView(GuestInfoView.Choose)
            }}
            onCancel={() => {
              logger.info({
                message: 'User cancelled the edit to guest info form'
              })
              setGuestInfoView(GuestInfoView.Display)
            }}
            onSave={(guestInfo: GuestInfo) => {
              logger.info({
                message: 'User saved updated guest info',
                data: {guestInfo}
              })
              dispatch(setGuestInfo(guestInfo))
              setGuestInfoView(GuestInfoView.Display)
            }}
          />
        )}

        {/* Guest info display */}
        {guestInfoView === GuestInfoView.Display && (
          <CheckoutGuestInfoDisplay
            guestInfo={guestInfo}
            onEdit={() => {
              logger.info({message: 'User is editing guest info'})
              setGuestInfoView(GuestInfoView.EditForm)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default CheckoutGuestInfo
