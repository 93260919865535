import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import PhoneInput from 'react-phone-input-2'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'
import {GuestInfo, User} from '../../types'
import {GuestInfoSchema} from '../../schema'

const logger = getLogger('CheckoutGuestInfoForm')

export interface CheckoutGuestInfoFormProps {
  className?: string
  isEditMode: boolean
  guestInfo: GuestInfo
  user: User
  onBack: () => void
  onSave: (guestInfo: GuestInfo) => void
  onCancel: () => void
}

const CheckoutGuestInfoForm = ({
  className = '',
  isEditMode,
  guestInfo,
  user,
  onBack,
  onSave,
  onCancel
}: CheckoutGuestInfoFormProps) => {
  const [formattedPhone, setFormattedPhone] = React.useState<string>('')
  const {register, handleSubmit, control, formState} = useForm<GuestInfo>({
    defaultValues: {
      firstName: guestInfo?.firstName || user?.firstName || '',
      lastName: guestInfo?.lastName || user?.lastName || '',
      phone: guestInfo?.phone || user?.phone || '',
      email: guestInfo?.email || user?.email || '',
      comments: guestInfo?.comments || '',
      acceptTerms: guestInfo?.acceptTerms || false
    },
    resolver: zodResolver(GuestInfoSchema)
  })

  const {errors} = formState

  return (
    <form
      className={clsx('flex flex-col gap-1', className)}
      onSubmit={handleSubmit((guestInfo: GuestInfo) => {
        logger.info({message: 'Guest info form submitted', data: {guestInfo}})
        ReactGA.event({
          category: 'IBE',
          action: 'Checkout_Guest_Info_Submit',
          value: 1
        })
        onSave({
          firstName: guestInfo.firstName,
          lastName: guestInfo.lastName,
          email: guestInfo.email,
          phone: guestInfo.phone,
          comments: guestInfo.comments,
          acceptTerms: guestInfo.acceptTerms,
          formattedPhone
        })
      })}
      aria-label="Guest information form."
    >
      <p className="mb-2">Please provide your contact information.</p>
      <label className="form-control">
        <div className="label">
          <span className="label-text">First Name*</span>
        </div>
        <input
          type="text"
          className="input input-bordered input-sm"
          aria-label="First name input field."
          aria-required="true"
          {...register('firstName')}
        />
        {errors.firstName ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.firstName.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Last Name*</span>
        </div>
        <input
          type="text"
          className="input input-bordered input-sm"
          aria-label="Last name input field."
          aria-required="true"
          {...register('lastName')}
        />
        {errors.lastName ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.lastName.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Mobile Phone*</span>
        </div>
        <Controller
          name="phone"
          control={control}
          render={({field}: any) => {
            return (
              <PhoneInput
                country={'us'}
                value={field.value}
                onChange={(
                  value: string,
                  _country: any,
                  _e: any,
                  formattedValue: string
                ) => {
                  // update phone field
                  field.onChange(value)
                  setFormattedPhone(formattedValue)
                }}
              />
            )
          }}
          aria-label="Phone number input field."
          aria-required="true"
        />

        {errors.phone ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.phone.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Email*</span>
        </div>
        <input
          type="text"
          className="input input-bordered input-sm"
          aria-label="Email input field."
          aria-required="true"
          {...register('email')}
        />
        {errors.email ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              {errors.email.message}
            </span>
          </div>
        ) : (
          ''
        )}
      </label>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Comments about your stay</span>
        </div>
        <textarea
          className="textarea textarea-bordered"
          aria-label="Comments about your stay."
          aria-required="false"
          {...register('comments')}
        ></textarea>
      </label>

      <label className="form-control">
        <div className="label">
          <input
            type="checkbox"
            className="checkbox mr-2"
            {...register('acceptTerms')}
          />
          <span className="label-text">
            I accept Placemakr's{' '}
            <a
              className="link text-accent text-bold no-underline hover:underline"
              href="https://www.placemakr.com/terms-and-conditions"
              target="_blank"
              onClick={e => {
                e.stopPropagation()
              }}
              aria-label="View Terms and Conditions."
            >
              Terms and Conditions
            </a>{' '}
            and agree to receive information about my reservation via text
            message.
          </span>
        </div>
        {errors.acceptTerms ? (
          <div className="label">
            <span className="label-text-alt text-red-500">
              Terms and Conditions must be accepted.
            </span>
          </div>
        ) : (
          ''
        )}
      </label>

      {isEditMode ? (
        <div className="flex justify-center gap-2 w-full mt-2">
          <button
            className="btn btn-ghost mt-4"
            onClick={() => {
              ReactGA.event({
                category: 'IBE',
                action: 'Checkout_Guest_Info_Cancel',
                value: 1
              })
              onCancel()
            }}
            aria-label="Cancel editing guest information."
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-secondary mt-4"
            aria-label="Update guest information."
          >
            Update
          </button>
        </div>
      ) : (
        <button
          type="submit"
          className="btn btn-secondary mt-4"
          aria-label="Continue to payment."
        >
          continue
        </button>
      )}

      {!user.isAuthenticated && !isEditMode && (
        <div className="flex justify-center w-full mt-2">
          <a
            className="link link-primary text-sm"
            onClick={() => {
              onBack()
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
              if (e.key === 'Enter') {
                onBack()
              }
            }}
            tabIndex={0}
            aria-label="Back to choose guest information view."
          >
            <FontAwesomeIcon
              icon={faCaretLeft}
              className="mr-1"
              title="Back to guest info"
            />
            <span>back</span>
          </a>
        </div>
      )}
    </form>
  )
}

export default CheckoutGuestInfoForm
