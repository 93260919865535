import React, {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {SearchSpecialRatesParams} from '../../types'

export interface SearchSpecialRatesControlProps {
  specialRates: SearchSpecialRatesParams
  onChange: (updatedParams: SearchSpecialRatesParams) => void
  className?: string
}

interface FormState {
  hasPromoCode: boolean
  promoCode?: string
  hasCorporateCode: boolean
  corporateCode?: string
  hasAAA: boolean
  hasAARP: boolean
  hasGov: boolean
  hasMilitary: boolean
}

interface SpecialRateLabelProps {
  form: FormState
}

function SpecialRateLabel({form}: SpecialRateLabelProps): JSX.Element {
  const {
    hasCorporateCode,
    corporateCode,
    hasPromoCode,
    promoCode,
    hasAAA,
    hasAARP,
    hasGov,
    hasMilitary
  } = form
  if (hasPromoCode) {
    const displayPromoCode = promoCode
      ? promoCode?.length > 15
        ? `${promoCode.slice(0, 15)}...`
        : promoCode
      : 'None'
    return (
      <div className="flex flex-row items-center gap-1">
        <span>Promo Code:</span>
        <span
          className={clsx({
            'text-gray-500': promoCode && promoCode.length > 0,
            'text-red-500': !promoCode || promoCode.length === 0
          })}
        >
          {displayPromoCode}
        </span>
      </div>
    )
  } else if (hasCorporateCode) {
    const displayCorporateCode = corporateCode
      ? corporateCode?.length > 15
        ? `${corporateCode.slice(0, 15)}...`
        : corporateCode
      : 'None'
    return (
      <div className="flex flex-row items-center gap-1">
        <span>Corp Code:</span>
        <span
          className={clsx({
            'text-gray-500': corporateCode && corporateCode.length > 0,
            'text-red-500': !corporateCode || corporateCode.length === 0
          })}
        >
          {displayCorporateCode}
        </span>
      </div>
    )
  } else if (hasAAA) {
    return <span>AAA Rate</span>
  } else if (hasAARP) {
    return <span>AARP Rate</span>
  } else if (hasGov) {
    return <span>US Gov Rate</span>
  } else if (hasMilitary) {
    return <span>US Military Rate</span>
  } else {
    return <span>Best Available</span>
  }
}

const SearchSpecialRatesControl = ({
  specialRates,
  onChange,
  className = ''
}: SearchSpecialRatesControlProps) => {
  const [formState, setFormState] = React.useState<FormState>({
    hasPromoCode: specialRates?.promoCode ? true : false,
    promoCode: specialRates?.promoCode,
    hasCorporateCode: specialRates?.corporateCode ? true : false,
    corporateCode: specialRates?.corporateCode,
    hasAAA: specialRates?.hasAAA,
    hasAARP: specialRates?.hasAARP,
    hasGov: specialRates?.hasGov,
    hasMilitary: specialRates?.hasMilitary
  })
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const {
      hasCorporateCode,
      hasPromoCode,
      corporateCode,
      promoCode,
      hasAAA,
      hasAARP,
      hasGov,
      hasMilitary
    } = formState
    onChange({
      ...specialRates,
      promoCode: hasPromoCode ? promoCode : '',
      corporateCode: hasCorporateCode ? corporateCode : '',
      hasAAA,
      hasAARP,
      hasGov,
      hasMilitary
    })
  }, [formState])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isOpen])

  return (
    <div ref={dropdownRef} className={clsx('dropdown min-w-52', className)}>
      {/* Dropdown button */}
      <button
        className="flex flex-row items-center cursor-pointer py-3.5 lg:py-1 w-full"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          setIsOpen(!isOpen)
        }}
        tabIndex={0}
        aria-label="Select special rates."
      >
        <div className="w-full text-left font-medium text-sm">
          <SpecialRateLabel form={formState} />
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className="ml-2 text-2xs"
          title="Toggle special rates field"
        />
      </button>

      {/* Dropdown content */}
      {isOpen && (
        <div
          className="dropdown-content menu bg-white rounded-box z-[9999] w-80 p-4 mt-2 shadow flex flex-col gap-3"
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
            if (e.target instanceof HTMLInputElement) {
              return
            } else {
              // prevents the dropdown from closing when clicking on the content
              e.preventDefault()
            }
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="form-control">
              <label className="label cursor-pointer justify-start gap-2">
                <input
                  type="checkbox"
                  className="toggle toggle-sm"
                  checked={formState.hasPromoCode}
                  onChange={e => {
                    setFormState({
                      ...formState,
                      hasPromoCode: e.target.checked,
                      hasCorporateCode: false,
                      hasAAA: false,
                      hasAARP: false,
                      hasGov: false,
                      hasMilitary: false
                    })
                  }}
                />
                <span className="label-text">Promo Code</span>
              </label>
            </div>
            {formState.hasPromoCode && (
              <div className="form-control">
                <input
                  type="text"
                  className="input input-sm focus:ring-0 outline-none"
                  placeholder="Enter Promo Code"
                  value={formState.promoCode}
                  onChange={e => {
                    setFormState({
                      ...formState,
                      promoCode: e.target.value
                    })
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <div className="form-control">
              <label className="label cursor-pointer justify-start gap-2">
                <input
                  type="checkbox"
                  className="toggle toggle-sm"
                  checked={formState.hasCorporateCode}
                  onChange={e => {
                    setFormState({
                      ...formState,
                      hasCorporateCode: e.target.checked,
                      hasPromoCode: false,
                      hasAAA: false,
                      hasAARP: false,
                      hasGov: false,
                      hasMilitary: false
                    })
                  }}
                />
                <span className="label-text">Corporate Code</span>
              </label>
            </div>
            {formState.hasCorporateCode && (
              <div className="form-control">
                <input
                  type="text"
                  className="input input-sm focus:ring-0 outline-none"
                  placeholder="Enter Corporate Code"
                  value={formState.corporateCode}
                  onChange={e => {
                    setFormState({
                      ...formState,
                      corporateCode: e.target.value
                    })
                  }}
                />
              </div>
            )}
          </div>

          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasAAA}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasAAA: e.target.checked,
                    hasCorporateCode: false,
                    hasPromoCode: false,
                    hasAARP: false,
                    hasGov: false,
                    hasMilitary: false
                  })
                }}
              />
              <span className="label-text">AAA</span>
            </label>
          </div>
          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasAARP}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasAARP: e.target.checked,
                    hasCorporateCode: false,
                    hasPromoCode: false,
                    hasAAA: false,
                    hasGov: false,
                    hasMilitary: false
                  })
                }}
              />
              <span className="label-text">AARP</span>
            </label>
          </div>
          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasGov}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasGov: e.target.checked,
                    hasCorporateCode: false,
                    hasPromoCode: false,
                    hasAAA: false,
                    hasAARP: false,
                    hasMilitary: false
                  })
                }}
              />
              <span className="label-text">US Government</span>
            </label>
          </div>
          <div className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasMilitary}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasMilitary: e.target.checked,
                    hasCorporateCode: false,
                    hasPromoCode: false,
                    hasAAA: false,
                    hasAARP: false,
                    hasGov: false
                  })
                }}
              />
              <span className="label-text">US Military</span>
            </label>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchSpecialRatesControl
