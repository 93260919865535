import React from 'react'
import {IbeConfig, OfferResult, SearchParams} from '../../types'
import SearchUnitResultItem from './SearchUnitResultItem'
import {findPropertyConfig} from '../../services/utils'
import {getUnitGroupOffers} from '../../services/offerUtils'
import {getLogger} from '../../services/logging'
import {useAppSelector} from '../../store/hooks'
import {selectConfig} from '../../store/configSlice'
import EmptyResults from '../../assets/ibe-empty-results.png'
import {useSearchSpecialRatesQuery} from '../../services/ibeApi'
import {selectSearch} from '../../store/searchSlice'

const logger = getLogger('SearchUnitResults')

/**
 * Formats the property selection label based on the selected property ID
 * @param selectedPropertyId
 * @param config
 * @returns
 */
function formatPropertySelectionLabel(
  selectedPropertyId: string,
  config: IbeConfig | null
): string {
  if (selectedPropertyId === 'ALL') {
    return 'All Properties'
  } else if (!config) {
    return 'Property'
  } else {
    const propertyConfig = findPropertyConfig(
      config.properties,
      selectedPropertyId
    )
    return propertyConfig?.name || 'Selected Property'
  }
}

/**
 * Checks if any special rates are enabled in the search parameters
 * @param searchParams The search parameters
 * @returns boolean
 */
function hasSpecialRates({specialRates}: SearchParams): boolean {
  return (
    (specialRates?.promoCode && specialRates?.promoCode?.length > 0) ||
    (specialRates?.corporateCode && specialRates?.corporateCode?.length > 0) ||
    specialRates?.hasAAA ||
    specialRates?.hasAARP ||
    specialRates?.hasGov ||
    specialRates?.hasMilitary
  )
}

export interface SearchUnitResultsProps {
  offerResults?: OfferResult[]
  selectedPropertyId: string
  isLoading: boolean
}

const SearchUnitResults = ({
  offerResults = [],
  selectedPropertyId,
  isLoading
}: SearchUnitResultsProps) => {
  const config = useAppSelector(selectConfig)
  const searchParams = useAppSelector(selectSearch)
  const propertySelectionLabel = config
    ? formatPropertySelectionLabel(selectedPropertyId, config)
    : 'Property'
  const unitGroupOffers = getUnitGroupOffers(selectedPropertyId, offerResults)

  // get special rate plans for selected property (if special rates are enabled)
  const {dateRange, guests, specialRates} = searchParams
  const specialRatePlansQuery = useSearchSpecialRatesQuery(
    {
      propertyId: selectedPropertyId,
      dateRange,
      guests,
      specialRates
    },
    {
      skip: !hasSpecialRates(searchParams)
    }
  )

  const specialRateCode = specialRatePlansQuery.data?.code || null
  const specialRatePlans = specialRatePlansQuery.data?.ratePlanIds || []

  logger.debug({
    message: 'Rendering SearchUnitResults...',
    data: {unitGroupOffers, specialRateCode, specialRatePlans}
  })

  if (isLoading) {
    return (
      <div className="flex-1 flex flex-col gap-8 mt-8">
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
        <div className="skeleton h-64 w-full"></div>
      </div>
    )
  } else if (!isLoading && unitGroupOffers?.length) {
    return (
      <>
        <div className="text-sm mt-4">
          Showing <span className="font-bold">{unitGroupOffers.length}</span>{' '}
          room results for{' '}
          <span className="font-bold">{propertySelectionLabel}</span>:
        </div>
        <div className="flex flex-col gap-8 mt-4 mb-8">
          {unitGroupOffers.map((unitGroupOffer, index) => {
            return (
              <SearchUnitResultItem
                key={index}
                unitGroupOffer={unitGroupOffer}
                propertyId={unitGroupOffer.propertyId}
                specialRateCode={specialRateCode}
                specialRatePlans={specialRatePlans}
              />
            )
          })}
        </div>
      </>
    )
  } else {
    return (
      <div className="flex flex-col items-center gap-2 mb-12">
        <img
          src={EmptyResults}
          className="max-h-72"
          alt="No results found image."
        />
        <h2 className="text-lg font-bold mt-8">No results found.</h2>
        <p className="text-center">
          Try adjusting your search filters or broadening your travel dates to
          see more options.
        </p>
      </div>
    )
  }
}

export default SearchUnitResults
