import React, {useMemo} from 'react'
import {Offer} from '../../types'
import SearchUnitOfferDetailsModal from './SearchUnitOfferDetailsModal'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {showItemEditor} from '../../store/shoppingCartSlice'
import {formatCurrency, generateUUID} from '../../services/utils'
import clsx from 'clsx'
import ReactGA from 'react-ga4'
import {selectGuestsPerRoom} from '../../store/searchSlice'
import {getLogger} from '../../services/logging'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import {calcBaseTotalRoomCharge, isMemberRate} from '../../services/offerUtils'

const logger = getLogger('SearchUnitOfferItem')

export interface SearchUnitOfferItemProps {
  propertyId: string
  offer: Offer
  specialRateCode?: string | null
  specialRatePlans?: string[] | null
}

function isSpecialRate(
  offer: Offer,
  specialRatePlans: string[] | null | undefined
): boolean {
  if (!specialRatePlans || specialRatePlans.length === 0) {
    return false
  }
  return specialRatePlans.includes(offer.ratePlan.id)
}

const SearchUnitOfferItem = ({
  offer,
  propertyId,
  specialRateCode,
  specialRatePlans
}: SearchUnitOfferItemProps) => {
  const dispatch = useAppDispatch()
  const guestsPerRoom = useAppSelector(selectGuestsPerRoom)
  const [showOfferDetailsModal, setShowOfferDetailsModal] = React.useState(
    false
  )
  const nights = offer.timeSlices?.length || 0
  const totalRoomCharge = useMemo(() => calcBaseTotalRoomCharge(offer), [offer])
  const hasSpecialRate = isSpecialRate(offer, specialRatePlans)

  const handleShowDetailsModal = () => {
    ReactGA.event({
      category: 'IBE',
      action: 'Unit_Rate_Details_View',
      value: 1
    })
    logger.info({
      message: 'User clicked rate details',
      data: {offer}
    })
    setShowOfferDetailsModal(true)
  }

  logger.debug({
    message: 'Rendering SearchUnitOfferItem...',
    data: {offer, nights, totalRoomCharge}
  })

  return (
    <>
      <div className="w-full">
        {/* special rate */}
        {hasSpecialRate && (
          <div className="badge badge-primary rounded text-xs mb-2">
            <FontAwesomeIcon
              icon={faStar}
              className="mr-1"
              title="Special rate"
            />
            <span className="italic">{specialRateCode}</span>
          </div>
        )}

        {/* member rate */}
        {!hasSpecialRate && isMemberRate(offer) && (
          <div className="badge badge-primary rounded text-xs mb-2">
            <FontAwesomeIcon
              icon={faStar}
              className="mr-1"
              title="Member rate"
            />
            <span className="italic">Member Rate</span>
          </div>
        )}

        <div className={clsx('flex flex-col gap-2 w-full', 'lg:flex-row')}>
          <div className={clsx('w-full', 'lg:w-3/5')}>
            <h1 className="text-md font-medium">{offer.ratePlan.name}</h1>
            <div className="mt-1">
              <a
                className={clsx(
                  'link text-sm text-light-primary font-semibold',
                  'no-underline hover:underline'
                )}
                onClick={handleShowDetailsModal}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'Enter') {
                    handleShowDetailsModal()
                  }
                }}
                tabIndex={0}
                aria-label="View rate details for this room."
              >
                View rate details
              </a>
            </div>
          </div>
          <div
            className={clsx(
              'w-full flex gap-4 flex-row items-end',
              'lg:w-2/5 lg:items-start lg:flex-row'
            )}
          >
            <div className="flex-1 flex flex-col items-end">
              <div className="flex flex-col items-center">
                <div className="flex flex-row items-center justify-end font-semibold">
                  <span className="text-xl leading-6 mr-1">
                    {formatCurrency(totalRoomCharge)}
                  </span>
                  <span className="text-xs uppercase">
                    {offer.totalGrossAmount.currency}
                  </span>
                </div>
                <div className="text-gray-500 text-2xs leading-3">
                  total for {nights} nights
                </div>
                <div className="text-gray-500 text-2xs leading-3">
                  plus taxes & fees
                </div>
              </div>
            </div>
            <div className="flex-0 flex flex-row justify-end">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  ReactGA.event({
                    category: 'IBE',
                    action: 'Unit_Select',
                    value: 1
                  })
                  logger.info({
                    message: 'User selected unit offer',
                    data: {propertyId, offer}
                  })
                  // show item editor modal
                  dispatch(
                    showItemEditor({
                      item: {
                        id: generateUUID(),
                        propertyId,
                        offer,
                        additionalServices: [],
                        adults: guestsPerRoom,
                        specialRateCode: hasSpecialRate ? specialRateCode : null
                      },
                      mode: 'Add'
                    })
                  )
                }}
                aria-label="Select the room offer and add to cart."
              >
                select
              </button>
            </div>
          </div>
        </div>
      </div>

      <SearchUnitOfferDetailsModal
        offer={offer}
        isOpen={showOfferDetailsModal}
        onClose={() => setShowOfferDetailsModal(false)}
      />
    </>
  )
}

export default SearchUnitOfferItem
